import AutoNumeric from 'autonumeric'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks'
import { isDefined } from '../../../../../../../utils/TypeCheckers'
import { TradingState, updateQuantity } from '../../../../tradingSlice'
import { InputStyled, QuantityLabelStyled, QuantityWrapperStyled } from './styled'

export interface RenderQuantityDO {
    code: string
}

export default function RenderQuantity({ code }: RenderQuantityDO) {
    const dispatch = useAppDispatch()
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const quantityRef = useRef<any>()
    const [quantityField, setQuantityField] = useState<AutoNumeric | undefined>()

    useEffect(() => {
        window.requestAnimationFrame(function () {
            try {
                if (quantityRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(quantityRef?.current)) {
                        const qtyElement = new AutoNumeric(quantityRef.current, _tradingSlice?.order?.getQuantity() || '', {
                            // currencySymbol: '$',
                            modifyValueOnWheel: false,
                            decimalPlaces: 2,
                            selectOnFocus: false,
                            minimumValue: '0',
                            // minimumValue: '1',
                        })
                        qtyElement.node().addEventListener('input', (event: Event) => {
                            // @ts-ignore
                            const value = parseInt((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', ''), 10)
                            try {
                                // const number = parseFloat(event);
                                if (isDefined(value)) {
                                    dispatch(
                                        updateQuantity({
                                            code: code,
                                            quantity: value || 0,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        updateQuantity({
                                            code: code,
                                            quantity: 0,
                                        })
                                    )
                                }
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setQuantityField(qtyElement)
                    } else {
                        if (_tradingSlice?.order?.isCloseout() && quantityField && isDefined(_tradingSlice?.order?.getQuantity())) {
                            // @ts-ignore
                            quantityField.set(_buySellSlice.order.getQuantity())
                        }
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [_tradingSlice?.order?.getOrderType()])

    if (_tradingSlice.apiError) {
        return <></>
    }
    return (
        <QuantityWrapperStyled>
            <QuantityLabelStyled>Quantity</QuantityLabelStyled>
            <InputStyled
                type={'tel'}
                autoFocus={true}
                step={2}
                ref={quantityRef}
                id={'quantity'}
                placeholder={'Quantity'}
                autoComplete={'off'}
                required={true}
            />
        </QuantityWrapperStyled>
    )
}
