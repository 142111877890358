import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { SessionContext } from '../../../../../providers/session/SessionContext'
import TabbedPage from '../../../tabbed-page/TabbedPage'
import { TradingState, updateTradingBlotterPath } from '../../tradingSlice'
import { BlotterContainerStyled } from './styled'

interface BlotterProperties {
    code: string
}

export const TradingBlotter = (properties: BlotterProperties): React.ReactElement<BlotterProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const dispatch = useAppDispatch()

    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[properties.code])

    const [refreshCounter, setRefreshCounter] = useState<number>(0)

    const onTabClicked = (entity: string | undefined, entityId: string | undefined, tabCode: string | undefined) => {
        dispatch(
            updateTradingBlotterPath({
                code: properties.code,
                path: `/${entity}/${entityId}/${tabCode}`,
            })
        )
        setRefreshCounter(refreshCounter + 1)
    }
    useEffect(() => {
        if (!_tradingSlice?.blotterPath || refreshCounter === 0) {
            dispatch(
                updateTradingBlotterPath({
                    code: properties.code,
                    path: '/Blotter/ui/Blotter-Positions',
                })
            )
            setRefreshCounter(refreshCounter + 1)
        }
    }, [_tradingSlice])

    return (
        <BlotterContainerStyled>
            <TabbedPage
                key={'blotter_tp_' + refreshCounter}
                noHeader={true}
                fixedHeight={400}
                path={_tradingSlice.blotterPath}
                queryParams={sessionContext.getQueryParams()}
                onTabClicked={onTabClicked}
            />
        </BlotterContainerStyled>
    )
}
