import React from 'react'
import { useAppSelector } from '../../../../../../../app/hooks'
import { TradingState } from '../../../../tradingSlice'
import { BoxStyled } from '../../../watchlist/styled'
import { ErrorPanelStyled } from './styled'

interface RenderErrorProperties {
    code: string
}

export default function RenderError({ code }: RenderErrorProperties) {
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const errorMessage: string = _tradingSlice.errorMessage
    console.log('errorMessage ==>', errorMessage)
    const isNotMapped: boolean = errorMessage === 'Contract not mapped'
    if (!isNotMapped) {
        return <></>
    }
    return (
        <BoxStyled>
            <ErrorPanelStyled>{_tradingSlice?.errorMessage || ''}</ErrorPanelStyled>
        </BoxStyled>
    )
}
