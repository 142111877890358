import React from 'react'

export interface MoneyWithCurrencyDO {
    value: number
    currency: string
}

export default function MoneyWithCurrency({ value, currency }: MoneyWithCurrencyDO) {
    return (
        <div className={'d-flex align-items-center justify-content-start'}>
            <span>{value ?? '---'}</span>
            <>&nbsp;</>
            <span>{value ? currency : <>&nbsp;</>}</span>
        </div>
    )
}
