import styled from 'styled-components'
import { FormMode } from '../../types'

interface FieldContentStyledProperties {
    formMode?: FormMode
}

export const IconPlaceholderStyled = styled.span.attrs((props: FieldContentStyledProperties) => ({}))<FieldContentStyledProperties>`
    float: right;
    padding-left: 10px;
    padding-bottom: 2px;

    ${(props) => {
        if (props.formMode === FormMode.EDIT || props.formMode === FormMode.NEW) {
            return 'color: ' + (props?.theme?.login?.entryBorderColor || props.theme.table.columnColor) + ' !important; '
        } else {
            return 'color: ' + (props?.theme?.form?.viewBorderColor || props.theme.table.columnColor) + ' !important; '
        }
    }}

    ${(props) => {
        if (props.formMode === FormMode.EDIT || props.formMode === FormMode.NEW) {
            return 'border-left: 1px solid ' + (props?.theme?.form?.entryBorderColor || props.theme.table.columnColor) + ' !important; '
        } else {
            return 'border-left: 1px solid ' + (props?.theme?.form?.viewBorderColor || props.theme.table.columnColor) + ' !important; '
        }
    }}
`

interface LoginIconStyledProperties {
    active: boolean
}

export const LoginIconPlaceholderStyled = styled.span.attrs((props: LoginIconStyledProperties) => ({}))<LoginIconStyledProperties>`
    float: right;
    padding-left: 10px;
    padding-bottom: 2px;
    font-size: 15px;

    i {
        padding-top: 5px;
        color: ${(props) => {
            return (props?.theme?.login?.input?.iconColor || props.theme.table.columnColor) + ' !important; '
        }};
        &:hover {
            color: ${(props) => {
                return (props?.theme?.login?.input?.iconColor || props.theme.table.columnColor) + ' !important; '
            }};
        }
    }
`
