// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import { DeltaAction, FieldDeltaDO } from '../../../providers/blast/commands'
import { guslStorage } from '../../../providers/session/GuslStorage'
import { SessionContextProps } from '../../../providers/session/types'
import { getStyle } from '../../../utils/CssUtils'
import { arrayNotEmpty, isArray, isDefined, notBlank } from '../../../utils/TypeCheckers'
import { clone, compare, constructUrl, matchMediaTypeWithAction, matchMediaTypeWithOrderByAction, safeStream } from '../../../utils/Utils'
import {
    ActionConfigDTO,
    ConditionalRowDetailsDTO,
    FavQueryDTO,
    FieldConfigDTO,
    FormMode,
    IMenuDTO,
    MediaType,
    OrderByActionDO,
    PageResponseDTO,
    PanelConfigDTO,
    QueryParamsDTO,
    RangeQueryDTO,
    SummaryDO,
    TableControlsDTO,
    TableRowDTO,
} from '../../types'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { GroupActionState } from '../maintain-table/table-row/groupActionsSlice'

export const defaultCellWidth: number = 90
export const inlineEditCellWidth: number = 200
export const minResizableCellWidth: number = 40

// -------------- actions

export interface GuslTableDataUpdatePayload {
    code: string
    response: PageResponseDTO
}

export interface GuslTableFilterTogglePayload {
    code: string
}

export interface GuslTableCardOpenedPayload {
    code: string
    uniqueId: string
}

export interface IncrementCounterPayload {
    code: string
}

export interface FavQueryUpdatedAtPayload extends IncrementCounterPayload {}

export interface GuslTableSearchableFieldTogglePayload {
    code: string
    field: FieldConfigDTO
}

export interface GuslTableSearchableIndividualFieldPayload {
    code: string
    field: string
}

export interface GuslTableSearchableFieldResetPayload {
    code: string
    defaultSearchableFields: FieldConfigDTO[] | undefined
}

export interface GuslTableEditRowPayload {
    code: string
    editRowId: string
}

export interface GuslTableReorderColumnDisplayPayload {
    code: string
    firstIdx: number
    lastIdx: number
}

export interface GuslTableToggleColumnDisplayPayload {
    code: string
    field: FieldConfigDTO
}

export interface GuslTableInlineTogglePayload {
    code: string
    menuItem: IMenuDTO | undefined
}

export interface GuslTableCreateEntityTogglePayload {
    code: string
}

export interface GuslTableCleanUpPayload {
    code: string
}

export interface GuslTableServerRangeQueryPayload {
    code: string
    serverRangeQueries: RangeQueryDTO[] | undefined
}

export interface GuslTableServerQueryParamsPayload {
    code: string
    serverQueryParams: QueryParamsDTO | undefined
}

export interface GuslTableCurrentContentTotalPayload {
    code: string
    currentContentTotal: number
}

// MK 12/08/2023 total for pagination update
export interface GuslTableResponseTotalPayload {
    code: string
    responseTotal: number
}

// END OF 12/08/2023 total for pagination update

export interface GuslTableSearchStringPayload {
    code: string
    searchString: string | undefined
}

export interface GuslTableScrollLeftPositionPayload {
    code: string
    scrollLeftPosition: number
}

export interface GuslTableResizeColumnWidthPayload {
    code: string
    fld: FieldConfigDTO
    width: number
}

export interface GuslTableColumnWidthPayload {
    code: string
    fld: FieldConfigDTO
    width: number | string
}

export interface GuslTableResizedColumnNamePayload {
    code: string
    fldName: string | undefined
}

export interface GuslTableFavQueriesPayload {
    code: string
    favQueries: FavQueryDTO[]
}

export interface GuslTableToggleRowSelectPayload {
    code: string
    row: TableRowDTO
    rowIndex: number
    withShiftKey: boolean
    selected: boolean
}

export interface GuslTableClearAllRowSelectionPayload {
    code: string
}

export interface GuslTableInitPayload {
    code: string
    sessionContext: SessionContextProps
    conditionalRowDetails: ConditionalRowDetailsDTO[] | undefined
    label: string | undefined
    selectUrl: string
    tableData: TableRowDTO[] | undefined
    hideHeaderPanel: boolean
    hideHeaderColumns: boolean
    allFields: FieldConfigDTO[]
    headerSummary: SummaryDO | undefined
    footerSummary: SummaryDO | undefined
    tableActions: ActionConfigDTO[]
    rowActions: ActionConfigDTO[]
    groupActions: ActionConfigDTO[]

    orderByActions: OrderByActionDO[]

    filterPanelOpened: boolean
    expandableRow: boolean
    // queryParams: QueryParamsDTO,
    // orderBys: OrderByDTO[],
    rowsPerPage: number
    onRefresh: (() => void) | undefined
    createConfig: IMenuDTO | undefined
    onOpenCreateNewEntityDialog: (() => void) | undefined
    onInlineMenuGroupAction: ((event: React.MouseEvent, item: IMenuDTO) => void) | undefined
    performDownloadWithoutPromptAction: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined
    performActionOnly: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined
    showFilters: boolean
    // MK 11/08/2023 top filters
    showTopFilters: boolean
    showColumnsSettings: boolean
    openFilterBlocks: string[]
    serverRangeQueries: RangeQueryDTO[]
    editRowId: string
    searchString: string | undefined
    highlightDelta: boolean | undefined
    disableTableControls: boolean | undefined
    disableColumnResize: boolean | undefined
    subLabel: string | undefined
    scrollLeftPosition: number
    // favQueries: FavQueryDTO[],
    favQueriesOpen: boolean
    showMobileTableControls: boolean
    mediaType: MediaType

    tableControl: TableControlsDTO

    // MK 12/08/2023
    responseTotal: number
    // END OF 12/08/2023

    // MK 19/08/2023 individual advanced search
    individualAdvancedSearchFields: string[]
    // END OF 19/08/2023 individual advanced search

    //MK 06-01-2014

    favQueriesUpdatedAt: number | undefined
    favQueries: FavQueryDTO[]
    headerPanels: PanelConfigDTO[]
    serverQueryParams: QueryParamsDTO | undefined
    currentContentTotal: number
    isCascadingTable: boolean
}

export interface DeltaUpdatePayload {
    code: string
    fieldDelta: FieldDeltaDO
}

// -------------- states
interface GuslState {
    [id: string]: GuslTableState
}

export interface GuslTableActionState {
    showInline: boolean
    activeItem: IMenuDTO | undefined
}

export interface GuslTableState {
    code: string
    sessionContext: SessionContextProps | undefined
    conditionalRowDetails: ConditionalRowDetailsDTO[] | undefined
    label: string | undefined
    tableData: TableRowDTO[] | undefined
    selectedRows: { [id: string]: TableRowDTO }

    selectUrl: string
    hideHeaderPanel: boolean
    hideHeaderColumns: boolean
    allFields: FieldConfigDTO[]
    headerSummary: SummaryDO | undefined
    footerSummary: SummaryDO | undefined
    tableActions: ActionConfigDTO[]
    rowActions: ActionConfigDTO[]
    groupActions: ActionConfigDTO[]

    orderByActions: OrderByActionDO[]

    haveRowActions: boolean

    haveGroupActions: boolean

    haveOrderByActions: boolean
    filterPanelOpened: boolean
    inlineAction: GuslTableActionState
    createConfig: IMenuDTO | undefined
    createEntityOpened: boolean
    expandableRow: boolean
    tableMenuGroups: IMenuDTO[]
    onRefresh: (() => void) | undefined
    onOpenCreateNewEntityDialog: (() => void) | undefined
    onInlineMenuGroupAction: ((event: React.MouseEvent, item: IMenuDTO) => void) | undefined
    performDownloadWithoutPromptAction: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined
    performActionOnly: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined
    showFilters: boolean
    // MK 11/08/2023
    showTopFilters: boolean
    showColumnsSettings: boolean
    showDetailedFilters: boolean
    showSearchBox: boolean
    serverRangeQueries: RangeQueryDTO[]
    searchableFields: FieldConfigDTO[]
    searchbarOpen: boolean
    haveSearchBox: boolean
    searchPrompt: string
    filterableFields: FieldConfigDTO[]
    haveFilters: boolean
    editRowId: string
    searchString: string | undefined
    highlightDelta: boolean
    disableTableControls: boolean | undefined

    disableColumnResize: boolean | undefined
    subLabel: string | undefined
    scrollLeftPosition: number

    //POC
    favQueries: FavQueryDTO[]
    favQueriesOpen: boolean
    showMobileTableControls: boolean

    mediaType: MediaType
    tableControl: TableControlsDTO

    hasCards: boolean
    lastQueryParams: QueryParamsDTO | undefined

    tableDeltaUpdateActive: boolean

    cards: {
        [id: string]: boolean
    }
    refreshCounter: number

    // MK 12/08/2023
    responseTotal: number
    // END OF 12/08/2023

    // MK 19/08/2023 individual advanced search
    individualAdvancedSearchFields: string[]
    // END OF 19/08/2023 individual advanced search
    haveId: boolean

    // MK 06-01-2024
    favQueriesUpdatedAt: number | undefined

    headerPanels: PanelConfigDTO[]
    serverQueryParams: QueryParamsDTO | undefined
    currentContentTotal: number

    hasInfinityScroll: boolean
    loading: boolean
    isCascadingTable: boolean
}

const initialState: GuslState = {}
const defaultSearchPrompt = 'Select One'
// -------------- utils
const createDefault = (code: string): GuslTableState => {
    return {
        code: code,
        sessionContext: undefined,
        conditionalRowDetails: undefined,
        label: undefined,
        tableData: [],
        selectedRows: {},
        selectUrl: '',
        hideHeaderPanel: false,
        hideHeaderColumns: false,
        allFields: [],
        headerSummary: undefined,
        footerSummary: undefined,
        tableActions: [],
        rowActions: [],
        groupActions: [],
        orderByActions: [],

        haveRowActions: false,
        haveGroupActions: false,
        haveOrderByActions: false,
        filterPanelOpened: false,
        inlineAction: {
            showInline: false,
            activeItem: undefined,
        },
        createConfig: undefined,
        createEntityOpened: false,
        expandableRow: false,
        cascadingFields: [],
        cascadingRowActions: [],
        tableMenuGroups: [],
        onRefresh: undefined,
        onOpenCreateNewEntityDialog: undefined,
        onInlineMenuGroupAction: undefined,
        performDownloadWithoutPromptAction: undefined,
        performActionOnly: undefined,
        showFilters: false,
        // MK 11/08/2023
        showTopFilters: false,
        showColumnsSettings: false,
        showDetailedFilters: false,
        showSearchBox: false,
        serverRangeQuery: { field: '' },
        searchableFields: [],
        searchbarOpen: false,
        haveSearchBox: false,
        searchPrompt: defaultSearchPrompt,
        filterableFields: [],
        haveFilters: false,
        editRowId: '',
        searchString: '',
        highlightDelta: false,
        disableTableControls: false,
        disableColumnResize: false,
        subLabel: '',
        scrollLeftPosition: 0,
        favQueries: [],
        favQueriesOpen: false,
        showMobileTableControls: false,
        mediaType: MediaType.Desktop,
        tableControl: maintainTableService.defaultTableControl(),
        hasCards: false,
        lastQueryParams: undefined,
        tableDeltaUpdateActive: true,
        cards: {},
        refreshCounter: 0,

        // MK 12/08/2023
        responseTotal: 0,
        // END OF 12/08/2023

        // MK 19/08/2023
        individualAdvancedSearchFields: [],
        // END OF 19/08/2023

        //MK 06-01-2024
        favQueriesUpdatedAt: undefined,

        headerPanels: [],
        currentContentTotal: 0,
        hasInfinityScroll: false,
        loading: false,
        isCascadingTable: false,
    } as GuslTableState
}

export interface TableResponseWrapper {
    code: string
    response: PageResponseDTO
}

interface TableDataRequest {
    code: string
    url: string
    queryParams: QueryParamsDTO
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

export const getTableData = createAsyncThunk('url', async (request: TableDataRequest) => {
    const response = await request.sessionContext.post<QueryParamsDTO, PageResponseDTO>(
        constructUrl(request.url, request.pathParams),
        request.queryParams,
        request.abortController
    )
    return { code: request.code, response: response?.data || {} }
})

const copyState = (entry: GuslTableState): GuslTableState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as GuslTableState
}

const sortFields = (fields: FieldConfigDTO[]): FieldConfigDTO[] => {
    let counter = 0 // <--- if you change this then fix repositionField logic
    return fields
        .map((field) => {
            const fld = Object.assign({}, field)
            if (!field.displayOrder || field.displayOrder === -1) {
                fld.displayOrder = counter++
            } else {
                counter++
            }
            fld.style = getStyle(fld.tableCss)
            fld.colStyle = getStyle(fld.colCss)
            return fld
        })
        .sort((a, b) => compare(a.displayOrder, b.displayOrder))
}

const checkIfDataHasCards = (fields: FieldConfigDTO[], rows: TableRowDTO[]): boolean => {
    let hasCards = false

    safeStream(rows).forEach((row) => {
        safeStream(fields).forEach((fld) => {
            if (fld.type === 'card') {
                try {
                    if (isDefined(row[fld.name]['cardPanel'])) {
                        hasCards = true
                    }
                } catch (e) {
                    // carry on and ignore
                }
            }
        })
    })
    return hasCards
}
const loadInitValues = (entry: GuslTableState, values: GuslTableInitPayload, code: string) => {
    const enhancedTableData = (values.tableData || []).flatMap<TableRowDTO>((row) => {
        const newRow = Object.assign({}, row)
        newRow.actions = maintainTableService
            .extractRowActionsFlattenedForRow(
                row,
                values.rowActions || [],
                values.performDownloadWithoutPromptAction,
                values.performActionOnly
            )
            .filter((action) => matchMediaTypeWithAction(action, values.mediaType))
        newRow.conditionalRowDetails = values.conditionalRowDetails
        newRow.formMode = FormMode.VIEW

        return newRow
    })
    entry.isCascadingTable = values.isCascadingTable

    entry.hasCards = checkIfDataHasCards(values.allFields, values.tableData || [])
    entry.lastQueryParams = undefined
    entry.groupActions = values.groupActions
    if (arrayNotEmpty(values.groupActions.filter((action) => matchMediaTypeWithAction(action, values.mediaType)))) {
        entry.haveGroupActions = true
    }

    entry.orderByActions = values.orderByActions
    if (arrayNotEmpty(values.orderByActions.filter((action) => matchMediaTypeWithOrderByAction(action, values.mediaType)))) {
        entry.haveOrderByActions = true
    }

    entry.expandableRow = isDefined(
        values.conditionalRowDetails &&
            values.conditionalRowDetails.length > 0 &&
            (values.conditionalRowDetails[0]?.expandable || values.conditionalRowDetails[0]?.expand)
    )

    if (arrayNotEmpty(values.rowActions.filter((action) => matchMediaTypeWithAction(action, values.mediaType)))) {
        entry.haveRowActions = true
    }
    let orderedFields: FieldConfigDTO[] = guslStorage.getOrderedFields(code, values.allFields)
    if (guslStorage.hasOrderedFields(code)) {
        orderedFields = guslStorage.getOrderedFields(code, values.allFields)
    }
    entry.tableMenuGroups = maintainTableService.extractSummaryActionsToMenuGroup(
        values.sessionContext.getLoggedInUser(),
        values.tableActions,
        values.onRefresh,
        values.onOpenCreateNewEntityDialog,
        values.onInlineMenuGroupAction,
        values.createConfig,
        values.performDownloadWithoutPromptAction,
        values.performActionOnly
    )

    entry.createConfig = values.createConfig
    entry.sessionContext = values.sessionContext
    entry.conditionalRowDetails = values.conditionalRowDetails
    entry.label = values.label
    entry.tableData = enhancedTableData // values.tableData
    entry.selectUrl = values.selectUrl
    entry.hideHeaderPanel = values.hideHeaderPanel || false
    entry.hideHeaderColumns = values.hideHeaderColumns
    entry.allFields = sortFields(orderedFields)

    entry.haveId = entry.allFields.filter((fld: FieldConfigDTO) => fld.name === 'id').length > 0

    entry.headerSummary = values.headerSummary
    entry.footerSummary = values.footerSummary
    entry.tableActions = values.tableActions
    entry.rowActions = values.rowActions
    entry.groupActions = values.groupActions
    entry.orderByActions = values.orderByActions
    entry.filterPanelOpened = values.filterPanelOpened
    entry.mediaType = values.mediaType

    entry.onRefresh = values.onRefresh
    entry.onOpenCreateNewEntityDialog = values.onOpenCreateNewEntityDialog
    entry.onInlineMenuGroupAction = values.onInlineMenuGroupAction
    entry.performDownloadWithoutPromptAction = values.performDownloadWithoutPromptAction
    entry.performActionOnly = values.performActionOnly

    const searchableFields: FieldConfigDTO[] = []
    let searchbarOpen: boolean = false

    values.allFields.forEach((fld) => {
        if (fld.searchbarOpen) {
            searchbarOpen = true
        }
        if (fld.searchable && isFieldTypeSearchable(fld)) {
            searchableFields.push(fld)
        }
    })

    // mergeDisplayColumns(code, values.allFields)

    entry.searchableFields = searchableFields
    entry.haveSearchBox = searchableFields.length > 0
    entry.searchbarOpen = searchbarOpen
    entry.showSearchBox = searchbarOpen
    entry.searchPrompt = getSearchPrompt(searchableFields)

    entry.filterableFields = extractFilterableFields(entry.allFields)
    entry.haveFilters = (entry?.filterableFields || []).length > 0
    entry.highlightDelta = values.highlightDelta || false
    entry.disableTableControls = values.disableTableControls || false
    entry.disableColumnResize = values.disableColumnResize || false
    entry.tableControl = values.tableControl
    entry.subLabel = values.subLabel || ''
    entry.scrollLeftPosition = values.scrollLeftPosition || 0
    entry.tableDeltaUpdateActive = true
    entry.cards = {}
    entry.refreshCounter = 0
    // entry.favQueries = values.favQueries || [];

    entry.headerPanels = values.headerPanels
    if (values.tableControl && values.tableControl.infinityScroll) {
        entry.hasInfinityScroll = true
    }
}

const extractFilterableFields = (allFields: FieldConfigDTO[]): FieldConfigDTO[] => {
    const filterableFields: FieldConfigDTO[] = []
    allFields
        .filter((fld) => fld.filterable)
        .sort((a, b) => compare(a.displayOrder, b.displayOrder))
        .forEach((fld) => {
            filterableFields.push(fld)
        })
    return filterableFields
}

const isFieldTypeSearchable = (field: FieldConfigDTO) => {
    switch (field.type) {
        case 'text':
        case 'html':
            return true
        case 'number':
            return true
        case 'percent':
            return true
    }
    return false
}

const getTableState = (state: GuslState, code: string): GuslTableState => {
    let entry: GuslTableState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

const updateData = (inboundState: GuslTableState, response: PageResponseDTO): GuslTableState => {
    // console.log('------------------------------- updateData -------------------------------')
    const entry: GuslTableState = copyState(inboundState)
    entry.loading = false
    if (response) {
        if (response.header) {
            entry.headerSummary = { ...response.header }
        }
        if (response.footer) {
            entry.footerSummary = { ...response.footer }
        }

        let conditionalRowDetails: ConditionalRowDetailsDTO[] | undefined = entry.conditionalRowDetails

        if (response.queryParams) {
            entry.lastQueryParams = response.queryParams
        }

        if (response.fields) {
            // this is a cascading table
            entry.allFields = maintainTableService.extractAllFieldsFromRows(response.fields)

            entry.tableMenuGroups = maintainTableService.extractSummaryActionsToMenuGroup(
                entry.sessionContext?.getLoggedInUser(),
                response.tableActions,
                entry.onRefresh,
                entry.onOpenCreateNewEntityDialog,
                entry.onInlineMenuGroupAction,
                entry.createConfig,
                entry.performDownloadWithoutPromptAction,
                entry.performActionOnly
            )

            if (response.actions) {
                // this is cascading table row actions
                entry.rowActions = response.actions.filter((action) => matchMediaTypeWithAction(action, entry.mediaType))
            }

            if (response.groupActions) {
                // this is cascading table row actions
                entry.groupActions = response.groupActions.filter((action) => matchMediaTypeWithAction(action, entry.mediaType))
            }
            // gb w
            if (response.orderByActions) {
                // this is cascading table row actions
                entry.orderByActions = response.orderByActions.filter((action) => matchMediaTypeWithOrderByAction(action, entry.mediaType))
            }

            if (arrayNotEmpty(entry.rowActions)) {
                entry.haveRowActions = true
            }

            if (arrayNotEmpty(entry.groupActions)) {
                entry.haveGroupActions = true
            }

            conditionalRowDetails = {
                conditions: [],
                url: response.url || '',
                expandUrl: response.expandUrl || '',
                fields: response.fields,
                expandable: response?.expandable || response?.expand || false,
                expand: response.expand || false,
                grouped: response.grouped || false,
                detailsButton: false,
                actions: (response.actions || []).filter((action) => matchMediaTypeWithAction(action, entry.mediaType)),
                table: response.table || !response.report,
                report: response.report,
                form: response.form,
                tableWidth: response.tableWidth,
                tableNoHeader: response.tableNoHeader,
                disableTableControls: response.disableTableControls || false,
                disableColumnResize: response.disableColumnResize || false,
            }
            entry.conditionalRowDetails = [conditionalRowDetails]
            entry.expandableRow =
                entry.conditionalRowDetails.length > 0 &&
                (entry.conditionalRowDetails[0]?.expandable || entry.conditionalRowDetails[0]?.expand)
        }

        const isFirstPage = response?.queryParams?.skip === 0
        let tableData: TableRowDTO[]
        if (isFirstPage) {
            tableData = [...(response.content || [])]
        } else if (!isFirstPage && entry.hasInfinityScroll) {
            tableData = [...(entry.tableData || []), ...(response.content || [])]
        } else {
            tableData = [...(response.content || [])]
        }

        const inboundData: TableRowDTO[] = tableData.flatMap<TableRowDTO>((row) => {
            const newRow = Object.assign({}, row)
            // const newRow = current( row)
            newRow.actions = maintainTableService
                .extractRowActionsFlattenedForRow(
                    row,
                    entry.rowActions || [],
                    entry.performDownloadWithoutPromptAction,
                    entry.performActionOnly
                )
                .filter((action) => matchMediaTypeWithAction(action, entry.mediaType))
            newRow.formMode = FormMode.VIEW
            if (isArray(conditionalRowDetails)) {
                newRow.conditionalRowDetails = conditionalRowDetails
            } else {
                newRow.conditionalRowDetails = [conditionalRowDetails]
            }
            return newRow
        })

        const hasShould: boolean = (entry.lastQueryParams?.should?.length || 0) > 0
        // console.log(`from: ${entry.tableData.length} ---> to: ${inboundData.length}`)
        entry.tableData = inboundData

        // if (entry.hasInfinityScroll && !isFirstPage) { // && !hasShould
        //     console.log('-- adding data')
        //     entry.tableData.push(...inboundData)
        // } else {
        //     console.log('-- replacing data')
        //     entry.tableData = inboundData
        // }
        // console.log('========> after size:', entry.tableData.length)

        //  entry.refreshCounter = entry.refreshCounter + 1
        // }
        entry.hasCards = checkIfDataHasCards(entry.allFields, entry.tableData || [])
    }
    return entry
}
/* eslint-disable @typescript-eslint/no-unused-vars */
const swapFields = (fields: FieldConfigDTO[], firstIdx: number, lastIdx: number): FieldConfigDTO[] => {
    try {
        let temp = fields[lastIdx].displayOrder
        fields[lastIdx].displayOrder = fields[firstIdx].displayOrder
        fields[firstIdx].displayOrder = temp
        return fields
    } catch (e) {
        console.error('error', e)
        return fields
    }
}

const moveUp = (fields: FieldConfigDTO[], firstIdx: number, lastIdx: number): FieldConfigDTO[] => {
    fields.forEach((fld) => {
        if ((fld.displayOrder || 0) === firstIdx) {
            fld.displayOrder = lastIdx
        } else if ((fld.displayOrder || 0) >= lastIdx && (fld.displayOrder || 0) < firstIdx) {
            fld.displayOrder = (fld.displayOrder || 0) + 1
        }
    })
    return fields
}

const moveDown = (fields: FieldConfigDTO[], firstIdx: number, lastIdx: number): FieldConfigDTO[] => {
    fields.forEach((fld) => {
        if ((fld.displayOrder || 0) === firstIdx) {
            fld.displayOrder = lastIdx
        } else if ((fld.displayOrder || 0) > firstIdx && (fld.displayOrder || 0) <= lastIdx) {
            fld.displayOrder = (fld.displayOrder || 0) - 1
        }
    })
    return fields
}

const resetDisplayOrder = (fields: FieldConfigDTO[]): FieldConfigDTO[] => {
    let flds: FieldConfigDTO[] = []
    fields.forEach((fld, index) => {
        fld.displayOrder = index
        flds.push(fld)
    })
    return flds
}

const repositionField = (fields: FieldConfigDTO[], firstIdx: number, lastIdx: number): FieldConfigDTO[] => {
    try {
        if (firstIdx > lastIdx) {
            return moveUp(fields, firstIdx, lastIdx)
        } else if (lastIdx > firstIdx) {
            return moveDown(fields, firstIdx, lastIdx)
        }
        return resetDisplayOrder(fields)
    } catch (e) {
        console.log('error', e)
        return resetDisplayOrder(fields)
    }
}

const reorderColumns = (entry: GuslTableState, firstIdx: number, lastIdx: number): void => {
    // swap
    // entry.allFields = swapFields(entry.allFields.slice(), firstIdx, lastIdx).sort((a, b) => compare(a.displayOrder, b.displayOrder))

    // place above or below
    entry.allFields = repositionField(entry.allFields.slice(), firstIdx, lastIdx).sort((a, b) => compare(a.displayOrder, b.displayOrder))
    entry.filterableFields = extractFilterableFields(entry.allFields)
}

const toggleSearchField = (entry: GuslTableState, field: FieldConfigDTO) => {
    const searchableFields: FieldConfigDTO[] = []

    entry.searchableFields.forEach((fld) => {
        const newField = Object.assign(fld, {})
        if (newField.name === field.name) {
            newField.searchableSelected = !field.searchableSelected
        }
        searchableFields.push(newField)
    })

    entry.searchPrompt = getSearchPrompt(searchableFields)
    entry.searchableFields = searchableFields
}

const getSearchPrompt = (searchableFields: FieldConfigDTO[]): string => {
    let prompt = defaultSearchPrompt
    let fieldLabel: string | undefined = undefined
    let count = 0
    searchableFields
        ?.filter((fld) => fld.searchableSelected)
        .forEach((fld) => {
            if (!fieldLabel) {
                fieldLabel = fld.label
            }
            count++
        })
    if (count > 1) {
        prompt = 'Many'
    } else if (fieldLabel) {
        prompt = fieldLabel
    }
    return prompt
}

const insertRow = (fieldDelta: FieldDeltaDO) => {
    const newRow: any = {}
    for (const origKey in fieldDelta.value) {
        if (fieldDelta.value.hasOwnProperty(origKey)) {
            newRow[origKey] = fieldDelta.value[origKey]
        }
    }
    return newRow
}

const findPrevious = (entry: GroupActionState, currentIndex: number, selected: boolean, tableData: TableRowDTO[]): number => {
    if (tableData.length > currentIndex) {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if ((selected && entry.selectedRows[tableData[i].rowId]) || (!selected && !entry.selectedRows[tableData[i].rowId])) {
                return i
            }
        }
    }
    return 0
}

const performClearAllRowSelections = (inboundState: GuslTableState) => {
    const entry: GuslTableState = inboundState //copyState(inboundState)
    entry.selectedRows = []
    return entry
}
const applyRowSelected = (
    inboundState: GuslTableState,
    row: TableRowDTO,
    withShiftKey: boolean,
    rowIndex: number,
    selected: boolean
): GuslTableState => {
    const entry: GuslTableState = inboundState //copyState(inboundState)

    if (rowIndex === 0 || !entry.tableData || !withShiftKey) {
        if (!selected) {
            delete entry.selectedRows[row.id]
        } else {
            entry.selectedRows[row.id] = row
        }
    } else if (withShiftKey) {
        const startIdx = findPrevious(entry, rowIndex, selected, entry.tableData || [])
        for (let i = startIdx; i <= rowIndex; i++) {
            if (!selected) {
                delete entry.selectedRows['r_' + i]
            } else {
                entry.selectedRows['r_' + i] = tableData[i]
            }
        }
    }

    // if (row?.id) {
    //     if (entry.selectedRows[row.id]) {
    //         delete entry.selectedRows[row.id]
    //     } else {
    //         entry.selectedRows[row.id] = row
    //     }
    // }
    return entry
}

const applyDelta = (inboundState: GuslTableState, fieldDelta: FieldDeltaDO): GuslTableState => {
    const entry: GuslTableState = inboundState //copyState(inboundState)

    if (!entry.tableDeltaUpdateActive) {
        // console.log('-- not updating', entry.code)
        return
    }
    if (fieldDelta) {
        let updateRequired: boolean = false

        let changedRow: TableRowDTO | undefined
        let index: number = -1

        if (!fieldDelta.action || fieldDelta.action === DeltaAction.UPDATE) {
            ;(entry?.tableData || []).forEach((row: TableRowDTO, idx: number) => {
                const newRow: TableRowDTO = clone(row)
                // @ts-ignore
                if (!fieldDelta.keyField || newRow[fieldDelta.keyField] + '' === fieldDelta.keyValue) {
                    if (notBlank(fieldDelta.preJs)) {
                        /* eslint-disable no-new-func */
                        const func = new Function('self', 'row', fieldDelta.preJs + '; process(row);')
                        func(this, newRow)
                    }

                    if (fieldDelta.fieldName) {
                        // @ts-ignore
                        newRow[fieldDelta.fieldName] = fieldDelta.value
                        updateRequired = true
                    } else {
                        for (const origKey in fieldDelta.value) {
                            if (fieldDelta.value.hasOwnProperty(origKey)) {
                                // @ts-ignore
                                if (newRow[origKey] !== fieldDelta.value[origKey]) {
                                    // @ts-ignore
                                    newRow[origKey] = fieldDelta.value[origKey]
                                    updateRequired = true
                                }
                            }
                        }
                    }
                    if (notBlank(fieldDelta.postJs)) {
                        /* eslint-disable no-new-func */
                        const func = new Function('self', 'row', fieldDelta.postJs + '; process(row);')
                        func(this, newRow)
                    }

                    if (entry.highlightDelta) {
                        newRow.flash = true
                    }
                    changedRow = newRow
                    index = idx
                }
            })

            if (changedRow && entry.tableData && updateRequired) {
                entry.tableData[index] = changedRow
            }
        } else if (fieldDelta.action === DeltaAction.INSERT) {
            entry.tableData?.push(insertRow(fieldDelta))
        } else if (fieldDelta.action === DeltaAction.REMOVE) {
            ;(entry?.tableData || []).forEach((row: TableRowDTO, idx: number) => {
                // const newRow: TableRowDTO = clone(current(row))
                const newRow: TableRowDTO = clone(row)
                // @ts-ignore
                if (!fieldDelta.keyField || newRow[fieldDelta.keyField] + '' === fieldDelta.keyValue) {
                    index = idx
                }
            })
            if (index > -1) {
                entry.tableData?.splice(index, 1)
            }
        }
    }
    return entry
}

// -------------- config
export const guslTableSlice = createSlice({
    name: 'guslTableSlice',
    initialState,
    reducers: {
        initTable(state, action: PayloadAction<GuslTableInitPayload>) {
            const code = action.payload.code
            const entry: GuslTableState = getTableState(state, code)
            loadInitValues(entry, action.payload, code)
            state[action.payload.code] = entry
        },
        deltaUpdate(state, action: PayloadAction<DeltaUpdatePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            applyDelta(entry, action.payload.fieldDelta)
        },
        dataUpdate(state, action: PayloadAction<GuslTableDataUpdatePayload>) {
            const newState = Object.assign({}, state)
            const entry: GuslTableState = getTableState(state, action.payload.code)
            updateData(entry, action.payload.response)
            // newState[action.payload.code] = entry
            // return newState
        },
        toggleInline(state, action: PayloadAction<GuslTableInlineTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.inlineAction.showInline = !entry.inlineAction.showInline
            entry.inlineAction.activeItem = action.payload.menuItem
            state[action.payload.code] = entry
        },
        toggleCreateEntity(state, action: PayloadAction<GuslTableCreateEntityTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.createEntityOpened = !entry.createEntityOpened
            entry.tableDeltaUpdateActive = !entry.createEntityOpened
            state[action.payload.code] = entry
        },
        cleanUp(state, action: PayloadAction<GuslTableCleanUpPayload>) {
            delete state[action.payload.code]
        },
        toggleFilters(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            // MK 27/08/2023
            if (!entry.showFilters) {
                entry.individualAdvancedSearchFields = []
            }
            entry.showFilters = !entry.showFilters
            if (entry.showColumnsSettings) {
                entry.showColumnsSettings = false
            }
            // MK 11/08/2023
            if (entry.showTopFilters) {
                entry.showTopFilters = false
            }
        },
        // MK 11/08/2023
        toggleTopFilters(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            // MK 27/08/2023
            if (!entry.showTopFilters) {
                entry.individualAdvancedSearchFields = []
            }
            entry.showTopFilters = !entry.showTopFilters
            if (entry.showFilters) {
                entry.showFilters = false
            }
        },
        toggleColumnsSettings(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.showColumnsSettings = !entry.showColumnsSettings
            if (entry.showFilters) {
                entry.showFilters = false
            }
        },
        toggleFieldDisplay(state, action: PayloadAction<GuslTableToggleColumnDisplayPayload>) {
            const code = action.payload.code
            const entry: GuslTableState = getTableState(state, code)
            const payloadField = action.payload.field
            entry.allFields
                .filter((field) => field.name === payloadField.name)
                .forEach((field) => (field.displayInTable = !field.displayInTable))
            guslStorage.saveOrderedFields(code, entry.allFields)
            state[code] = entry
        },
        reOrderColumn(state, action: PayloadAction<GuslTableReorderColumnDisplayPayload>) {
            const code = action.payload.code
            const entry: GuslTableState = getTableState(state, code)
            reorderColumns(entry, action.payload.firstIdx, action.payload.lastIdx)
            guslStorage.saveOrderedFields(code, entry.allFields)
            state[code] = entry
        },
        toggleSearchBox(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.showSearchBox = !entry.showSearchBox
        },
        toggleSearchableField(state, action: PayloadAction<GuslTableSearchableFieldTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            toggleSearchField(entry, action.payload.field)
            state[action.payload.code] = entry
        },
        resetSearchableField(state, action: PayloadAction<GuslTableSearchableFieldResetPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            const defaultSearchableFields: FieldConfigDTO[] = action.payload.defaultSearchableFields as FieldConfigDTO[]
            entry.searchableFields = defaultSearchableFields
            entry.searchPrompt = getSearchPrompt(defaultSearchableFields)
            //resetSearchField(entry)
            state[action.payload.code] = entry
        },
        setSearchString(state, action: PayloadAction<GuslTableSearchStringPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.searchString = action.payload.searchString
            state[action.payload.code] = entry
        },
        setServerRangeQueries(state, action: PayloadAction<GuslTableServerRangeQueryPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)

            entry.serverRangeQueries = action.payload.serverRangeQueries
            state[action.payload.code] = entry
        },
        //  MK 14-08-2024
        setServerQueryParameters(state, action: PayloadAction<GuslTableServerQueryParamsPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)

            entry.serverQueryParams = action.payload.serverQueryParams
            state[action.payload.code] = entry
        },
        setResponseTotal(state, action: PayloadAction<GuslTableResponseTotalPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.responseTotal = action.payload.responseTotal
        },
        setEditRowId(state, action: PayloadAction<GuslTableEditRowPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.editRowId = action.payload.editRowId
        },
        setScrollLeftPosition(state, action: PayloadAction<GuslTableScrollLeftPositionPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.scrollLeftPosition = action.payload.scrollLeftPosition
            state[action.payload.code] = entry
        },

        toggleFavQueries(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.favQueriesOpen = !entry.favQueriesOpen
        },
        toggleMobileTableControls(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.showMobileTableControls = !entry.showMobileTableControls
            state[action.payload.code] = entry
        },
        turnOffTableUpdates(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            if (entry) {
                entry.tableDeltaUpdateActive = false
            }
        },
        turnOnTableUpdates(state, action: PayloadAction<GuslTableFilterTogglePayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            if (entry) {
                entry.tableDeltaUpdateActive = true
            }
        },
        openCard(state, action: PayloadAction<GuslTableCardOpenedPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            if (entry) {
                entry.cards[action.payload.uniqueId] = true
            }
        },
        closeCard(state, action: PayloadAction<GuslTableCardOpenedPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            if (entry) {
                entry.cards[action.payload.uniqueId] = false
            }
        },
        incrementCounter(state, action: PayloadAction<IncrementCounterPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            if (entry) {
                entry.refreshCounter = entry.refreshCounter + 1
            }
        },

        // MK 19/08/2023
        handleIndividualAdvancedSearchFields(state, action: PayloadAction<GuslTableSearchableIndividualFieldPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            const currentField: string = action.payload.field
            const currentFields: string[] = entry.individualAdvancedSearchFields
            const alreadyIn: boolean = currentFields.includes(currentField)

            if (alreadyIn) {
                entry.individualAdvancedSearchFields = currentFields.filter((field) => field !== currentField)
            } else {
                entry.individualAdvancedSearchFields.push(action.payload.field)
            }
            // MK 27/08/2023
            entry.showFilters = false
            state[action.payload.code] = entry
        },
        // END OF 19/08/2023

        // MK 06-01-2024
        handleFavQueriesUpdates(state, action: PayloadAction<FavQueryUpdatedAtPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.favQueriesUpdatedAt = new Date().getTime()
            state[action.payload.code] = entry
        },

        // MK 11-01-2024
        updateFavQueries(state, action: PayloadAction<GuslTableFavQueriesPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.favQueries = action.payload.favQueries
            state[action.payload.code] = entry
        },
        toggleRowSelection(state, action: PayloadAction<GuslTableToggleRowSelectPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            applyRowSelected(entry, action.payload.row, action.payload.withShiftKey, action.payload.rowIndex, action.payload.selected)
            state[action.payload.code] = entry
        },
        clearAllRowSelections(state, action: PayloadAction<GuslTableClearAllRowSelectionPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            performClearAllRowSelections(entry, action.payload.code)
            state[action.payload.code] = entry
        },

        setCurrentContentTotal(state, action: PayloadAction<GuslTableCurrentContentTotalPayload>) {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            entry.currentContentTotal = action.payload.currentContentTotal
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getTableData.fulfilled, (state, action: PayloadAction<TableResponseWrapper>) => {
            const entry: GuslTableState = getTableState(state, action.payload.code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
        builder.addCase(getTableData.pending, (state, action: PayloadAction<TableResponseWrapper>) => {
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: GuslTableState = getTableState(state, code)
                entry.loading = true
            }
        })
    },
})

export const {
    initTable,
    toggleInline,
    toggleCreateEntity,
    cleanUp,
    toggleFilters,
    toggleColumnsSettings,
    toggleSearchBox,
    toggleFieldDisplay,
    reOrderColumn,
    setServerRangeQueries,
    toggleSearchableField,
    setEditRowId,
    resetSearchableField,
    setSearchString,
    deltaUpdate,
    // setScrollLeftPosition,
    toggleFavQueries,
    toggleMobileTableControls,
    turnOffTableUpdates,
    turnOnTableUpdates,
    openCard,
    closeCard,
    incrementCounter,

    // MK 12/08/2023
    toggleTopFilters,
    setResponseTotal,
    // END OF 12/08/2023

    // MK 19/08/2023
    handleIndividualAdvancedSearchFields,
    // END OF 19/08/2023

    // MK 06-01-2024
    handleFavQueriesUpdates,

    //MK 11-01-2024
    updateFavQueries,
    toggleRowSelection,
    clearAllRowSelections,
    //MK 14-08-2024
    setServerQueryParameters,
    setCurrentContentTotal,
} = guslTableSlice.actions

export default guslTableSlice.reducer
