import React, { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../app/hooks'
import { SystemContext } from '../../../../../providers/system/SystemContext'
import { ChildReference, IMenuDTO } from '../../../../types'
import { LookupModal } from '../../../lookup-modal/LookupModal'
import { initForm } from '../../../maintain-form/guslFormSlice'
import { getLookupFieldProperties } from '../../form-properties'
import { TickerDO } from '../../types'

interface TickerLookupProperties {
    code: string
    onTickerSelected: Function
    ticker: TickerDO | undefined
}

export const TickerLookup = (properties: TickerLookupProperties): React.ReactElement<TickerLookupProperties> => {
    const [className] = useState('TickerLookup-' + new Date().getTime())

    const systemContext = useContext(SystemContext)
    const dispatch = useAppDispatch()

    const [menuItem, setMenuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(properties.code))

    const fields = menuItem?.action?.rows[0].columns[0].fields || menuItem?.singlePage?.rows[0]?.columns[0]?.fields || []

    const getFieldConfig = (_field: string) => {
        return fields?.filter((field) => field.name === _field)[0]
    }

    const registerChildReference = (reference: ChildReference) => {}

    const [lookupFieldProperties, setLookupFieldProperties] = useState<any | undefined>(() => {
        const lookupProperties = getLookupFieldProperties(properties.code, registerChildReference, getFieldConfig('ticker'))
        lookupProperties.data = { ...properties.ticker }
        return lookupProperties
    })

    useEffect(() => {
        if (properties.ticker) {
            const lookup = getLookupFieldProperties(properties.code, registerChildReference, getFieldConfig('ticker'))
            lookup.data = { ...properties.ticker }
            setLookupFieldProperties(lookup)
            dispatch(
                initForm({
                    code: properties.code,
                    fields: lookup?.fieldConfig?.innerFields || [],
                    formData: { ...properties.ticker },
                    rowData: { ...properties.ticker },
                })
            )
        }
    }, [properties.ticker])

    const getCurrentRowData = () => {
        return {}
    }

    return (
        <LookupModal
            key={properties.code}
            code={properties.code}
            formMode={lookupFieldProperties.formMode}
            fieldConfig={lookupFieldProperties.fieldConfig}
            menuItem={lookupFieldProperties.menuItem}
            data={lookupFieldProperties.data}
            onSubmitted={() => {}}
            onChange={() => {}}
            onReset={() => {
                console.log('-- on reset')
                // reset(false, buySellState.orderAction)
            }}
            getCurrentRowData={() => {}}
            reference={lookupFieldProperties.reference}
            setTicker={(selectedTicker: TickerDO) => {
                const value = { ...lookupFieldProperties }
                value.data = selectedTicker?.symbol
                setLookupFieldProperties(value)
                if (selectedTicker) {
                    if (properties.onTickerSelected) {
                        properties.onTickerSelected(selectedTicker)
                    }
                }
            }}
            reset={0}
            hasLabel={true}
        />
    )
}
/*

        <div>
            <p>ticker lookup</p>
</div>

 */
