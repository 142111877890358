import React, { useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { log } from '../../../services/LogService'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { ErrorMessageStyled } from '../../styled'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { OptionViewStyled } from '../option/styled'
import { PixelFieldStyled, PixelInputStyled } from '../pixel/styled'
import { FloatingFormStyled, InputStyled, TextFieldStyled } from '../text/styled'
import { FontSizeFieldStyled } from './styled'

export const FontSizeField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'FontSizeField-' + new Date().getTime())
    const valueRef = useRef(properties?.data)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data?.replace('px', '') || '');
    const [formValue, setFormValue] = useState<string>(
        (_guslFormState?.getFieldValue(properties) || properties.data || '').replace('px', '')
    )

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data?.replace('px', '') || '')
    }

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                <span className="" style={style}>
                    {formValue}
                </span>
            </TextFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const onDataInputChange = (value: string) => {
            log.info(className, 'MSG003', 'onDataInputChange', valueRef?.current?.value, formValue, value)
            setFormValue(value)
            properties.onChange(properties.fieldConfig.name, value + 'px')
        }

        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (hideField) {
            return <></>
        }

        if (formMode === FormMode.VIEW || disableField) {
            return (
                <>
                    <FloatingFormStyled>
                        <PixelFieldStyled>
                            <span>{formValue} px</span>
                        </PixelFieldStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                </>
            )
        }

        return (
            <>
                <FloatingFormStyled>
                    <FontSizeFieldStyled>
                        <PixelInputStyled
                            type={'number'}
                            id={properties?.fieldConfig?.name || 'def'}
                            key={properties?.fieldConfig?.name || 'def'}
                            ref={valueRef}
                            value={formValue}
                            autoFocus={properties.isFirstField}
                            readOnly={disableField}
                            disabled={disableField}
                            onChange={(e) => onDataInputChange(e.target.value)}
                            required={required}
                            submitted={submitted}
                            noValue={!formValue}
                        />
                    </FontSizeFieldStyled>
                    <FieldLabel properties={properties} />
                    {submitted && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                </FloatingFormStyled>
            </>
        )
        // }
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
