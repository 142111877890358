import styled from 'styled-components'
import { CONTENT_START_POSITION, HEADER_HEIGHT, TAB_HEIGHT } from '../../../providers/theme/GuslThemeProvider'

export const TradingPageStyled = styled.div.attrs((props) => ({
    className: 'row-g-0',
    id: 'trading_page',
}))``

interface TradingBodyProperties {
    isMobile: boolean
    startPos: number
    footerHeight: number
}

export const TradingBodyStyled = styled.div.attrs((props: TradingBodyProperties) => ({
    className: 'col-12 mx-auto mt-3',
    id: 'trading_body',
}))<TradingBodyProperties>`
    overflow: auto;
    height: ${(props) => {
        if (!props.isMobile) {
            return '100%'
        }
        return 'calc(var(--internal-height) - env(safe-area-inset-bottom) - ' + (props.startPos + props.footerHeight) + 'px)'
    }};
`

export const TradingPanelStyled = styled.div.attrs((props) => ({
    id: 'trading_panel',
}))`
    padding-bottom: 20px;
`

interface TradingContainerProperties {
    isMobile?: boolean
    isTradePanel?: boolean
    height?: string
    isEdit?: boolean
    isIOS: boolean
    footerHeight: number
}

export const TradingContainerStyled = styled.div.attrs((props: TradingContainerProperties) => ({
    className: '',
    id: 'trading_container',
}))<TradingContainerProperties>`
    padding: ${(props) => (props.isMobile ? '0px 3px 0px 3px' : '0 20px 0 20px')};
    overflow: auto;
    max-height: ${(props) => {
        if (props.isTradePanel && props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) +
                'px)'
            )
        } else if (!props.isTradePanel && props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) +
                'px)'
            )
        } else if (props.height && props.isEdit) {
            return 'calc(' + props.height + ' - 120px);'
        } else if (props.height) {
            return 'calc(' + props.height + ' - 55px);'
        } else if (props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) +
                'px)'
            )
        } else if (props.isEdit) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION + 22) +
                'px)'
            )
        } else {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) +
                'px)'
            )
        }
    }};

    height: 100%;

    .form-control:not(.lookup):not(.cron) {
        border: none;
        box-shadow: none !important;
    }

    .shadow {
        box-shadow: none !important;
    }
`

export const TradingLayoutStyled = styled.div.attrs((props) => ({
    className: '',
    id: 'trading_layout',
}))`
    line-height: 30px;

    .form-control:not(.lookup):not(.cron) {
        width: 200px;
        padding-left: 0;
        margin-left: 7px;
    }

    .form-floating > .form-control:not(:placeholder-shown) ~ label {
        display: none;
    }

    .form-floating > .form-control:not(:placeholder-shown) {
        padding: 0;
    }
`

export const TradingRowStyled = styled.div.attrs((props) => ({
    className: 'row',
    id: 'trading_row',
}))``

interface TradingColProperties {
    fullWidth?: boolean
    widgetExpanded?: boolean
    numCols?: number
}

const getColumnSize = (fullWidth: boolean | undefined, widgetExpanded: boolean | undefined, numCols: number | undefined) => {
    if (widgetExpanded) {
        return ' d-none '
    }
    if (fullWidth) {
        return ' col-lg-12 '
    }
    if (numCols) {
        return ' col-lg-' + numCols + ' '
    }
    return ' col-lg-6 '
}
export const TradingColStyled = styled.div.attrs((props: TradingColProperties) => ({
    className: 'p-0 m-0 ' + getColumnSize(props.fullWidth, props.widgetExpanded, props.numCols),
    id: 'trading_col',
}))<TradingColProperties>``

export const TradingColWrapperStyled = styled.div.attrs((props) => ({}))`
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0 5px 8px -4px;
    border: 1px solid rgb(226, 226, 226);
    margin: 6px 3px 0 3px; ;
`

export const TradingCol3Styled = styled.div.attrs((props: TradingColProperties) => ({
    className: 'p-0 m-0 ' + (props.fullWidth ? ' col-lg-12 ' : ' col-lg-6 '),
    id: 'trading_col',
}))<TradingColProperties>``
