import React from 'react'
import { useAppSelector } from '../../../../../../../app/hooks'
import { BuySellOrder, OrderAction } from '../../../../../buy-sell-form/types'
import Money from '../../../../../money/money'
import { TradingState } from '../../../../tradingSlice'
import RenderPlaceOrderButtons from './RenderPlaceOrderButtons'
import { DisplayContainerStyled, DisplayFieldPanelStyled, DisplayFieldStyled, DisplayLabelStyled, DisplayValueStyled } from './styled'

export interface RenderDisplayInfoDO {
    code: string
}

export default function RenderDisplayInfo({ code }: RenderDisplayInfoDO) {
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const order: BuySellOrder | undefined = _tradingSlice?.order

    return (
        <>
            <DisplayContainerStyled>
                <DisplayFieldPanelStyled isMobile={true}>
                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Free equity</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <Money amount={_tradingSlice.freeEquity} key={'freeEquity'} />
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    {_tradingSlice.orderAction !== OrderAction.CLOSE && (
                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Available equity</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={_tradingSlice.availableEquity} key={'availableEquity'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>
                    )}

                    {_tradingSlice.orderAction !== OrderAction.CLOSE && (
                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Margin requirement ({(_tradingSlice.margin || 0) * 100} %)</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={_tradingSlice.marginRequired} key={'marginRequired'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>
                    )}

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Order Price</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <span>
                                {order?.getOrderPrice()?.toLocaleString('en-GB', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                })}
                            </span>
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Quantity</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <span className={_tradingSlice.canOrder ? '' : 'invalid'}>{order?.getQuantity().toLocaleString()}</span>
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Notional</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <Money amount={_tradingSlice.notionalPrice} key={'notionalPrice'} />
                        </DisplayValueStyled>
                    </DisplayFieldStyled>
                </DisplayFieldPanelStyled>
                <RenderPlaceOrderButtons code={code} />
            </DisplayContainerStyled>
        </>
    )
}
