import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { SystemContext } from '../../../providers/system/SystemContext'
import { CONTENT_START_POSITION } from '../../../providers/theme/GuslThemeProvider'
import { cancelAbortController, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { BlankLineStyled, BoxStyled } from '../buy-sell-form/styled_v2'
import { OrderAction } from '../buy-sell-form/types'
import { Blotter } from '../buy-sell-form/v2_components/blotter/Blotter'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import { TradingBlotter } from './components/blotter/TradingBlotter'
import { OrderForm } from './components/orderform/OrderForm'
import { TickerHeadline } from './components/ticker-headline/TickerHeadline'
import { TradingWidgets } from './components/trading-widgets/TradingWidgets'
import { WidgetMainContainerStyled } from './components/watchlist/styled'
import Watchlist from './components/watchlist/Watchlist'
import {
    TradingBodyStyled,
    TradingColStyled,
    TradingColWrapperStyled,
    TradingContainerStyled,
    TradingLayoutStyled,
    TradingPageStyled,
    TradingPanelStyled,
    TradingRowStyled,
} from './styled'
import { getLimitStep, getOrderAction } from './traderUtils'
import {
    cleanUpTradingPage,
    getPreOrder,
    getTradingData,
    initTradingPage,
    PreOrderResponseWrapper,
    showApiError,
    TradingResponseWrapper,
    TradingState,
} from './tradingSlice'

export const TRADING_HOME_PAGE = '/bespoke/TradingPage'
export const TradingPage = (): React.ReactElement => {
    const [className] = useState('BlotterPage-' + new Date().getTime())
    const [code] = useState<string>('blotter')

    const systemContext = useContext(SystemContext)
    const sessionContext = useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined)
    const [preOrderAbortController, setPreOrderAbortController] = useState<AbortController | undefined>(undefined)

    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const [systemReady, setSystemReady] = useState<boolean>(false)
    const isMobile = environmentContext.isMobileDevice()
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS())

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const params = useParams()

    const registerChildReference = () => {}

    const tickerParam = params?.tickerId ? decodeURIComponent(params?.tickerId) : undefined
    const actionParam = params?.action ? decodeURIComponent(params?.action) : undefined

    const menuItem = systemContext
        .getSystemConfig()
        .menuGroups.filter((menuGroup) => menuGroup?.label === 'Trading')[0]
        ?.menuItems?.filter((menuItem) => menuItem?.code === 'Blotter')[0]

    const [limitStep] = useState<number>(getLimitStep())

    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            return () => {
                unSubscribe(loaderSubscription)
            }
        })
    })

    const contentElement = useRef(null)
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION)
    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [contentElement])

    useEffect(() => {
        if (systemReady) {
            dispatch(
                initTradingPage({
                    code: code,
                    tickerParam: tickerParam,
                    actionParam: actionParam,
                    menuItem: menuItem,
                    registerChildReference: registerChildReference,
                })
            )
            getData()
        }
        return () => {
            cancelAbortController(currentAbortController)
            cancelAbortController(preOrderAbortController)
            dispatch(cleanUpTradingPage({ code: code }))
        }
    }, [systemReady, tickerParam, actionParam])

    const showError = (errorMessage: string) => {
        dispatch(
            showApiError({
                code: code,
                hasError: true,
                errorMessage: errorMessage,
                apiError: false,
            })
        )
    }
    const performPreOrderQuery = (ticker: string, orderAction: OrderAction) => {
        console.log('performPreOrderQuery')
        const abortController = new AbortController()
        setPreOrderAbortController(abortController)

        dispatch(
            getPreOrder({
                code: code,
                sessionContext: sessionContext,
                abortController: abortController,
                id: ticker,
                orderAction: orderAction,
            })
        )
            .unwrap()
            .then((reply: PreOrderResponseWrapper) => {})
            .catch((error: any) => {
                console.error('ERR002 Error', error)
                showError('Error with pre order, contact support.')
            })
    }
    const getData = () => {
        console.log('getting data')
        const abortController = new AbortController()
        setCurrentAbortController(abortController)

        dispatch(
            getTradingData({
                code: code,
                sessionContext: sessionContext,
                abortController: abortController,
            })
        )
            .unwrap()
            .then((reply: TradingResponseWrapper) => {
                const currentTicker = reply?.response?.tickers[0]
                if (tickerParam && currentTicker) {
                    performPreOrderQuery(currentTicker?.id, getOrderAction(actionParam))
                    console.log('all ok', currentTicker)
                } else if (tickerParam && !currentTicker) {
                    navigate(TRADING_HOME_PAGE)
                } else {
                    navigate(TRADING_HOME_PAGE + '/' + currentTicker?.symbol || '')
                }
            })
            .catch((error: any) => {
                console.error('ERR001 Error', error)
                showError('Contract not mapped')
            })
    }

    const renderBuySellForm = (): React.ReactElement => {
        if (_tradingSlice?.hasPreOrderError) {
            return (
                <WidgetMainContainerStyled>
                    <BoxStyled>
                        <span className={'invalid xs-small'}>{_tradingSlice?.preOrderErrorMessage || 'Error'}</span>
                    </BoxStyled>
                </WidgetMainContainerStyled>
            )
        }
        return (
            <WidgetMainContainerStyled>
                <OrderForm code={code} />
            </WidgetMainContainerStyled>
        )
    }

    const renderContent = (): React.ReactElement => {
        console.log(' _blotterSlice?.ticker', _tradingSlice?.ticker)
        return (
            <TradingLayoutStyled>
                <TradingRowStyled>
                    <TradingColStyled fullWidth={true}>
                        <TradingColWrapperStyled>
                            <TickerHeadline code={code} />
                        </TradingColWrapperStyled>
                    </TradingColStyled>
                    <TradingColStyled fullWidth={_tradingSlice?.widgetExpanded}>
                        <TradingColWrapperStyled>
                            <TradingWidgets code={code} />
                        </TradingColWrapperStyled>
                    </TradingColStyled>
                    <TradingColStyled widgetExpanded={_tradingSlice?.widgetExpanded} numCols={3}>
                        <TradingColWrapperStyled>
                            <Watchlist />
                        </TradingColWrapperStyled>
                    </TradingColStyled>
                    <TradingColStyled widgetExpanded={_tradingSlice?.widgetExpanded} numCols={3}>
                        <TradingColWrapperStyled>{renderBuySellForm()}</TradingColWrapperStyled>
                    </TradingColStyled>
                    <TradingColStyled fullWidth={true}>
                        <TradingColWrapperStyled>
                            <TradingBlotter code={code} />
                        </TradingColWrapperStyled>
                    </TradingColStyled>
                </TradingRowStyled>
            </TradingLayoutStyled>
        )
    }

    const renderPage = (): React.ReactElement => {
        if (!_tradingSlice?.order) {
            return <></>
        }

        return (
            <TradingPageStyled>
                <TradingBodyStyled isMobile={isMobile} ref={contentElement} startPos={startPos} footerHeight={footerHeight}>
                    <TradingContainerStyled isMobile={isMobile} isTradePanel={false} isIOS={isIOS} footerHeight={footerHeight}>
                        <TradingPanelStyled>
                            {!_tradingSlice?.isCloseOut && renderContent()}
                            <BlankLineStyled>&nbsp;</BlankLineStyled>
                        </TradingPanelStyled>
                    </TradingContainerStyled>
                </TradingBodyStyled>
            </TradingPageStyled>
        )
    }

    return <>{_tradingSlice?.loaded ? renderPage() : <LoadingSpinner />}</>
}
