import { FieldDeltaDO } from '../../../providers/blast/commands'
import { MoneyDTO } from '../../types'

export enum OrderAction {
    NEW = 'NEW',
    CLOSE = 'CLOSE',
    TRADE = 'TRADE',
}

export const getOrderAction = (action: string | undefined): OrderAction => {
    if (!action) {
        return OrderAction.NEW
    }
    switch (action.toUpperCase()) {
        case 'CLOSE':
            return OrderAction.CLOSE
        case 'TRADE':
            return OrderAction.TRADE
        default:
            return OrderAction.NEW
    }
}

export interface PreOrderDTO {
    ticker: string
    bidPrice: number
    askPrice: number
    freeEquity: MoneyDTO
    isDelayed: boolean
    quantity: number
    margin: number
    ccy: string
    hasError: boolean
    errorMessage: string
    side: 'BUY' | 'SELL' | undefined
    orderType: string
    validity: string
    isShort: boolean
    orderAction: OrderAction
    concd: string
    dma: boolean

    dmaMessage: string

    spdMessage: string

    delayedMessage: string

    isSpreadBet: boolean
    acuityCode?: number | undefined
}

export interface BuySellOrder {
    setTicker: (ticker: string) => void
    getTicker: () => string
    getLimitPrice: () => number
    setLimitPrice: (limitPrice: number) => void

    getOriginalQuantity: () => number

    getQuantity: () => number
    setQuantity: (quantity: number) => void
    getSide: () => 'BUY' | 'SELL' | undefined
    setSide: (side: 'BUY' | 'SELL' | undefined) => void
    getOrderType: () => string
    setOrderType: (orderType: string) => void
    getValidity: () => string
    setValidity: (validity: string) => void
    setBuyPrice: (askPrice: number) => void
    setSellPrice: (bidPrice: number) => void
    isShort: () => boolean
    isCloseout: () => boolean
    isDelayed: () => boolean
    isDma: () => boolean
    getMaxQuantity: () => number
    getNotional: () => number
    getPrice: () => number
    isLimitOrder: () => boolean
    getBuyPrice: () => number
    getSellPrice: () => number
    getConcd: () => string
    getOrderPrice: () => number
    setNoLongerDelayed: () => void
    clone: () => Order

    getDmaMessage: () => string

    getSpdMessage: () => string

    getDelayedMessage: () => string

    isSpd: () => boolean
}

export class Order implements BuySellOrder {
    private preOrder: PreOrderDTO
    public canProceed: boolean
    private errorMessage: string
    private hasError: boolean
    private side: 'BUY' | 'SELL' | undefined
    public quantity: number
    public limitPrice: number
    public orderType: string
    public validity: string

    constructor(readonly preOrderDTO: PreOrderDTO) {
        this.preOrder = preOrderDTO
        this.canProceed = false
        this.errorMessage = preOrderDTO.errorMessage
        this.hasError = preOrderDTO.hasError
        this.side = preOrderDTO.side
        this.quantity = 0
        this.limitPrice = 0
        this.orderType = preOrderDTO.orderType
        this.validity = preOrderDTO.validity
    }

    setTicker(ticker: string) {
        this.preOrder.ticker = ticker
    }

    getTicker() {
        return this.preOrder.ticker
    }

    getMaxQuantity() {
        if (this.preOrderDTO.isShort) {
            return this.preOrderDTO.quantity
        }

        return Number.MAX_VALUE
    }

    isShort() {
        return this.preOrderDTO.isShort
    }

    isCloseout() {
        return this.preOrderDTO?.orderAction === OrderAction.CLOSE
    }

    isDelayed() {
        return this.preOrderDTO.isDelayed
    }

    setNoLongerDelayed() {
        this.preOrderDTO.isDelayed = false
    }

    isLimitOrder(): boolean {
        return this.orderType === 'LIMIT'
    }

    setSide(value: 'BUY' | 'SELL' | undefined) {
        this.side = value
    }

    getSide(): 'BUY' | 'SELL' | undefined {
        return this.side
    }

    getOrderType() {
        return this.orderType
    }

    getValidity() {
        return this.validity
    }

    getPrice(): number {
        if (this.side === 'SELL') {
            return this.getSellPrice()
        }

        return this.getBuyPrice()
    }

    getNotional(): number {
        if (this.isLimitOrder()) {
            return this.limitPrice * this.quantity * 100
        }

        return this.getPrice() * this.quantity * 100
    }

    getOrderPrice(): number {
        if (this.isLimitOrder()) {
            return this.limitPrice
        }

        return this.getPrice()
    }

    getBuyPrice(): number {
        return this.preOrderDTO.askPrice
    }

    getSellPrice(): number {
        return this.preOrderDTO.bidPrice
    }

    setBuyPrice(price: number): void {
        this.preOrderDTO.askPrice = price
    }

    setSellPrice(price: number): void {
        this.preOrderDTO.bidPrice = price
    }

    getHasError() {
        return this.hasError
    }

    resetError() {
        this.errorMessage = ''
        this.hasError = false
    }

    setError(message: string) {
        this.errorMessage = message
        this.hasError = true
    }

    reset() {
        this.quantity = 0
        this.limitPrice = 0
    }

    getConcd() {
        return this.preOrderDTO.concd
    }

    getDmaMessage() {
        return this.preOrder.dmaMessage
    }

    getSpdMessage() {
        return this.preOrder.spdMessage
    }

    getDelayedMessage() {
        return this.preOrder.delayedMessage
    }

    updateBlastDelta(delta: FieldDeltaDO | undefined) {
        if (delta && this.preOrder?.ticker === delta.keyValue) {
            if (delta.value?.ask) {
                this.preOrderDTO.askPrice = delta.value.ask
            }
            if (delta.value?.bid) {
                this.preOrderDTO.bidPrice = delta.value.bid
            }
        }
    }

    getLimitPrice(): number {
        return this.limitPrice
    }

    setLimitPrice(limitPrice: number): void {
        this.limitPrice = limitPrice
    }

    setOrderType(orderType: string): void {
        this.orderType = orderType
    }

    getOriginalQuantity(): number {
        return this.preOrder.quantity
    }

    getQuantity(): number {
        return this.quantity
    }

    setQuantity(quantity: number): void {
        this.quantity = quantity
    }

    setValidity(validity: string): void {
        this.validity = validity
    }

    isDma(): boolean {
        return this.preOrderDTO.dma
    }

    isSpd(): boolean {
        return this.preOrderDTO.isSpreadBet
    }

    clone(): Order {
        const order: Order = new Order(this.preOrderDTO)
        order.canProceed = this.canProceed
        order.errorMessage = this.errorMessage
        order.hasError = this.hasError
        order.side = this.side
        order.quantity = this.quantity
        order.limitPrice = this.limitPrice
        order.orderType = this.orderType
        order.validity = this.validity
        return order
    }
}

export interface TickerDO {
    id: string
    name: string
    symbol: string
    displayTicker?: string
    bbgTicker?: string
}

export interface ListViewTickerDO {
    symbol: string
    del: string
    putcall?: string
    strikePrice?: number
}
