import styled, { ThemeProps } from 'styled-components'
import { CONTENT_START_POSITION, HEADER_HEIGHT, TAB_HEIGHT } from '../../../providers/theme/GuslThemeProvider'

export const BlankLineStyled = styled.div.attrs((props) => ({}))`
    height: 50px;
`

export const DisclaimerStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor};
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 10px 40px 10px;
`
export const DisclaimerHeaderStyled = styled.span.attrs((props) => ({}))`
    color: ${(props) => props.theme.panel.titleColor};
    font-size: 12px;
    font-weight: bold;
    padding-left: 60px;
`
export const DisclaimerHeaderDivStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.panel.titleColor};
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
`

export const BuySellPanelStyled = styled.div.attrs((props) => ({
    // className: 'p-3'
}))`
    // background-color:${(props) => props.theme.modal.innerBackground} ;
    padding-bottom: 20px;
`

interface BuySellBodyProperties {
    isMobile: boolean
    startPos: number
    footerHeight: number
}

export const BuySellBodyStyled = styled.div.attrs((props: BuySellBodyProperties) => ({
    className: 'col-12 mx-auto mt-3',
}))<BuySellBodyProperties>`
    overflow: auto;
    height: ${(props) => {
        if (!props.isMobile) {
            return '100%'
        }
        return 'calc(var(--internal-height) - env(safe-area-inset-bottom) - ' + (props.startPos + props.footerHeight) + 'px)'
    }};
`

interface InputStyledProps extends ThemeProps<any> {}

interface InputGroupIconProps extends ThemeProps<any> {
    className: string
}

export const InputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: 'form-control form-control-sm   border-top-0 border-start-0 border-end-0 text-light ',
}))<InputStyledProps>`
    color: ${(props) => props.theme.colors.dark[0]} !important;
    font-size: 12px;
    width: 45% !important;
    padding: 5px !important;
`
export const InputGroupIconWrapperStyled = styled.span.attrs((props) => ({
    className: 'input-group-text ',
}))`
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #fafafa;
    border: none;
`
export const InputGroupResultsWrapperStyled = styled.span.attrs((props) => ({
    className: 'input-group-text ',
}))`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #fafafa;
    border: none;
`
export const InputGroupIconStyled = styled.i.attrs((props: InputGroupIconProps) => ({
    className: props.className,
}))<InputGroupIconProps>`
    color: #918d8d;
`
export const InputGroupInputStyled = styled.input.attrs((props) => ({
    className: 'form-control ',
}))`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #fafafa;
    border: none;

    &&:focus {
        outline: none !important;
        border-color: inherit;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
`

export const ErrorStyled = styled.p.attrs((props) => ({
    className: 'small invalid',
}))`
    font-size: 11px;
`

export const DisplayContainerStyled = styled.div.attrs((props) => ({
    className: 'row g-0 d-flex ',
}))``
export const ErrorPanelStyled = styled.div.attrs((props) => ({
    className: 'invalid text-center ',
}))`
    font-size: 11px;
`

interface DisplayFieldPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean
}

export const DisplayFieldPanelStyled = styled.div.attrs((props: DisplayFieldPanelStyledProps) => ({
    className: 'mb-3 ' + (props.isMobile ? 'col-12' : 'col-6'),
}))<DisplayFieldPanelStyledProps>`
    text-align: center;
    line-height: 25px;
    margin: 0 20px 0 0;
    padding: 0 15px 0 10px;
    border-radius: 3px;
`

export const DisplayFieldStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-between text-light',
}))`
    // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;
`

export const DisplayLabelStyled = styled.span.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: 12px;
`

export const DisplayValueStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

interface ActionButtonPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean
    side?: string
    canShowInfo: boolean
    tickerId: string
}

export const ActionButtonPanelStyled = styled.div.attrs((props: ActionButtonPanelStyledProps) => ({
    className:
        'align-items-center justify-content-evenly ' +
        'col-' +
        (props.side && !props.isMobile && props.canShowInfo && props.tickerId ? '6' : '12'),
}))<ActionButtonPanelStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

export const ActionButtonsStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-evenly',
}))``

export const ActionButtonStyled = styled.div.attrs((props) => ({
    className: 'p-1 mx-1',
}))`
    background-color: ${(props) => props.theme.colors.background[1]};
    border-radius: 20px;
`
export const ActionButtonStyledOtto = styled.div.attrs((props) => ({
    className: 'p-1 mx-1',
}))`
    background-color: ${(props) => props.theme.colors.dark[1]};
    border-radius: 20px;
`
interface BuySellSelectionPanelProps extends ThemeProps<any> {
    tickerId: string
}

interface BoxStyledProps extends ThemeProps<any> {
    level?: string
    className?: string
}

interface BuySellProps extends ThemeProps<any> {
    side?: string
    active?: boolean
    className?: string
}

interface HeaderItemLabelProps extends ThemeProps<any> {}

interface SortButtonProps extends ThemeProps<any> {
    active: boolean
    onClick: Function
}

interface NavTabProps extends ThemeProps<any> {
    active: boolean
    onClick: Function
}

export interface HeaderItemValueProps extends ThemeProps<any> {
    color: string
}

export const BuySellSelectionPanelStyled = styled.div.attrs((props: BuySellSelectionPanelProps) => ({
    className: 'row g-0 d-flex align-items-center justify-content-evenly pb-2 ',
}))<BuySellSelectionPanelProps>`
    // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;
    display: ${(props) => (!props.tickerId || props.tickerId === 'undefined' ? 'none !important' : 'inherit')};
    padding-top: 5px;

    @media only screen and (min-width: 500px) {
        margin-left: 90px;
        width: 500px;
    }
`
export const BuySellSelectionPanelStyledV2 = styled.div.attrs((props: BuySellSelectionPanelProps) => ({
    className: 'row g-0 d-flex align-items-center justify-content-evenly pb-2 mb-3',
}))<BuySellSelectionPanelProps>`
    // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;
    display: ${(props) => (!props.tickerId || props.tickerId === 'undefined' ? 'none !important' : 'inherit')};
`
export const CurrencyTickerStyled = styled.h3.attrs((props) => ({
    className: '',
}))`
    color: ${(props) => props.theme.colors.light};
    font-weight: bold;
    font-size: 16px;
`
export const WidgetMainContainerStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    min-height: 730px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
`
export const HeaderWrapperStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    min-height: 80px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.dark[0]};
`
export const BalanceStyled = styled.h3.attrs((props) => ({
    id: 'balance',
    className: '',
}))`
    color: ${(props) => props.theme.colors.dark[1]};

    font-size: 12px;
`
export const BoxStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className,
}))<BoxStyledProps>`
    background-color: ${(props) => props.theme.colors.dark[0]};
    background: -moz-linear-gradient(
        left,
        ${(props) => props.theme.colors.dark[0]} 0%,
        ${(props) => props.theme.colors.background[1]} 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        ${(props) => props.theme.colors.dark[0]} 0%,
        ${(props) => props.theme.colors.background[1]} 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        ${(props) => props.theme.colors.dark[0]} 0%,
        ${(props) => props.theme.colors.background[1]} 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 5px;
    padding: 2px 5px;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid
        ${(props) =>
            props.level === 'WARNING' ? props.theme.colors.warning : props.level === 'INFO' ? props.theme.colors.info : 'inherit'};
`

interface CloseOrderSelectionPanelStyledProps extends ThemeProps<any> {
    side?: string
    isMobile: boolean
}

export const CloseOrderPriceStyled = styled.span.attrs((props) => ({}))`
    font-weight: bold;
    font-size: 22px;
`

export const LookupWrapperStyled = styled.div.attrs((props) => ({}))`
    line-height: 30px;

    .form-control:not(.lookup):not(.cron) {
        width: 200px;
        padding-left: 0px;
        margin-left: 7px;
    }

    .form-floating > .form-control:not(:placeholder-shown) ~ label {
        display: none;
    }

    .form-floating > .form-control:not(:placeholder-shown) {
        padding: 0px;
    }
`

export const CloseOrderSelectionPanelStyled = styled.div.attrs((props: CloseOrderSelectionPanelStyledProps) => ({
    className: ' col-4 mb-3 border mx-auto p-2 text-light text-center  ',
}))<CloseOrderSelectionPanelStyledProps>`
    background-color: ${(props) =>
        props.side === 'BUY'
            ? props.theme?.colors?.bespoke[0] || props.theme.money.positiveColor
            : props.theme?.colors?.bespoke[1] || props.theme.money.negativeColor};
    // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;

    width: ${(props) => (props.isMobile ? '80vw' : '200px')};
    line-height: 30px;
`

export const BuySellButtonStyled = styled.div.attrs((props: BuySellProps) => ({
    className: 'text-center',
    role: 'button',
}))<BuySellProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    background-color: ${(props) => (props.active ? props.theme?.colors?.dark[1] : 'inherit')};
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    min-width: 50px;
`
export const BuySellButtonV3Styled = styled.button.attrs((props: BuySellProps) => ({
    className: '   ' + props.className ?? '',
    role: 'button',
}))<BuySellProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    background-color: ${(props) => (props.side === 'SELL' ? props.theme?.colors?.bespoke[1] : props.theme?.colors?.primary[1])};
    opacity: ${(props) => (!props.active ? 0.7 : 1)};
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    border: 2px solid ${(props) => (props.active ? props.theme?.colors?.light : 'inherit')};
    padding: 0 10px;
    @media (max-width: 1400px) {
        font-size: 9px;
        padding: 0 4px;
    }
    @media (max-width: 1200px) {
        font-size: 10px;
        padding: 0 9px;
    }

    @media (max-width: 992px) {
        font-size: 9px;
        padding: 0 8px;
    }

    @media (max-width: 768px) {
        font-size: 8px;
        padding: 0 7px;
    }

    @media (max-width: 576px) {
        font-size: 7px;
        padding: 0 6px;
    }
`
export const ButtonGroupStyled = styled.div.attrs((props: BuySellProps) => ({}))<BuySellProps>`
    background-color: ${(props) => props.theme?.colors?.background[1]};
    border-radius: 20px;
`
export const OrderTypeWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 10px 0px 0px 5px;
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

export const RadioButtonContainerStyled = styled.div.attrs((props) => ({}))`
    padding-top: 5px;

    :last-child {
        padding-top: 10px;
    }

    display: flex;
`

interface RadioButtonProperties extends ThemeProps<any> {
    active: boolean
}

export const RadioButtonIconStyled = styled.i.attrs((props: RadioButtonProperties) => ({
    className: props.active ? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle',
}))<RadioButtonProperties>`
    font-size: 16px;
    line-height: 30px;
`

export const RadioButtonLabelStyled = styled.div.attrs((props) => ({}))`
    padding-left: 10px;
    width: 80px;
    line-height: 30px;
`

export const QuantityWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 10px 0px 0px 5px;
`

export const InputWrapperStyled = styled.div.attrs((props) => ({}))`
    line-height: 30px;
`
export const SymbolWrapperStyled = styled.div.attrs((props) => ({}))`
    line-height: 52px;
`

export const SymbolLabelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    min-width: 100px;
    line-height: 52px;
    padding-left: 5px;
`

export const QuantityLabelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    min-width: 100px;
    line-height: 40px;
`

export const LimitPriceLabelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    min-width: 100px;
    line-height: 30px;
    vertical-align: middle;
    margin: auto;
`

export const InlineInputStyled = styled.div.attrs((props) => ({}))`
    display: flex;

    input[type='number'] {
        height: 30px !important;
        border-style: none;
        border-bottom: ${(props) => props.theme.form.fieldBorder} !important;
        width: 200px;
    }
`

interface BuySellPanelFormWrapperProperties {
    isMobile?: boolean
    isTradePanel?: boolean
    height?: string
    isEdit?: boolean
    isIOS: boolean
    footerHeight: number
}

export const BuySellPanelFormWrapper = styled.div.attrs((props: BuySellPanelFormWrapperProperties) => ({
    className: '',
}))<BuySellPanelFormWrapperProperties>`
    padding: ${(props) => (props.isMobile ? '0px 3px 0px 3px' : '0 20px 0 20px')};
    overflow: auto;
    max-height: ${(props) => {
        if (props.isTradePanel && props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) +
                'px)'
            )
        } else if (!props.isTradePanel && props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) +
                'px)'
            )
        } else if (props.height && props.isEdit) {
            return 'calc(' + props.height + ' - 120px);'
        } else if (props.height) {
            return 'calc(' + props.height + ' - 55px);'
        } else if (props.isMobile) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) +
                'px)'
            )
        } else if (props.isEdit) {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION + 22) +
                'px)'
            )
        } else {
            return (
                'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) +
                'px)'
            )
        }
    }};

    height: 100%;

    .form-control:not(.lookup):not(.cron) {
        border: none;
        box-shadow: none !important;
    }

    .shadow {
        box-shadow: none !important;
    }
`

export const HeaderItemStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
    background-color: #202931;
    border-radius: 5px;
`
export const HeaderItemValueStyled = styled.h3.attrs((props: HeaderItemValueProps) => ({}))<HeaderItemValueProps>`
    color: ${(props) =>
        props.color === 'light'
            ? props.theme.colors.light
            : props.color === 'muted'
            ? props.theme.colors.dark[1]
            : props.color === 'positive'
            ? props.theme.money.positiveColor
            : props.theme.money.negativeColor} !important;
    font-size: 16px;
    font-weight: bold;
`
export const ChangeFieldStyled = styled.div.attrs((props: HeaderItemValueProps) => ({
    className: 'd-flex align-items-center',
}))<HeaderItemValueProps>`
    color: ${(props) =>
        props.color === 'light'
            ? props.theme.colors.light
            : props.color === 'muted'
            ? props.theme.colors.dark[1]
            : props.color === 'positive'
            ? props.theme.money.positiveColor
            : props.theme.money.negativeColor} !important;
`
export const HeaderItemLabelStyled = styled.div.attrs((props: HeaderItemLabelProps) => ({}))<HeaderItemLabelProps>`
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 11px;
    margin: -10px 0 0 0;
`
//SortButton

export const SortButtonStyled = styled.div.attrs((props: SortButtonProps) => ({
    onClick: props.onClick,
}))<SortButtonProps>`
    color: ${(props) => (props.active ? props.theme.colors.secondary[0] : props.theme.colors.light)};
    font-size: 7px;
`
export const SortButtonsWrapperStyled = styled.div.attrs((props) => ({}))`
    margin-left: 5px;
    display: block;
    line-height: 1;
    cursor: pointer;
`

export const NavTabsWrapperStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 10px;
    padding: 10px 10px 10px 0;
`
export const NavTabsStyled = styled.ul.attrs((props) => ({}))``
export const NavTabStyled = styled.li.attrs((props: NavTabProps) => ({
    onClick: props.onClick,
}))<NavTabProps>`
    margin-right: 10px;
    background-color: ${(props) => (props.active ? props.theme.colors.dark[1] : 'inherit')};
    padding: 7px 20px;
    border-radius: 20px;
    cursor: pointer;

    :hover {
        background-color: ${(props) => props.theme.colors.dark[1]};
        opacity: 0.7;
    }
`
