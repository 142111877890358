import AutoNumeric from 'autonumeric'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks'
import { isDefined } from '../../../../../../../utils/TypeCheckers'
import { getLimitStep } from '../../../../traderUtils'
import { TradingState, updateLimit } from '../../../../tradingSlice'
import { InputStyled, QuantityLabelStyled, QuantityWrapperStyled } from './styled'

export interface RenderLimitDO {
    code: string
}

export default function RenderLimit({ code }: RenderLimitDO) {
    const dispatch = useAppDispatch()
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])

    const limitRef = useRef<any>()
    const [limitField, setLimitField] = useState<AutoNumeric | undefined>()

    const limitStep = getLimitStep()

    useEffect(() => {
        window.requestAnimationFrame(function () {
            try {
                if (limitRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(limitRef?.current)) {
                        const limitElement = new AutoNumeric(limitRef.current, _tradingSlice?.order?.getLimitPrice() || '', {
                            modifyValueOnWheel: false,
                            decimalPlaces: 8,
                            selectOnFocus: false,
                            minimumValue: '0',
                        })
                        limitElement.node().addEventListener('input', (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', ''), 10)
                            try {
                                // const number = parseFloat(event);
                                if (isDefined(value)) {
                                    dispatch(
                                        updateLimit({
                                            code: code,
                                            limitPrice: value || 0,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        updateLimit({
                                            code: code,
                                            limitPrice: 0,
                                        })
                                    )
                                }
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setLimitField(limitElement)
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [_tradingSlice?.order?.getOrderType()])

    return (
        <QuantityWrapperStyled>
            <QuantityLabelStyled>Limit</QuantityLabelStyled>
            <InputStyled
                type={'tel'}
                ref={limitRef}
                step={limitStep}
                id={'limitPrice'}
                placeholder={'Limit Price'}
                autoComplete={'off'}
                required={true}
            />
        </QuantityWrapperStyled>
    )
}
