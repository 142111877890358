import React from 'react'
import { LoginPage } from '../../../pages/login/LoginPage'
import {
    BackgroundImageStyled,
    HeaderMenuItemStyled,
    HeaderStyled,
    LoginWrapperStyled,
    LogoStyled,
    MenuContainerStyled,
    NavStyled,
    SectionStyled,
} from './styled'

export const KoshLogin = (): React.ReactElement => {
    return (
        <div id={'kosh-login'}>
            <main>
                <SectionStyled>
                    <BackgroundImageStyled src="/assets/images/cover_london.webp" alt="hero" loading="lazy" decoding="async" />
                    <LoginWrapperStyled>
                        <LoginPage noModal={true} />
                    </LoginWrapperStyled>
                </SectionStyled>
            </main>
        </div>
    )
}
