import React, { useState } from 'react'
import { useAppSelector } from '../../../../../../../app/hooks'
import { BuySellOrder } from '../../../../../buy-sell-form/types'
import { TradingState } from '../../../../tradingSlice'
import { ErrorStyled } from './styled'

export interface TradeNoteProperties {
    code: string
}

export const TradeNote = ({ code }: TradeNoteProperties): React.ReactElement<TradeNoteProperties> => {
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const order: BuySellOrder | undefined = _tradingSlice?.order

    return (
        <>
            {order?.isDelayed() && <ErrorStyled>{order?.getDelayedMessage()}</ErrorStyled>}
            {_tradingSlice?.isDMA && <ErrorStyled>{order?.getDmaMessage()}</ErrorStyled>}
            {order?.isSpd() && <ErrorStyled>{order?.getSpdMessage()}</ErrorStyled>}
        </>
    )
}
