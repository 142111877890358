import styled from 'styled-components'

export const BlotterContainerStyled = styled.div.attrs((props) => ({}))`
    min-height: 480px;
    height: 480px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
`
export const BlotterWrapperStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    min-height: 480px;
    height: 480px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
`
