import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { SessionContextProps } from '../../../providers/session/types'
import { isDefined } from '../../../utils/TypeCheckers'
import { ChildReference, CurrencyDTO, FieldConfigDTO, FieldProperties, FormMode, IMenuDTO, MoneyDTO } from '../../types'
import { ApiErrorPayload } from '../buy-sell-form/buySellSlice'
import { BuySellOrder, Order, OrderAction, PreOrderDTO } from '../buy-sell-form/types'
import { NewTickerDO, TickersResponseDO } from '../buy-sell-form/v2_components/types'
import { AcuityWidgetType } from './components/trading-widgets/types'
import { getOrderAction } from './traderUtils'

export interface TradingState {
    code: string
    tickerParam: string | undefined
    orderAction: OrderAction
    order: BuySellOrder
    ticker?: NewTickerDO | undefined
    hasError: boolean
    errorMessage: string
    hasPreOrderError: boolean
    preOrderErrorMessage: string
    lookupProps: FieldProperties | undefined
    menuItem: IMenuDTO | undefined
    loading: boolean
    loaded: boolean
    loadingPreOrder: boolean
    isCloseOut: boolean
    isNewOrder: boolean
    isTradeOrder: boolean
    isMarket: boolean
    isDMA: boolean
    createOrderUrl?: string | undefined
    loadingCreatingOrder: boolean
    blotterPath?: string | undefined
    hasActuityCode: boolean
    actuityCode: number | undefined
    widgetType: AcuityWidgetType
    widgetExpanded: boolean

    refreshCounter: number

    // old
    canShowInfo?: boolean | undefined
    canOrder?: boolean | undefined
    apiError?: boolean | undefined
    margin?: number | undefined
    freeEquity?: MoneyDTO | undefined
    currency?: string | undefined
    freeEquityCurrency?: CurrencyDTO | undefined
    marginRequired?: MoneyDTO | undefined
    notionalPrice?: MoneyDTO | undefined
    availableEquity?: MoneyDTO | undefined
}

export interface InitPayload {
    code: string
    tickerParam: string | undefined
    actionParam: string | undefined
    menuItem: IMenuDTO | undefined
    registerChildReference: (reference: ChildReference) => void
}

export interface CleanUpBlotterPayload {
    code: string
}

export interface BlotterPathPayload {
    code: string
    path: string
}

export interface WidgetSelectedPayload {
    code: string
    widget: AcuityWidgetType
}

export interface OrderTypePayload {
    code: string
    orderType: 'MARKET' | 'LIMIT'
}

export interface SidePayload {
    code: string
    side: 'BUY' | 'SELL'
}

export interface QuantityPayload {
    code: string
    quantity: number | undefined
}

export interface LimitPayload {
    code: string
    limitPrice: number | undefined
}

export interface CancelOrderPayload {
    code: string
}

export interface WidgetExpandedPayload {
    code: string
    expanded: boolean
}

interface MasterTradingState {
    [id: string]: TradingState
}

const initialState: MasterTradingState = {} as MasterTradingState

const copyState = (entry: TradingState): TradingState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as TradingState
}

const getLookupFieldProperties = (
    code: string,
    registerChildReference: (reference: ChildReference) => void,
    fieldConfig: FieldConfigDTO | undefined
): FieldProperties => {
    return {
        code: code,
        menuItem: undefined,
        formMode: FormMode.EDIT,
        isFirstField: true,
        reference: {
            name: 'realtickMapper',
            displayOrder: 1,
            register: registerChildReference,
        },
        fieldConfig: fieldConfig
            ? fieldConfig
            : {
                  name: 'symbol',
                  label: 'Symbol',
                  type: 'lookup',
              },
        data: '',
        onSubmitted: () => {},
        onChange: () => {}, //noop - picked up differently
        getCurrentRowData: () => {},
    }
}

const getFieldConfig = (menuItem: IMenuDTO | undefined, _field: string) => {
    const fields = menuItem?.action?.rows[0].columns[0].fields || []
    return fields?.filter((field) => field.name === _field)[0]
}

export const createDefault = (code: string): TradingState => {
    return {
        code: code,
        tickerParam: undefined,
        orderAction: OrderAction.NEW,
        order: new Order({} as PreOrderDTO),
        hasError: false,
        errorMessage: '',
        hasPreOrderError: false,
        preOrderErrorMessage: '',
        menuItem: undefined,
        lookupProps: undefined,
        loading: false,
        loaded: false,
        isCloseOut: false,
        isNewOrder: false,
        isTradeOrder: false,
        isMarket: true,
        isDMA: false,
        loadingCreatingOrder: false,
        hasActuityCode: false,
        actuityCode: undefined,
        widgetType: 'Chart',
        widgetExpanded: false,
        loadingPreOrder: false,
        refreshCounter: 0,
    }
}

const loadInitValues = (entry: TradingState, values: InitPayload): TradingState => {
    entry = createDefault(values.code)
    entry.code = values.code
    entry.tickerParam = values.tickerParam
    entry.orderAction = getOrderAction(values.actionParam)

    entry.lookupProps = getLookupFieldProperties('blotter', values.registerChildReference, getFieldConfig(entry.menuItem, 'realtickMapper'))

    entry.createOrderUrl = values?.menuItem?.action?.url

    // entry.order = new Order(values.preOrder || ({} as PreOrderDTO))
    // entry.ticker = values.ticker
    // entry.orderAction = values.orderAction
    //
    // if (values.preOrder) {
    //     entry.margin = values.preOrder.margin / 100
    //     entry.freeEquity = values.preOrder.freeEquity
    //     entry.currency = values.preOrder.ccy
    //     entry.freeEquityCurrency = values.preOrder.freeEquity?.currency
    //     entry.marginRequired = {
    //         currency: values.preOrder.freeEquity?.currency,
    //         value: 0,
    //     }
    //     entry.notionalPrice = {
    //         currency: values.preOrder.freeEquity?.currency,
    //         value: 0,
    //     }
    //     entry.availableEquity = {
    //         currency: values.preOrder.freeEquity?.currency,
    //         value: values.preOrder.freeEquity?.value,
    //     }
    //
    //     if (values.side) {
    //         entry.order.setSide(values.side)
    //     }
    //
    //     if (values.orderAction === OrderAction.CLOSE) {
    //         entry.order.setQuantity(values.preOrder.quantity) // close order
    //     } else {
    //         entry.order.setOrderType(values.preOrder.orderType)
    //         entry.order.setValidity(values.preOrder.validity)
    //     }
    //
    //     if (isDefined(values.preOrder.side)) {
    //         entry.order.setSide(values.preOrder.side)
    //     }
    // }
    return entry
}

const getCurrentState = (state: MasterTradingState, code: string): TradingState => {
    let entry: TradingState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

export interface TradingResponseWrapper {
    code: string
    response: TickersResponseDO
}

interface TradingRequest {
    code: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface TradingRequestParams {
    ticker: string | undefined
    orderAction: OrderAction
}

export const getTradingData = createAsyncThunk('url', async (request: TradingRequest, { getState }) => {
    const response = await request.sessionContext.post<TradingRequestParams, TickersResponseDO>(
        '/tickers/get',
        {
            // @ts-ignore
            ticker: getState()?.tradingSlice[request.code]?.tickerParam || '',
            // @ts-ignore
            orderAction: getState()?.tradingSlice[request.code]?.orderAction || OrderAction.NEW,
        },
        request.abortController
    )
    return { code: request.code, response: response?.data || {} } as TradingResponseWrapper
})

const updateData = (inboundState: TradingState, response: TickersResponseDO): TradingState => {
    const entry: TradingState = copyState(inboundState)
    entry.loading = false
    entry.loaded = true
    if (response && response?.tickers) {
        entry.ticker = response?.tickers[0] || undefined
    }
    return entry
}

interface PreOrderRequest {
    code: string
    sessionContext: SessionContextProps
    abortController: AbortController
    id: string
    orderAction: string
}

interface PreOrderRequestParams {
    id: string
    orderAction: string
}

export interface PreOrderResponseWrapper {
    code: string
    response: PreOrderDTO
}

export const getPreOrder = createAsyncThunk('get-pre-order-url', async (request: PreOrderRequest, { getState }) => {
    const response = await request.sessionContext.post<PreOrderRequestParams, PreOrderDTO>(
        '/order-entry/pre-order',
        {
            id: request.id,
            orderAction: request.orderAction,
        },
        request.abortController
    )
    return { code: request.code, response: response?.data || {} } as PreOrderResponseWrapper
})

interface CreateOrderRequest {
    code: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface CreateOrderPostRequest {
    side: 'BUY' | 'SELL' | undefined
    orderType: string
    validity: string
    realtickMapper: NewTickerDO
    quantity: number
    limitPrice: number
    price: number
    concd: string
    dma: boolean
}

export interface CreateOrderResponseWrapper {
    code: string
    response: any
    error?: any
}

const buildCreateOrderRequest = (state: any, code: string): [string, CreateOrderPostRequest] => {
    const entry: TradingState = getCurrentState(state, code)
    const order: BuySellOrder = entry?.order || ({} as BuySellOrder)
    const ticker: NewTickerDO = entry?.ticker || ({} as NewTickerDO)
    const url: string = entry.createOrderUrl || ''
    const postRequest: CreateOrderPostRequest = {
        side: order.getSide(),
        orderType: order.getOrderType(),
        validity: order.getValidity(),
        realtickMapper: ticker,
        quantity: order.getQuantity(),
        limitPrice: order.getLimitPrice(),
        price: order.getPrice(),
        concd: order.getConcd(),
        dma: order.isDma(),
    }
    return [url, postRequest]
}

export const createOrder = createAsyncThunk('create-order-url', async (request: CreateOrderRequest, { getState, rejectWithValue }) => {
    const [url, postRequest] = buildCreateOrderRequest(getState(), request.code)
    try {
        const response = await request.sessionContext.post<CreateOrderPostRequest, PreOrderDTO>(url, postRequest, request.abortController)
        return { code: request.code, response: response?.data || {} } as CreateOrderResponseWrapper
    } catch (error: any) {
        console.log('ERR999 error', error)
        return rejectWithValue(error)
    }
})

const differentMoney = (a: MoneyDTO | undefined, b: MoneyDTO | undefined): boolean => {
    if (!a && !b) {
        return false
    } else if (a && !b) {
        return true
    } else if (!a && b) {
        return true
    } else {
        // @ts-ignore
        if (a.currency !== b.currency) {
            return true
        } else {
            // @ts-ignore
            return a.value !== b.value
        }
    }
}

const performValidation = (entry: TradingState, isProxy: boolean) => {
    if (entry.order) {
        const order: BuySellOrder = entry.order

        if (!entry.ticker) {
            entry.errorMessage = 'Please select a ticker.'
            entry.hasError = true
            return
        }

        if (entry.order?.getSide() && !entry.ticker) {
            entry.errorMessage = 'Please select the ticker first.'
            entry.hasError = true
            return
        }

        if (!entry.order?.getSide()) {
            entry.errorMessage = 'Please select SELL or BUY.'
            entry.hasError = true
            return
        }

        if (order.isCloseout() && order.isShort()) {
            if (order.getQuantity() > order.getMaxQuantity()) {
                entry.errorMessage = 'Reduce quantity, max reached.'
                entry.hasError = true
                entry.canOrder = false
                return
            }
        }
        // if (isNaN(_limitPrice) || _limitPrice === 0) {
        if (order.getOrderType() === 'LIMIT' && !(order.getLimitPrice() > 0)) {
            entry.errorMessage = 'Limit must be greater than zero'
            entry.hasError = true
            entry.canOrder = false
            // return
        }

        if (order.getQuantity() < 1) {
            entry.errorMessage = 'Quantity must be greater than  zero'
            entry.hasError = true
            // return
        }

        if (order.isCloseout()) {
            if (order.getQuantity() > order.getOriginalQuantity()) {
                entry.hasError = true
                entry.errorMessage = 'Quantity cannot be greater than position quantity.'
                entry.canOrder = false
                return
            }
        }

        // free Equity Check
        if (entry.freeEquityCurrency) {
            const currency: CurrencyDTO = !isProxy ? entry.freeEquityCurrency : current(entry.freeEquityCurrency)

            console.log('currency', currency, order.getOrderType(), order.getNotional(), order.getQuantity(), order.getPrice())

            // update notionalPrice if changed
            const newNotionalPrice: MoneyDTO = {
                value: order.getNotional(),
                currency: currency,
            }
            const currentNotionalPrice: MoneyDTO | undefined = !isProxy ? entry.notionalPrice : current(entry.notionalPrice)
            if (differentMoney(newNotionalPrice, currentNotionalPrice)) {
                entry.notionalPrice = newNotionalPrice
            }

            // if (order?.isCloseout()) {
            //     if (!entry.canShowInfo) {
            //         entry.canShowInfo = true
            //     }
            //     return
            // }

            // update margin if changed
            const newMarginRequired: MoneyDTO = {
                value: order.getNotional() * (entry.margin || 0),
                currency: currency,
            }
            const currentMarginRequired: MoneyDTO | undefined = !isProxy ? entry.marginRequired : current(entry.marginRequired)
            if (differentMoney(newMarginRequired, currentMarginRequired)) {
                entry.marginRequired = newMarginRequired
            }

            // update AvailableEquity if changed
            // POR-523
            // was value: entry.freeEquity!.value - (entry.marginRequired!.value + order.getNotional()),
            // s/be value: entry.freeEquity!.value - entry.marginRequired!.value

            const newAvailableEquity: MoneyDTO = {
                value: entry.freeEquity!.value - entry.marginRequired!.value,
                currency: currency,
            }
            const currentAvailableEquity: MoneyDTO | undefined = !isProxy ? entry.availableEquity : current(entry.availableEquity)
            if (differentMoney(newAvailableEquity, currentAvailableEquity)) {
                entry.availableEquity = newAvailableEquity
            }

            if (order?.getSide() === 'BUY') {
                if (!order.isCloseout() && entry.availableEquity!.value < 0) {
                    entry.hasError = true
                    entry.errorMessage = 'Not enough free equity to proceed.'
                    entry.canOrder = false
                    return
                }
            }

            if (order?.getSide() === 'SELL') {
                if (!order.isCloseout() && entry.availableEquity!.value < 0) {
                    entry.hasError = true
                    entry.errorMessage = 'Not enough free equity to proceed.'
                    entry.canOrder = false
                    return
                }
            }
        }
        if (!entry.canShowInfo) {
            entry.canShowInfo = true
        }

        // only change if different
        if (order.getOrderType() === 'LIMIT' && order.getLimitPrice() > 0 && entry.order.getQuantity() > 0) {
            if (!entry.canOrder) {
                entry.canOrder = true
            }
        } else if (order.getOrderType() === 'MARKET' && entry.order.getQuantity() > 0) {
            if (!entry.canOrder) {
                entry.canOrder = true
            }
        } else {
            if (entry.canOrder) {
                entry.canOrder = false
            }
        }
    }
}
const handlePreOrderResponseError = (inboundState: TradingState, response: PreOrderDTO): TradingState => {
    console.log('---------- handle pre-order error ----------')
    const entry: TradingState = copyState(inboundState)
    entry.loadingPreOrder = false
    return entry
}

const updateCreateResponse = (inboundState: TradingState, response: any): TradingState => {
    const entry: TradingState = copyState(inboundState)
    entry.loadingCreatingOrder = false
    return entry
}

const updatePreOrderResponse = (inboundState: TradingState, response: PreOrderDTO): TradingState => {
    const entry: TradingState = copyState(inboundState)
    entry.loadingPreOrder = false

    entry.order = new Order(response || ({} as PreOrderDTO))

    if (response?.hasError || response?.errorMessage) {
        entry.hasPreOrderError = true
        entry.preOrderErrorMessage = response?.errorMessage || 'Error'
        entry.apiError = true
    } else {
        entry.actuityCode = response.acuityCode
        entry.hasActuityCode = isDefined(response.acuityCode)
        entry.margin = response?.margin / 100
        entry.freeEquity = response?.freeEquity
        entry.currency = response?.ccy
        entry.freeEquityCurrency = response?.freeEquity?.currency
        entry.marginRequired = {
            currency: response?.freeEquity?.currency,
            value: 0,
        }
        entry.notionalPrice = {
            currency: response?.freeEquity?.currency,
            value: 0,
        }
        entry.availableEquity = {
            currency: response?.freeEquity?.currency,
            value: response?.freeEquity?.value,
        }

        if (response.orderAction === OrderAction.CLOSE) {
            entry.order.setQuantity(response?.quantity) // close order
        } else {
            entry.order.setOrderType(response?.orderType)
            entry.order.setValidity(response?.validity)
        }

        if (response?.askPrice) {
            entry.order.setBuyPrice(response.askPrice)
        }
        if (response?.bidPrice) {
            entry.order.setSellPrice(response.bidPrice)
        }
        // If delayed then turn if off
        if (entry.order.isDelayed()) {
            entry.order.setNoLongerDelayed()
        }

        entry.isCloseOut = response.orderAction === OrderAction.CLOSE
        entry.isNewOrder = response.orderAction === OrderAction.NEW
        entry.isTradeOrder = response.orderAction === OrderAction.TRADE
        entry.isMarket = response.orderAction === OrderAction.CLOSE ? false : (response?.orderType || 'MARKET') === 'MARKET'

        entry.isDMA = entry?.order?.isDma() || false
        // entry.order.setSide(response?.side || 'BUY')
        entry.order.setSide(response?.side)

        performValidation(entry, false)
    }

    return entry
}

const performShowError = (entry: TradingState, payload: ApiErrorPayload): TradingState => {
    entry.hasError = payload.hasError
    entry.errorMessage = payload.errorMessage || ''
    entry.apiError = payload.apiError || false
    return entry
}

const performCancelOrder = (entry: TradingState): TradingState => {
    return entry
}

interface OrderChangeAction {
    orderType?: string | undefined
    side?: 'BUY' | 'SELL' | undefined
    quantity?: number | undefined
    limitPrice?: number | undefined
    validity?: string | undefined
}

const updateOrderValues = (entry: TradingState, orderChange: OrderChangeAction) => {
    entry.hasError = false
    entry.errorMessage = ''

    if (entry.order) {
        // whe have a change?
        let haveChange: boolean = false
        const order = entry.order.clone()

        if (orderChange.orderType && entry.order.getOrderType() !== orderChange.orderType) {
            haveChange = true
            order.setOrderType(orderChange.orderType)
        }

        if (orderChange.side && entry.order.getSide() !== orderChange.side) {
            haveChange = true
            order.setSide(orderChange.side)
        }

        if (isDefined(orderChange.quantity) && entry.order.getQuantity() !== orderChange.quantity) {
            haveChange = true
            order.setQuantity(orderChange.quantity || 0)
        }

        if (isDefined(orderChange.limitPrice) && entry.order.getLimitPrice() !== orderChange.limitPrice) {
            haveChange = true
            order.setLimitPrice(orderChange.limitPrice || 0)
        }

        if (orderChange.validity && entry.order.getValidity() !== orderChange.validity) {
            haveChange = true
            order.setValidity(orderChange.validity)
        }

        if (haveChange) {
            entry.order = order
        }
        performValidation(entry, true)
    }
    return entry
}

export const tradingSlice = createSlice({
    name: 'tradingSlice',
    initialState,
    reducers: {
        initTradingPage(state: any, action: PayloadAction<InitPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            state[action.payload.code] = loadInitValues(entry, action.payload)
            return state
        },
        cleanUpTradingPage(state, action: PayloadAction<CleanUpBlotterPayload>) {
            delete state[action.payload.code]
        },
        updateTradingBlotterPath(state: any, action: PayloadAction<BlotterPathPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            entry.blotterPath = action.payload.path
        },
        updateSelectedWidgetType(state: any, action: PayloadAction<WidgetSelectedPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            entry.widgetType = action.payload.widget
        },
        widgetExpanded(state: any, action: PayloadAction<WidgetExpandedPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            entry.widgetExpanded = action.payload.expanded
        },
        showApiError(state: any, action: PayloadAction<ApiErrorPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            performShowError(entry, action.payload)
        },
        updateOrderType(state: any, action: PayloadAction<OrderTypePayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            entry.isMarket = (action.payload.orderType || 'MARKET') === 'MARKET'
            updateOrderValues(entry, { orderType: action.payload.orderType })
        },
        updateSide(state: any, action: PayloadAction<SidePayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            updateOrderValues(entry, { side: action.payload.side })
            // entry.refreshCounter = entry.refreshCounter + 1
        },
        updateQuantity(state: any, action: PayloadAction<QuantityPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            updateOrderValues(entry, { quantity: action.payload.quantity })
        },
        updateLimit(state: any, action: PayloadAction<LimitPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            updateOrderValues(entry, { limitPrice: action.payload.limitPrice })
        },
        cancelOrder(state: any, action: PayloadAction<CancelOrderPayload>) {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            performCancelOrder(entry)
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getTradingData.fulfilled, (state: any, action: PayloadAction<TradingResponseWrapper>) => {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
        builder.addCase(getTradingData.pending, (state: any, action: PayloadAction<any>) => {
            // @ts-ignore
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: TradingState = getCurrentState(state, code)
                entry.loading = true
            }
        })
        builder.addCase(getPreOrder.fulfilled, (state: any, action: PayloadAction<PreOrderResponseWrapper>) => {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            state[action.payload.code] = updatePreOrderResponse(entry, action.payload.response)
        })
        builder.addCase(getPreOrder.pending, (state: any, action: PayloadAction<any>) => {
            // @ts-ignore
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: TradingState = getCurrentState(state, code)
                entry.loadingPreOrder = true
            }
        })
        builder.addCase(getPreOrder.rejected, (state: any, action: PayloadAction<any>) => {
            // @ts-ignore
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: TradingState = getCurrentState(state, code)
                console.log('api error', state, action)
                // handlePreOrderResponseError
            }
        })

        builder.addCase(createOrder.fulfilled, (state: any, action: PayloadAction<CreateOrderResponseWrapper>) => {
            const entry: TradingState = getCurrentState(state, action.payload.code)
            state[action.payload.code] = updateCreateResponse(entry, action.payload.response)
        })
        builder.addCase(createOrder.pending, (state: any, action: PayloadAction<any>) => {
            // @ts-ignore
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: TradingState = getCurrentState(state, code)
                entry.loadingCreatingOrder = true
            }
        })
        builder.addCase(createOrder.rejected, (state: any, action: PayloadAction<any>) => {
            // @ts-ignore
            const code = action?.meta?.arg?.code
            if (code) {
                const entry: TradingState = getCurrentState(state, code)
                console.log('createOrder', state, action)
                entry.loadingCreatingOrder = false
                // handlePreOrderResponseError
            }
        })
    },
})

export const {
    initTradingPage,
    cleanUpTradingPage,
    updateTradingBlotterPath,
    updateSelectedWidgetType,
    widgetExpanded,
    showApiError,
    updateOrderType,
    updateSide,
    updateQuantity,
    updateLimit,
    cancelOrder,
} = tradingSlice.actions

export default tradingSlice.reducer
