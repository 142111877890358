import styled from 'styled-components'

export const HeaderItemWrapperStyled = styled.div.attrs((props) => ({
    className: 'col-xxl-1  col-xl-2  col-md-2 ',
}))`
    margin-right: 10px;
    width: auto;
    text-align: center;
`
export const HeaderTickerLookupWrapperStyled = styled.div.attrs((props) => ({
    className: 'col-xxl-2 col-xl-2 d-flex align-items-center',
    id: 'ticker-lookup',
}))`
    width: 300px;
    margin-right: 10px;
    background-color: #202931;
    border-radius: 5px;

    #lookup_ff_BuySellTickerLookup {
        #lookup_modal_ffcs_BuySellTickerLookup {
            border: none !important;
        }

        #lookup_cc_BuySellTickerLookup {
            width: 280px !important;
        }
    }
`
