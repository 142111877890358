import React from 'react'
import { removeSpaces } from '../../../../../utils/Utils'
import { NewTickerDO } from '../../../buy-sell-form/v2_components/types'
import ChangePerc from './ChangePerc'
import MoneyWithCurrency from './MoneyWithCurrency'
import { HeadlineItemLabelStyled, HeadlineItemStyled, HeadlineItemValueProps, HeadlineItemValueStyled } from './styled'

export type TICKER_VALUE_TYPE = 'lastPrice' | 'dailyHigh' | 'dailyLow' | 'change' | 'volume'

export interface HeadlineItemProperties {
    ticker: NewTickerDO | undefined
    label: string
    value: number | undefined
    currency: string
    valueType: TICKER_VALUE_TYPE
}

export const HeadlineItem = ({
    ticker,
    label,
    value,
    valueType,
    currency,
}: HeadlineItemProperties): React.ReactElement<HeadlineItemProperties> => {
    const colorMap: {
        [K in HeadlineItemProperties['valueType']]: HeadlineItemValueProps['color']
    } = {
        lastPrice: 'light',
        dailyHigh: 'muted',
        dailyLow: 'muted',
        change: valueType === 'change' && (value || 0) > 0 ? 'positive' : valueType === 'change' && (value || 0) < 0 ? 'negative' : 'light',
        volume: 'muted',
    }

    const isMoney: boolean = ['lastPrice', 'dailyHigh', 'dailyLow'].includes(valueType)
    const isChange: boolean = valueType === 'change'
    const updatedTicker: NewTickerDO = { ...(ticker || ({} as NewTickerDO)) }

    const renderMoney = (): React.ReactElement => {
        return <MoneyWithCurrency value={updatedTicker[valueType]} currency={currency} />
    }

    const renderChange = (): React.ReactElement => {
        return <ChangePerc value={value} multiplyBy100 />
    }
    const renderValue = (): React.ReactElement => {
        return <>{value ?? '---'}</>
    }

    return (
        <HeadlineItemStyled id={'headline_fld_' + removeSpaces(label)}>
            <HeadlineItemValueStyled color={colorMap[valueType]}>
                {isMoney && renderMoney()}
                {isChange && renderChange()}
                {!isMoney && !isChange && renderValue()}
            </HeadlineItemValueStyled>
            <HeadlineItemLabelStyled>{label}</HeadlineItemLabelStyled>
        </HeadlineItemStyled>
    )
}
