import styled, { ThemeProps } from 'styled-components'

interface ButtonProps extends ThemeProps<any> {
    side?: string
    active?: boolean
}

export const ButtonCollectionStyled = styled.div.attrs((props) => ({
    className: 'pe-2',
    id: 'market-limit-button-collection',
}))``

export const ButtonGroupStyled = styled.div.attrs((props) => ({
    className: 'row g-0 row-cols-2',
    id: 'market-limit-button-group',
}))`
    background-color: ${(props) => props.theme?.colors?.background[1]};
    border-radius: 20px;
`

export const ButtonStyled = styled.div.attrs((props: ButtonProps) => ({
    className: 'text-center',
    role: 'button',
}))<ButtonProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    background-color: ${(props) => (props.active ? props.theme?.colors?.dark[1] : 'inherit')};
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    min-width: 50px;
`
