import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { BuySellButtonStyled, WidgetMainContainerStyled } from '../../../buy-sell-form/styled_v2'
import BSChart from '../../../buy-sell-form/v2_components/acuity-widgets/BSChart'
import BSEvents from '../../../buy-sell-form/v2_components/acuity-widgets/BSEvents'
import BSInfo from '../../../buy-sell-form/v2_components/acuity-widgets/BSInfo'
import { ElementWithTooltip } from '../../../element-with/element-with-tooltip/ElementWithTooltip'
import { TradingState, updateSelectedWidgetType, widgetExpanded } from '../../tradingSlice'
import {
    WidgetButtonGroupStyled,
    WidgetButtonStyled,
    WidgetsLeftButtonCollectionStyled,
    WidgetsNoChartsAvailableStyled,
    WidgetsRightButtonCollectionStyled,
    WidgetsRowStyled,
} from './styled'
import { AcuityWidgetType, WidgetMap } from './types'

interface TradingWidgetsProperties {
    code: string
}

export const TradingWidgets = (properties: TradingWidgetsProperties): React.ReactElement<TradingWidgetsProperties> => {
    const dispatch = useAppDispatch()

    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[properties.code])

    const defaultMap: WidgetMap = { Chart: undefined, Info: undefined, Events: undefined }
    const [widgetMap] = useState<WidgetMap>(defaultMap)

    if (!_tradingSlice?.ticker) {
        return <></>
    }

    const renderWidgetButton = (widgetType: AcuityWidgetType): React.ReactElement => {
        const onWidgetTypeSelected = (e: React.MouseEvent<HTMLElement, MouseEvent>, widgetType: AcuityWidgetType): void => {
            e?.stopPropagation()
            dispatch(updateSelectedWidgetType({ code: properties.code, widget: widgetType }))
        }

        return (
            <WidgetButtonStyled
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onWidgetTypeSelected(e, widgetType)}
                active={_tradingSlice?.widgetType === widgetType}
            >
                {widgetType}
            </WidgetButtonStyled>
        )
    }
    const renderExpandCollapseButton = (expandButton: boolean, icon: string, labels: string[]): React.ReactElement => {
        const onWidgetExpanded = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            dispatch(widgetExpanded({ code: properties.code, expanded: expandButton }))
        }
        return (
            <BuySellButtonStyled
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onWidgetExpanded(e)}
                active={expandButton ? _tradingSlice?.widgetExpanded : !_tradingSlice?.widgetExpanded}
            >
                <ElementWithTooltip
                    element={<i className={'fa-solid  ' + icon} />}
                    tooltip={<span>{_tradingSlice?.widgetExpanded ? labels[0] : labels[1]}</span>}
                />
            </BuySellButtonStyled>
        )
    }
    const renderExpandButton = (): React.ReactElement => {
        return <>{renderExpandCollapseButton(true, 'fa-arrows-left-right-to-line', ['Expanded', 'Expand'])}</>
    }
    const renderCollapseButton = (): React.ReactElement => {
        return <>{renderExpandCollapseButton(false, 'fa-minimize', ['Collapse', 'Collapsed'])}</>
    }
    const renderTopButtons = (): React.ReactElement => {
        if (!_tradingSlice?.hasActuityCode) {
            return <></>
        }
        return (
            <>
                <WidgetsLeftButtonCollectionStyled>
                    <WidgetButtonGroupStyled>
                        {renderWidgetButton('Chart')}
                        {renderWidgetButton('Info')}
                        {renderWidgetButton('Events')}
                    </WidgetButtonGroupStyled>
                </WidgetsLeftButtonCollectionStyled>
                <WidgetsRightButtonCollectionStyled>
                    <WidgetButtonGroupStyled cols={2}>
                        {renderExpandButton()}
                        {renderCollapseButton()}
                    </WidgetButtonGroupStyled>
                </WidgetsRightButtonCollectionStyled>
            </>
        )
    }

    const renderNoCharts = (message: string): React.ReactElement => {
        return (
            <WidgetsNoChartsAvailableStyled>
                <i className="fa-solid fa-chart-line fa-5x"></i>
                <p className="lead mt-3">{message}</p>
                {/*<br/>*/}
                {/*<i className="fa-solid fa-circle-info fa-5x"></i>*/}
                {/*<p className="lead mt-3">coming soon...</p>*/}
                {/*<br/>*/}
                {/*<i className="fa-solid fa-calendar-days fa-5x"></i>*/}
                {/*<p className="lead mt-3">coming soon...</p>*/}
            </WidgetsNoChartsAvailableStyled>
        )
    }
    const renderWidgetType = (widgetType: AcuityWidgetType): React.ReactElement => {
        if (!_tradingSlice?.ticker) {
            return <></>
        }

        switch (widgetType) {
            case 'Info':
                return (
                    <BSInfo
                        acuityCode={_tradingSlice?.actuityCode}
                        key={'chart-' + _tradingSlice?.ticker?.displayTicker}
                        ticker={_tradingSlice?.ticker}
                    />
                )
            case 'Events':
                return (
                    <BSEvents
                        acuityCode={_tradingSlice?.actuityCode}
                        key={'chart-' + _tradingSlice?.ticker?.displayTicker}
                        ticker={_tradingSlice?.ticker}
                    />
                )
            case 'Chart':
            default:
                return (
                    <BSChart
                        acuityCode={_tradingSlice?.actuityCode}
                        key={'chart-' + _tradingSlice?.ticker?.displayTicker}
                        ticker={_tradingSlice?.ticker}
                    />
                )
        }
    }

    const renderChartArea = (): React.ReactElement => {
        if (!_tradingSlice?.hasActuityCode) {
            return <>{renderNoCharts('Sorry, currently not available')}</>
        }
        return <>{renderWidgetType(_tradingSlice?.widgetType || 'Chart')}</>
    }

    return (
        <WidgetMainContainerStyled>
            <WidgetsRowStyled>{renderTopButtons()}</WidgetsRowStyled>
            <WidgetsRowStyled>{renderChartArea()}</WidgetsRowStyled>
        </WidgetMainContainerStyled>
    )
}
