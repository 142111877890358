import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import { ErrorMessageStyled } from '../../styled'
import { ChildReference, FieldConfigDTO, FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import { InnerFieldStyled, InnerPanelStyled } from './styled'

export const SubPanelField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode)
    const [refreshCounter, setRefreshCounter] = useState<number>(0)

    const [childReferences] = useState<Map<string, ChildReference>>(new Map())

    const [fields] = useState<FieldConfigDTO[]>(properties.fieldConfig.innerFields || [])
    const [tableFields] = useState<FieldConfigDTO[]>((properties.fieldConfig.innerFields || []).filter((fld) => fld.displayInTable))
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    const [tableValue] = useState<string>(() => {
        let value: string = ''
        ;(properties.fieldConfig.innerFields || [])
            .filter((fld: FieldConfigDTO) => fld.displayInTable)
            .forEach((fld) => {
                try {
                    const fldValue = properties.data[fld.name]
                    if (fldValue) {
                        value = value + fldValue
                    }
                } catch (err) {
                    // ignore
                }
            })
        return value
    })

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        childReferences.forEach((reference) => {
            if (reference && reference.changeMode) {
                reference.changeMode(mode)
            }
        })
    }

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        setErrorMessage(undefined)

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, fieldValue)
        if (!hideField) {
            let valid: boolean = true
            childReferences.forEach((reference) => {
                if (reference && reference.doValidation && fieldValue) {
                    if (!reference.doValidation(fieldValue[reference.name])) {
                        valid = false
                    }
                }
            })
            return valid
        }
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return <div style={style}>{tableValue}</div>
    }

    const renderInnerFormField = (fieldConfig: FieldConfigDTO, idx: number): React.ReactElement => {
        const onSubmitted = (): void => {
            // do nothing
        }
        const onChangeHandler = (name: string, value: any): void => {
            properties.onChange(name, value, false, properties?.panelName)
        }
        const getCurrentRowData = (): any => {
            return properties.rowData
        }

        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference)
        }

        const reLoad = () => {
            // onRefresh()
        }

        const panelData = _guslFormState?.getFieldValue(properties)
        const fieldData = (panelData || {})[fieldConfig.name]

        return (
            <InnerFieldStyled key={fieldConfig.name + '_' + refreshCounter + '_' + idx}>
                {fieldService.getFormTemplate({
                    code: _guslFormState.code || properties.code || fieldConfig.name, // properties.fieldConfig.name,
                    formMode: formMode,
                    fieldConfig,
                    // menuItem: properties.menuItem,
                    data: fieldData,
                    onSubmitted,
                    onChange: (name: string, value: any) => onChangeHandler(name, value),
                    getCurrentRowData,
                    isDialog: false,
                    menuItem: undefined,
                    reference: {
                        name: fieldConfig.name,
                        displayOrder: idx,
                        register: registerChildReference,
                    },
                    reLoad: reLoad,
                })}
            </InnerFieldStyled>
        )
    }

    const renderInnerFields = (): React.ReactElement => {
        return (
            <InnerPanelStyled id={'sub_panel_innerPanel_' + properties.code} key={'sub_panel_innerPanel_' + properties.code}>
                {fields.map((fld, idx) => renderInnerFormField(fld, idx))}
            </InnerPanelStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, _guslFormState?.getFieldValue(properties))
        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled id={'sub_panel_' + properties.code} key={'sub_panel_' + properties.code}>
                                    {renderInnerFields()}
                                </FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
