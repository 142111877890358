import styled from 'styled-components'

export const OttoTableContainerStyled = styled.div.attrs((props) => ({
    className: 'col-12',
    id: 'otto-table',
}))`
    .table {
        --bs-table-striped-bg: inherit !important;
        --bs-table-striped-color: inherit !important;

        i {
            color: ${(props) => 'rgba(41,160,132,1)'};
        }
    }
`

export const OttoTableLeftContainerStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    background-color: #f3f4f6;
    margin-top: 5px;
    margin-bottom: 12px;
`

//     id: 'otto-table-left'

interface OttoTableRightContainerProperties {
    height?: string
}

export const OttoTableRightContainerStyled = styled.div.attrs((propsLOttoTableRightContainerProperties) => ({
    className: 'p-2',
    id: 'otto-table-right',
}))<OttoTableRightContainerProperties>`
    position: relative;
`
/*
    ${(props) => props.height && 'height: ' + props.height + ';'}

 */
export const CollectionHeaderStyled = styled.div.attrs((props) => ({
    className: 'fs-4 p-2',
}))`
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`
export const CardInfoStyled = styled.div.attrs((props) => ({}))`
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
`
export const FieldLabelStyled = styled.span.attrs((props) => ({}))`
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 10px;
    color: rgba(161, 161, 170, 1);
`

export const OttoTableGrid = styled.div.attrs((props) => ({
    className: 'g-0',
}))`
    width: 100%;
`

interface OttoTableProperties {
    flex?: boolean
}

export const OttoTableCard = styled.div.attrs((props: OttoTableProperties) => ({
    className: 'p-2',
}))<OttoTableProperties>`
    width: 100%;
    h@media (min-widt: 992px) {
        ${(props) => props.flex && 'min-height: 84px;'}
    }
`
