import React from 'react'
import { useAppSelector } from '../../../../../app/hooks'
import { TradingState } from '../../tradingSlice'
import { BoxStyled } from '../watchlist/styled'
import { BuySellButtons } from './components/buy-sell-buttons/BuySellButtons'
import { MarketLimitButtons } from './components/market-limit/MarketLimitButtons'
import RenderDisplayInfo from './components/other/RenderDisplayInfo'
import RenderError from './components/other/RenderError'
import RenderLimit from './components/other/RenderLimit'
import RenderQuantity from './components/other/RenderQuantity'
import { TradeNote } from './components/other/TradeNote'
import { Col6Styled, RowStyled, SymbolStyled } from './styled'

export interface OrderFormProperties {
    code: string
    // setSideHandler: Function
    // onOrderType: Function
    // placeOrderError: boolean
    // isDMA: Function
    // limitRef: React.MutableRefObject<any>
    // quantityRef: React.MutableRefObject<any>
    // limitStep: number
    // reset: Function
    // setPlaceOrderError: Function
}

export const OrderForm = ({
    code,
}: // setSideHandler,
// onOrderType,
// isDMA,
// limitRef,
// quantityRef,
// limitStep,
// reset,
// setPlaceOrderError,
OrderFormProperties): React.ReactElement<OrderFormProperties> => {
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])

    return (
        <>
            <RowStyled>
                <Col6Styled id={'trading-order-form'}>Order form</Col6Styled>
                <Col6Styled>
                    <MarketLimitButtons code={code} />
                </Col6Styled>
            </RowStyled>
            <BoxStyled>
                <SymbolStyled>{_tradingSlice?.ticker?.displayTicker || _tradingSlice?.ticker?.symbol}</SymbolStyled>
                <BuySellButtons code={code} key={'trading_buttons_' + _tradingSlice?.refreshCounter} />
                <TradeNote code={code} />
            </BoxStyled>
            <RowStyled>
                {_tradingSlice?.hasError && <RenderError code={code} />}
                <BoxStyled>
                    <RenderQuantity code={code} />
                </BoxStyled>

                {!_tradingSlice?.isMarket && (
                    <BoxStyled>
                        <RenderLimit code={code} />
                    </BoxStyled>
                )}

                {_tradingSlice.canShowInfo && (
                    <BoxStyled>
                        <RenderDisplayInfo code={code} />
                    </BoxStyled>
                )}
            </RowStyled>
        </>
    )
}
