import styled, { ThemeProps } from 'styled-components'
import { HeaderItemValueProps } from '../../../buy-sell-form/styled_v2'

export const TickerHeadlineWrapperStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    min-height: 80px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
`

export const TickerHeadlineRowStyled = styled.div.attrs((props) => ({
    className: 'row g-0',
    id: 'headline_row',
}))``

export const HeadlineItemStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
    background-color: #202931;
    border-radius: 5px;
    .purple {
        background-color: #d0c5f8;
    }
`

export interface HeadlineItemValueProps extends ThemeProps<any> {
    color: string
}

export const HeadlineItemValueStyled = styled.h3.attrs((props: HeadlineItemValueProps) => ({
    className: 'clr-' + props.color,
}))<HeadlineItemValueProps>`
    color: ${(props) =>
        props.color === 'light'
            ? props.theme.colors.light
            : props.color === 'muted'
            ? props.theme.colors.dark[1]
            : props.color === 'positive'
            ? props.theme.money.positiveColor
            : props.theme.money.negativeColor} !important;
    font-size: 16px;
    font-weight: bold;
`

export const HeadlineItemLabelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 11px;
    margin: -10px 0 0 0;
`

export const ChangeFieldStyled = styled.div.attrs((props: HeaderItemValueProps) => ({
    className: 'd-flex align-items-center',
}))<HeaderItemValueProps>`
    color: ${(props) =>
        props.color === 'light'
            ? props.theme.colors.light
            : props.color === 'muted'
            ? props.theme.colors.dark[1]
            : props.color === 'positive'
            ? props.theme.money.positiveColor
            : props.theme.money.negativeColor} !important;
`
export const HeadlineItemWrapperStyled = styled.div.attrs((props) => ({
    className: 'col-xxl-1  col-xl-2  col-md-2 ',
}))`
    margin-right: 10px;
    width: auto;
    text-align: center;
`
export const HeadlineTickerLookupWrapperStyled = styled.div.attrs((props) => ({
    className: 'col-xxl-2 col-xl-2 d-flex align-items-center',
    id: 'ticker-lookup',
}))`
    width: 300px;
    margin-right: 10px;
    background-color: #202931;
    border-radius: 5px;

    #lookup_ff_BuySellTickerLookup {
        #lookup_modal_ffcs_BuySellTickerLookup {
            border: none !important;
        }

        #lookup_cc_BuySellTickerLookup {
            width: 280px !important;
        }
    }
`
