import React from 'react'
import styled, { ThemeProps } from 'styled-components'
import { ACTION_ITEM_WIDTH, HEADER_HEIGHT, TAB_HEIGHT } from '../../../providers/theme/GuslThemeProvider'
import { getTextAlign } from '../../../utils/CssUtils'
import { defaultCellWidth } from '../gusl-table/guslTableSlice'

export interface ColumnFieldProperties extends ThemeProps<any> {
    textAlign?: string | undefined
    cellWidth?: number | string | undefined
    hasLink?: boolean | undefined
    storagePrefix?: string | undefined
}

export interface ColumnHeaderProperties extends ThemeProps<any> {
    textAlign?: string | undefined
    className?: string | undefined
    cellWidth?: number | string | undefined
    beingResized?: boolean | undefined

    disableColumnResize?: boolean | undefined
    sortable?: boolean | undefined
    resizing?: boolean | undefined
}

// MK 19/08/2023
export interface ColumnHeaderIndividualProps extends ColumnHeaderProperties, ThemeProps<any> {
    top?: number | undefined
    right?: number | undefined
}

export interface ResizerControlProps extends ThemeProps<any> {
    className?: string | undefined
}

export interface TdProperties extends ThemeProps<any> {
    cellWidth?: number | string | undefined
    bold?: boolean
    textAlign?: string | undefined
    beingResized?: boolean | undefined
    hasLink?: boolean | undefined
    // MK 25-02-2024
    isEditable?: boolean | undefined
}

export interface ActionProperties extends TdProperties {
    numberOfActions: number
    className?: string
}

export const pxOrPerc = (cellWidth: any) => {
    if (!cellWidth) {
        return undefined
    }
    if (('' + cellWidth).indexOf('%') > 0) {
        return cellWidth
    }
    if (('' + cellWidth).indexOf('px') > 0) {
        return cellWidth
    }
    return cellWidth + 'px'
}
export const ColumnHeaderStyled = styled.th.attrs((props: ColumnHeaderProperties) => ({
    className: props.className,
}))<ColumnHeaderProperties>`
  text-align: ${(props) => getTextAlign(props.textAlign)};
  ${(props) => props.beingResized && 'background-color: ' + props.theme.colors.dark[0] + ' !important;'};
  color: ${(props) => props.theme.table.headerColor};
  font-size: ${(props) => props.theme.table.headerFontSize};
    // gbw 04-Mar
  padding: 10px 0 5px 5px !important;
  height: 40px;
  font-weight: bold;

    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}

    background-color: ${(props) => props.theme.table.headerBgColor}!important;

    position: sticky;
    top: -1px;
    z-index: 1;

/* GBW 17 Jun 2024 event modal lookup*/
        width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
        min-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
        max-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    
}
`
/*
  // width: ${props => props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial'};
  // min-width: ${props => props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial'};
  // max-width: ${props => props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial'};

 */

export const ColumnHeaderPartWrapperStyled = styled.div.attrs({})`
    display: flex;
    position: relative;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
`

export const ColumnHeaderLeftPartStyled = styled.div.attrs((props: ColumnHeaderProperties) => ({
    className: 'd-flex align-items-center',
}))<ColumnHeaderProperties>`
    text-align: ${(props) => getTextAlign(props.textAlign)};

    width: 100%;
    margin-right: ${(props) => (props.disableColumnResize ? '0' : '20px')};
`

export const ColumnHeaderRightPartStyled = styled.div.attrs({})`
    position: absolute;
    right: 0;
    width: 20px;
`

export const DragIconStyled = styled.i.attrs({
    className: 'grip fas fa-grip-vertical',
})``

export const ResizerContainerStyled = styled.div.attrs({})`
    //padding-left:5px;
    //padding-right:5px;
    //display: inline-block;
    ////cursor: col-resize;
    //width: 7px;
    //height:20px;
    //
    //
    //&:hover {
    //  filter: brightness(120%) opacity(77%);
    width: 18px;
    height: 25px;
    cursor: grab;

    :hover,
    :active {
        border: 1px solid transparent;
        background: radial-gradient(circle at center, ${(props) => props.theme.colors.accentColor} 50%, transparent 22%),
            rgba(238, 238, 238, 0);
        background-repeat: space;
        background-size: 6px 6px;
        cursor: grab;
    }
`

export const ResizerContainerMobileStyled = styled.div.attrs({})`
    width: 6px;
    height: 15px;
    cursor: grab;
    border: 1px solid transparent;
    background: radial-gradient(circle at center, ${(props) => props.theme.colors.dark[1]} 50%, transparent 22%), rgba(238, 238, 238, 0);
    background-repeat: space;
    background-size: 4px 4px;
    z-index: 1000;
    margin: 0 5px 0 5px;
`
export const ResizerContentMobileStyled = styled.div.attrs((props) => ({
    className: 'row g-0 text-center mx-auto',
}))`
    background-color: ${(props) => props.theme.colors.background[1]};
    min-width: 177px;
    font-size: 25px;
    border-top: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    border-bottom: 1px solid ${(props) => props.theme.colors.accentColorMuted};
`
export const ResizerControlWrapperStyled = styled.div.attrs((props: ResizerControlProps) => ({
    className: props.className,
}))<ResizerControlProps>`
    z-index: 1000;
`
export const ResizerControlStyled = styled.i.attrs((props: ResizerControlProps) => ({
    className: props.className,
}))<ResizerControlProps>``
export const ResizerFldLabelStyled = styled.p.attrs((props: ResizerControlProps) => ({
    className: props.className,
}))<ResizerControlProps>`
    background-color: ${(props) => props.theme.colors.background[1]};
    color: ${(props) => props.theme.colors.accentColorLight};
`
export const ResizerStyled = styled.div.attrs((props: ColumnHeaderProperties) => ({
    className: 'rizer',
}))<ColumnHeaderProperties>`
    .rizer {
        left: 8px;
        top: 20px;
    }

    display: block;
    position: absolute;
    width: 7px;
    cursor: grab;
    z-index: 1;
    border-right: ${(props) => (!props.resizing || props.beingResized ? '2px solid ' + props.theme.table.columnColor : 'none')};
`
/*
  background-color: ${props => !props.beingResized ? "transparent" : props.theme.colors.dark[0]} !important;

 */
export const ActionHeaderColumnStyled = styled.th.attrs((props: ActionProperties) => ({}))<ActionProperties>`
    text-align: ${(props) => getTextAlign(props.textAlign)};
    //border:1px solid yellow;

    color: ${(props) => props.theme.table.headerColor};

    background-color: ${(props) => props.theme.table.headerBgColor}!important;
    ${(props) => props.beingResized && 'background-color: ' + props.theme.colors.dark[0] + ' !important;'};

    border-collapse: collapse;
    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}

    font-size: ${(props) => props.theme.table.headerFontSize};
    font-weight: bold;
    padding: 10px 0 5px 5px !important;
    height: 50px;

    position: sticky;
    top: -1px;
    z-index: 1;

    // gbw 04-Mar
    width: ${(props) => {
        const width = props.numberOfActions * ACTION_ITEM_WIDTH
        return width > 50 ? width : 50
    }}px;
    min-width: ${(props) => {
        const width = props.numberOfActions * ACTION_ITEM_WIDTH
        return width > 50 ? width : 50
    }}px;
`

/*
min-width: ${props => {
const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
return width > 60 ? width : 60
}}px;
width: ${props => {
const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
return width > 60 ? width : 60
}}px;
max-width: ${props => {
const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
return width > 60 ? width : 60
}}px;
*/

export const ActionColumnStyled = styled.td.attrs((props: ActionProperties) => ({}))<ActionProperties>`
    color: ${(props) => props.theme.colors.dark[0] || props.theme.table.columnColor};
    word-break: break-all;
    font-size: ${(props) => props.theme.table.columnFontSize};

    border-collapse: collapse;
    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}
    i {
        color: ${(props) => props.theme.table.columnColor};
        //  color: ${(props) => props.theme?.action?.colors?.default || props.theme.colors.dark[0] || props.theme.table.columnColor};
    }
`
/*
  min-width: ${props => {
    const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
    return width > 60 ? width : 60
  }}px;
  width: ${props => {
    const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
    return width > 60 ? width : 60
  }}px;
  max-width: ${props => {
    const width = (props.numberOfActions * ACTION_ITEM_WIDTH)
    return width > 60 ? width : 60
  }}px;
 */

/*
  background-color: ${props => !props.beingResized ? "transparent" : props.theme.colors.dark[0]} !important;

 */

export const ColumnStyled = styled.td.attrs((props: TdProperties) => ({}))<TdProperties>`
    color: ${(props) => props.theme.table.columnColor};
    //word-break: break-all;
    font-size: ${(props) => props.theme.table.columnFontSize};
    // gbw 04-Mar

    // MK 07/09/2023 -> Logs table, when the message is very long, if screws table when isSquashed=true
    // max-width: 500px;
    //max-width: ${(props) => (typeof props.cellWidth !== 'undefined' ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth))};
    ${(props) => props.beingResized && 'background-color: ' + props.theme.colors.dark[0] + ' !important;'};
    // MK 30-12-2023 don't need it anymore ⬇︎⬇︎⬇︎
    //#det_id_Logs_0_undefined_2 {
    //  max-width: 500px;
    //}
    ${(props) => props.hasLink && ':hover {cursor:pointer;text-decoration: underline;}'}

    // ${(props) => props.isEditable && 'border: 1px solid ' + props.theme.colors.dark[0] + ' !important;'};

  border-collapse: collapse;
    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}

    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}
`
/*
  width: ${props => typeof props.cellWidth !== "undefined" ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth)};
  min-width: ${props => typeof props.cellWidth !== "undefined" ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth)};

 */
export const CheckboxWrapperStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
`

interface TableTitleProperties extends ThemeProps<any> {
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    isSubHeader?: boolean | undefined
    isReportSummary?: boolean | undefined
    footerHeight: number

    isMobile?: boolean
    isFirstTable?: boolean

    isTabChild?: boolean
    isNested?: boolean

    width?: number
    height?: number
    isLoading?: boolean
}

interface TopFiltersProperties extends ThemeProps<any> {
    id: string
    ref: React.RefObject<HTMLDivElement>
}

interface TopFiltersTHeadProperties extends ThemeProps<any> {}

interface GuslTableWrapperProperties extends ThemeProps<any> {
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    isSubHeader?: boolean | undefined
    isReportSummary?: boolean | undefined
    footerHeight: number

    isMobile?: boolean
    isFirstTable?: boolean

    isTabChild?: boolean
    isNested?: boolean

    width?: number
    height?: number

    startPos: number
}

export const GuslTableWrapperStyled = styled.div.attrs((props: GuslTableWrapperProperties) => ({}))<GuslTableWrapperProperties>`
    background-color: inherit;

    padding: ${(props) => {
        if (props.isReportSummary) {
            return '0px 10px 5px 25px'
        } else if (props.isSummaryHeader) {
            return props.isMobile ? '0px' : '0px 10px 5px 11px'
        } else if (props.isSummaryFooter) {
            return props.isMobile ? '0px' : '0px 10px 5px 11px'
        } else {
            // return props.isMobile ? '15px 0px 5px 0px' : props.theme.table.titlePadding
            // blotter - trying to remove dead space
            return props.isMobile ? '15px 0px 5px 0px' : '0'
        }
    }};

    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => (props.isSummaryHeader || props.isSummaryFooter ? '14px' : props.theme.table.titleFontSize)};
`

interface TableExtProperties extends TableTitleProperties {
    isMobile: boolean
}

export const TableTitleStyled = styled.h5.attrs((props: TableExtProperties) => ({}))<TableExtProperties>`
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => {
        if (props.isMobile) {
            return '14px'
        }
        return props.isSummaryHeader || props.isSummaryFooter || props.isSubHeader ? '16px' : props.theme.table.titleFontSize
    }};
    padding: ${(props) => {
        if (props.isMobile) {
            return '7px 10px 5px 0px'
        }
        return props.isSummaryHeader || props.isSummaryFooter ? '7px 10px 0px 15px' : '0px'
    }};
    //  '7px 10px 0px 0px'
    width: 100%;
    text-align: ${(props) => (props.isMobile && !props.isSummaryHeader && !props.isSummaryFooter ? 'center' : 'left')};
`

export const TableTitleContainerStyled = styled.div.attrs((props: TableTitleProperties) => ({
    role: 'toolbar',
    className: 'row g-0',
}))<TableTitleProperties>`
    height: ${(props) => (props.isSubHeader || props.isSummaryHeader || props.isSummaryFooter ? '30px' : '50px')};
    padding: ${(props) => {
        if (props.isSummaryHeader) {
            return '0px'
        } else if (props.isSummaryFooter) {
            return '0px'
        } else {
            return props.theme.table.titlePadding
        }
    }};
`
export const RowsPerContentStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
    height: 100px;
`

export const TableContainerStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
    ${(props) => props.isLoading && 'opacity: 0.5;'};
`
/*
(props.isTabChild ? TAB_HEIGHT : 0) +
  ${props => props.isMobile && 'padding-bottom: ' + MAIN_TOOLBAR_HEIGHT +'px;'};
 */
export const TableContentStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
    ${(props) =>
        props.isMobile &&
        !props.isFirstTable &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.footerHeight + HEADER_HEIGHT + (props.isTabChild ? TAB_HEIGHT : 0) + 10) +
            'px)'};
    ${(props) => !props.isMobile && 'height: auto;'};
    ${(props) => props.isMobile && 'overflow: overlay;'};
`
export const TableMenuBarStyled = styled.div.attrs((props: TableTitleProperties) => ({
    className: 'col d-flex justify-content-end table-menubar',
    id: 'table_menubar',
}))<TableTitleProperties>`
    > .navbar-collapse {
        > .dropdown {
            > button {
                color: ${(props) => props.theme.table.menuButtonColor};
                background-color: ${(props) =>
                    props.isSummaryHeader || props.isSummaryFooter ? 'inherit' : props.theme.table.menuButtonBgColor};
                border: none;

                :hover {
                    box-shadow: none;
                    color: ${(props) => props.theme.table.menuButtonHoverColor};
                    background-color: ${(props) => props.theme.table.menuButtonHoverBgColor};
                }

                :active {
                    box-shadow: none;
                }

                :focus {
                    box-shadow: none;
                }
            }
        }
    }
`
// export const BlankTopFilterStyled = styled.th.attrs((props: ColumnHeaderProperties) => ({
//
// }

export const TopFilterStyled = styled.th.attrs((props: ColumnHeaderProperties) => ({
    className: props.className,
}))<ColumnHeaderProperties>`
    text-align: ${(props) => getTextAlign(props.textAlign)};
    ${(props) => props.beingResized && 'background-color: ' + props.theme.colors.dark[0] + ' !important;'};
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    min-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    max-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
`
// MK 19/08/2023
export const TopFilterIndividualStyled = styled.th.attrs((props: ColumnHeaderIndividualProps) => ({
    className: props.className,
}))<ColumnHeaderIndividualProps>`
    text-align: ${(props) => getTextAlign(props.textAlign)};
    ${(props) => props.beingResized && 'background-color: ' + props.theme.colors.dark[0] + ' !important;'};
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    min-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    max-width: ${(props) => (props.cellWidth ? pxOrPerc(props.cellWidth) : 'initial')};
    position: absolute;
    top: ${(props) => props.top}px;
    right: ${(props) => props.right}px;
    background-color: ${(props) => props.theme.colors.background[1]};
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    //padding: 5px 2px;
    z-index: 1000;
    height: 100px;
`
// MK 22/08/2023
export const TopFilterIndividualArrowStyled = styled.i.attrs((props: ColumnHeaderIndividualProps) => ({
    className: props.className,
}))<ColumnHeaderIndividualProps>`
    color: ${(props) => props.theme.colors.accentColorMuted};
    //font-size:1.15rem;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
`

// MK 26/08/2023
export const CheckboxFiltersIndividualStyled = styled.div.attrs((props) => ({
    className: props.className,
}))`
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
`
// MK 27/08/2023
export const CheckboxFiltersTopStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-center',
}))``
// MK 22/08/2023
export const NumOfFiltersAppliedStyled = styled.sup.attrs((props: ColumnHeaderIndividualProps) => ({
    className: props.className,
}))<ColumnHeaderIndividualProps>`
    color: ${(props) => props.theme.colors.light};
    background-color: ${(props) => props.theme.colors.accentColor};
    padding: 1px 2px 0 2px;
    border-radius: 3px;
    font-size: 0.8rem;
    font-weight: bold !important;
`
// MK 15/08/2023
export const TopFiltersWrapperStyled = styled.div.attrs((props: TopFiltersProperties) => ({
    id: props.id,
    ref: props.ref,
    className: 'table-responsive',
}))<TopFiltersProperties>`
    padding: 0 20px;
    overflow-x: hidden;
    width: 97.5%;
`
// MK 16/08/2023
export const TopFiltersTHeadStyled = styled.thead.attrs((props: TopFiltersTHeadProperties) => ({}))<TopFiltersTHeadProperties>`
    display: inline-table;
    width: 97%;
`
