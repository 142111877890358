import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { assignReferences, clone, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode, PermissionDTO, PermissionGroupDTO } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'
import PermissionBlock from './PermissionBlock'
import './Persmissions.css'
import { PermissionFieldStyled, PermissionScrollStyled, PermissionWrapperStyled } from './styled'

export const PermissionsField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'PermissionsField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)
    //     const [formValue, setFormValue] = useState<PermissionGroupDTO[]>(properties?.data || []);
    const [formValue, setFormValue] = useState<PermissionGroupDTO[]>(clone(_guslFormState?.getFieldValue(properties) || []))

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [refreshCounter, setRefreshCounter] = useState<number>(0)

    const requiredPermission: string = 'VIEW'
    const delimiter: string = '_'
    const MUST: string = delimiter + requiredPermission

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(clone(_guslFormState?.getFieldValue(properties) || []))
    }

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        return (
            <>
                {properties.isNumber && <div className="text-right">{'' + formValue}</div>}
                {!properties.isNumber && <div className="">{'' + formValue}</div>}
            </>
        )
    }

    const onInputChange = (permissionGroup: PermissionGroupDTO, permission: PermissionDTO, value: boolean) => {
        const currentCollection: PermissionGroupDTO[] = clone(formValue)
        currentCollection
            ?.filter((group) => group.name === permissionGroup.name)
            .forEach((group) => {
                const existingRequiredPermission = group.permissions?.filter((item) => item.permission.endsWith(MUST))[0]

                if (existingRequiredPermission) {
                    group.permissions
                        ?.filter((item) => item.permission === permission.permission)
                        .forEach((item) => {
                            item.value = value
                            permission.value = value
                        })

                    /*REQUIRED PERMISSION UN-CHECKED*/
                    if (!existingRequiredPermission.value) {
                        group.permissions?.forEach((item) => {
                            item.value = false
                            permission.value = false
                        })
                    }
                } else {
                    /*PERMISSION BLOCK DOES NOT HAVE REQUIRED PERMISSION*/
                    group.permissions
                        ?.filter((item) => item.permission === permission.permission)
                        .forEach((item) => {
                            item.value = value
                            permission.value = value
                        })
                }
            })
        setFormValue(currentCollection)
        properties.onChange(properties.fieldConfig.name, currentCollection)
        setRefreshCounter(refreshCounter + 1)
    }

    const renderPermissions = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <PermissionFieldStyled submitted={submitted} noValue={!formValue}>
                            <PermissionScrollStyled>
                                <PermissionWrapperStyled>
                                    {formValue
                                        // .sort((a, b) => compareString(a.name, b.name))
                                        .map((permissionGroup: PermissionGroupDTO, idx: number) => (
                                            <PermissionBlock
                                                key={idx + '-' + refreshCounter}
                                                permissionGroup={clone(permissionGroup)}
                                                /*@ts-ignore*/
                                                onChange={onInputChange}
                                                disabledField={disableField}
                                                formMode={formMode}
                                                MUST={MUST}
                                            />
                                        ))}
                                </PermissionWrapperStyled>
                            </PermissionScrollStyled>
                        </PermissionFieldStyled>
                    </>
                )}
            </>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)
        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled>{renderPermissions()}</FieldContentStyled>v{' '}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
