import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { ErrorMessageStyled } from '../../styled'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { TableColumnResizeState } from '../gusl-table/guslColumnResizeSlice'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import { CommentWrapperStyled, TextCommentStyled } from './styled'

export const TextCommentField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    // const [className] = React.useState<string>(() => 'TextCommentField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || '')

    const [tableValue, setTableValue] = useState<string>('')
    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [numberRows, setNumberRows] = useState<number>(4)
    const [fieldWidth, setFieldWidth] = useState<string>('300px')

    // if you want ellipsis on text field ... need to pass cellWidth to TextFieldStyled
    const code: string = maintainTableService.getCodeFromUrl()
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    const cellWidth: number | string | undefined = _columnResizeState?.resizedColumns[properties.fieldConfig.name]

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        let tableValue = properties?.data || ''
        tableValue = tableValue.replace('\n', ' ')
        setTableValue(tableValue)
        let count = ((properties?.data || '').match(/\n/g) || []).length
        setNumberRows(count + 4)
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (!properties.isDialog && valueRef?.current) {
                    // @ts-ignore
                    const rect = valueRef?.current?.getBoundingClientRect()
                    if (rect) {
                        const w = window.innerWidth - rect.left - 200
                        setFieldWidth(w > 0 ? w + 'px' : 300 + 'px')
                    } else {
                        setFieldWidth(300 + 'px')
                    }
                } else if (properties.isDialog) {
                    setFieldWidth('100%')
                }
            }, 100)
        })
    }, [properties.formMode])

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')
        // <span className="" style={style} dangerouslySetInnerHTML={{__html: formValue}}></span>

        // return (
        //     <TextCommentTableStyled cellWidth={cellWidth} style={style}>
        //         <span className="" style={style} dangerouslySetInnerHTML={{__html: tableValue}}></span>
        //     </TextCommentTableStyled>
        // )

        return (
            <TextCommentStyled
                style={style}
                numberRows={numberRows}
                fieldWidth={fieldWidth}
                id={properties?.fieldConfig?.name || 'def'}
                key={properties?.fieldConfig?.name || 'def'}
                ref={valueRef}
                value={formValue}
                autoFocus={properties.isFirstField}
                readOnly={true}
                disabled={true}
                submitted={submitted}
                noValue={!formValue}
            />
        )
    }

    const renderField = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        const onDataInputChange = (value: string) => {
            setFormValue(value)
            properties.onChange(properties.fieldConfig.name, valueRef?.current?.value)
        }

        return (
            <CommentWrapperStyled>
                <TextCommentStyled
                    numberRows={numberRows}
                    fieldWidth={fieldWidth}
                    id={properties?.fieldConfig?.name || 'def'}
                    key={properties?.fieldConfig?.name || 'def'}
                    ref={valueRef}
                    value={formValue}
                    autoFocus={properties.isFirstField}
                    readOnly={disableField}
                    disabled={disableField}
                    onChange={(e) => onDataInputChange(e.target.value)}
                    required={required}
                    submitted={submitted}
                    noValue={!formValue}
                />
            </CommentWrapperStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled>{renderField()}</FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
