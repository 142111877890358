import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../../app/hooks'
import { TickerDO } from '../../../buy-sell-form/types'
import { TickerLookup } from '../../../buy-sell-form/v2_components/ticker-lookup/TickerLookup'
import { TRADING_HOME_PAGE } from '../../TradingPage'
import { TradingState } from '../../tradingSlice'
import { HeadlineItem, TICKER_VALUE_TYPE } from './HeadlineItem'
import {
    HeadlineItemWrapperStyled,
    HeadlineTickerLookupWrapperStyled,
    TickerHeadlineRowStyled,
    TickerHeadlineWrapperStyled,
} from './styled'

interface TickerHeadlineProperties {
    code: string
}

export const TickerHeadline = (properties: TickerHeadlineProperties): React.ReactElement<TickerHeadlineProperties> => {
    const navigate = useNavigate()

    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[properties.code])

    const renderLookup = (): React.ReactElement => {
        const onTickerSelected = (ticker: TickerDO) => {
            navigate(TRADING_HOME_PAGE + '/' + ticker?.symbol || '')

            // setTickers(ticker)
            // performPreOrderQuery(ticker, _buySellSlice.orderAction || OrderAction.NEW)
            // setLookUpClosed(false)
        }

        return (
            <HeadlineTickerLookupWrapperStyled>
                <TickerLookup code={'BuySellTickerLookup'} onTickerSelected={onTickerSelected} ticker={_tradingSlice?.ticker} />
            </HeadlineTickerLookupWrapperStyled>
        )
    }

    const renderItem = (label: string, value: number | undefined, valueType: TICKER_VALUE_TYPE): React.ReactElement => {
        return (
            <HeadlineItemWrapperStyled>
                <HeadlineItem
                    ticker={_tradingSlice?.ticker}
                    label={label}
                    value={value}
                    valueType={valueType}
                    currency={_tradingSlice?.ticker?.currency?.code || ''}
                />
            </HeadlineItemWrapperStyled>
        )
    }

    return (
        <TickerHeadlineWrapperStyled>
            <TickerHeadlineRowStyled>
                {renderLookup()}
                {renderItem('Last price', _tradingSlice?.ticker?.lastPrice, 'lastPrice')}
                {renderItem('24h change', _tradingSlice?.ticker?.change, 'change')}
                {renderItem('24h high', _tradingSlice?.ticker?.dailyHigh, 'dailyHigh')}
                {renderItem('24h low', _tradingSlice?.ticker?.dailyLow, 'dailyLow')}
                {renderItem('Volume', _tradingSlice?.ticker?.volume, 'volume')}
            </TickerHeadlineRowStyled>
        </TickerHeadlineWrapperStyled>
    )
}
