import styled, { ThemeProps } from 'styled-components'

export const ErrorPanelStyled = styled.div.attrs((props) => ({
    className: 'invalid text-center ',
}))`
    font-size: 11px;
`

export const QuantityWrapperStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-between',
}))``

export const QuantityLabelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.headerColor};
    font-size: ${(props) => props.theme.table.headerFontSize};
    min-width: 100px;
    line-height: 40px;
`
export const InputStyled = styled.input.attrs((props) => ({
    className: 'form-control form-control-sm   border-top-0 border-start-0 border-end-0 text-light ',
}))`
    color: ${(props) => props.theme.colors.dark[0]} !important;
    font-size: 12px;
    width: 45% !important;
    padding: 5px !important;
`
export const DisplayContainerStyled = styled.div.attrs((props) => ({
    className: 'row g-0 d-flex ',
    id: 'display-container',
}))``

interface DisplayFieldPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean
}

export const DisplayFieldPanelStyled = styled.div.attrs((props: DisplayFieldPanelStyledProps) => ({
    className: 'mb-3 ' + (props.isMobile ? 'col-12' : 'col-6'),
    id: 'display-field-panel',
}))<DisplayFieldPanelStyledProps>`
    text-align: center;
    line-height: 25px;
    margin: 0 20px 0 0;
    padding: 0 15px 0 10px;
    border-radius: 3px;
`

export const DisplayFieldStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-between text-light',
}))`
    // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;
`

export const DisplayLabelStyled = styled.span.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: 12px;
`

export const DisplayValueStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

interface ActionButtonPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean
    side?: string
    canShowInfo: boolean
    tickerId: string
}

export const ActionButtonPanelStyled = styled.div.attrs((props: ActionButtonPanelStyledProps) => ({
    className:
        'align-items-center justify-content-evenly ' +
        'col-' +
        (props.side && !props.isMobile && props.canShowInfo && props.tickerId ? '6' : '12'),
}))<ActionButtonPanelStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

export const ActionButtonsStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-evenly',
}))``

export const ActionButtonStyled = styled.div.attrs((props) => ({
    className: 'p-1 mx-1',
}))`
    background-color: ${(props) => props.theme.colors.background[1]};
    border-radius: 20px;
`

export const DisclaimerStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor};
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 10px 40px 10px;
`
export const ErrorStyled = styled.p.attrs((props) => ({
    className: 'small invalid',
}))``
