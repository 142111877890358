import styled, { ThemeProps } from 'styled-components'

export const WidgetsRowStyled = styled.div.attrs((props) => ({
    className: 'row g-0',
    id: 'widget-row',
}))``

interface WidgetButtonGroupProps extends ThemeProps<any> {
    cols?: number
}

export const WidgetButtonGroupStyled = styled.div.attrs((props: WidgetButtonGroupProps) => ({
    className: 'row g-0 row-cols-' + (props.cols ? props.cols : '3') + ' d-flex justify-content-center',
    id: 'widget-button-group',
}))<WidgetButtonGroupProps>`
    background-color: ${(props) => props.theme?.colors?.background[1]};
    border-radius: 20px;
`
export const WidgetsLeftButtonCollectionStyled = styled.div.attrs((props) => ({
    className: 'col-5 mb-3 ',
    id: 'widget-left-buttons',
}))`
    min-width: 160px;
`

export const WidgetsRightButtonCollectionStyled = styled.div.attrs((props) => ({
    className: 'col-4 ms-auto mb-3 ',
    id: 'widget-right-buttons',
}))`
    width: auto;
`

interface WidgetButtonProps extends ThemeProps<any> {
    active?: boolean
}

export const WidgetButtonStyled = styled.div.attrs((props: WidgetButtonProps) => ({
    className: 'text-center',
    role: 'button',
}))<WidgetButtonProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    background-color: ${(props) => (props.active ? props.theme?.colors?.dark[1] : 'inherit')};
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    min-width: 50px;
`

export const WidgetsNoChartsAvailableStyled = styled.div.attrs((props) => ({
    className: 'text-center mt-3 ',
    id: 'widget-no-charts-avail',
}))``
