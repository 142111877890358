import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ActionDialog from '../../components/common/action-dialog/ActionDialog'
import { ActionConfigDTO } from '../../components/types'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { IEnvironment } from '../../providers/environment/types'
import { guslStorage } from '../../providers/session/GuslStorage'
import { SessionContext } from '../../providers/session/SessionContext'
import { SignInResponseDTO } from '../../providers/session/types'
import { log } from '../../services/LogService'
import { translateService } from '../../services/TranslateService'
import { isBlank, notBlank } from '../../utils/TypeCheckers'
import { IMountedMonitor, MountedMonitor, RunOnceEffect, unSubscribe } from '../../utils/Utils'
import Password from './components/Password'
import Username from './components/Username'
import './Login.css'
import { FormErrorMessageStyled, FormStyled, LoginButtonStyled } from './styled'
import { LoginProps } from './types'

export const inputClass = (extraClass?: string) =>
    'form-control mb-1 bg-transparent rounded-0 border-top-0 ' + 'border-start-0 border-end-0 text-light ' + extraClass

// export const newInputClass = (extraClass?: string) => extraClass ? extraClass : 'form-control shadow mb-1 bg-transparent rounded-0 border-top-0 ' +
//     'border-start-0 border-end-0 text-light ';

export const inputWrapperClass = (extraClass?: string) =>
    'form-control shadow mb-1 bg-transparent rounded-0 border-top-0 ' + 'border-start-0 border-end-0 text-light ' + extraClass

export const inputChildClass = (extraClass?: string) => 'bg-transparent border-0 ' + extraClass

const LoginUsernamePassword = (props: LoginProps): React.ReactElement => {
    const navigate = useNavigate()
    const environmentContext = React.useContext(EnvironmentContext)
    const sessionContext = React.useContext(SessionContext)

    const [className] = React.useState<string>(() => 'LoginUsernamePassword-' + new Date().getTime())
    const [homePage, setHomePage] = useState<string | undefined>(undefined)
    const [changePasswordAction] = useState<ActionConfigDTO | undefined>(() => environmentService.getEnvironment()?.changePassword)
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState(false)
    const [username, setUsername] = useState<string>(guslStorage.getJwtUserName())
    const [password, setPassword] = useState<string>('')
    const [mountedMonitor] = useState<IMountedMonitor>(MountedMonitor(className, true))
    const submitLabel = translateService.getValue('login.submit', 'Login')

    RunOnceEffect(() => {
        let subscription = environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            if (environment.loaded) {
                setHomePage(environment.homePage)
            }
        })
        return () => {
            unSubscribe(subscription)
        }
    })

    const onSignIn = () => {
        const valid = (): boolean => {
            return notBlank(username) && notBlank(password)
        }
        setSubmitted(true)
        if (!valid()) {
            return
        }
        setErrorMessage('')
        sessionContext
            .signIn(username, password)
            .then((response: SignInResponseDTO) => {
                console.log('onSignIn', response)
                document.documentElement.style.setProperty(`--theme`, response?.theme || '')

                if (response.passwordChangeRequired && environmentService.getEnvironment()?.changePassword) {
                    const action: ActionConfigDTO | undefined = environmentService.getEnvironment()?.changePassword
                    if (action) {
                        setShowChangePassword(true)
                    } else {
                        console.log('Home page', homePage)
                        navigate('/' + homePage || '/')
                    }
                } else {
                    navigate('/' + homePage || '/')
                }
            })
            .catch((error) => {
                console.log('onSignIn error', mountedMonitor, error)
                if (mountedMonitor.isMounted()) {
                    log.error(className, 'MSG002', 'SignIn error', error)
                    setErrorMessage('Invalid details')
                }
            })
    }

    const renderActionPlaceholder = (): React.ReactElement => {
        return (
            <>
                {showChangePassword && changePasswordAction && (
                    <ActionDialog
                        action={changePasswordAction}
                        cannotClose={true}
                        closeDialog={() => {
                            setShowChangePassword(false)
                            navigate('/' + homePage || '/')
                        }}
                    />
                )}
            </>
        )
    }

    return (
        <>
            <FormStyled id={'login_form'} noModal={environmentService.getEnvironment()?.loginUiProperties?.noModal}>
                <Username
                    hasLabel={props.environment?.loginUiProperties?.withLabel || false}
                    hasIcon={props.environment?.loginUiProperties?.withIcon || false}
                    asUppercase={props.environment?.loginUiProperties?.asUppercase || false}
                    value={username}
                    autoFocus={isBlank(username)}
                    formSubmitted={submitted}
                    onNextField={() => {}}
                    onChange={(value: string) => {
                        guslStorage.setJwtUserName(value)
                        setUsername(value)
                    }}
                />
                <Password
                    hasLabel={props.environment?.loginUiProperties?.withLabel || false}
                    hasIcon={props.environment?.loginUiProperties?.withIcon || false}
                    asUppercase={props.environment?.loginUiProperties?.asUppercase || false}
                    value={password}
                    autoFocus={notBlank(username)}
                    formSubmitted={submitted}
                    onNextField={() => {
                        onSignIn()
                    }}
                    onChange={(value: string) => setPassword(value)}
                />
                <LoginButtonStyled
                    size={'xl'}
                    tabIndex={3}
                    type="submit"
                    noModal={environmentService.getEnvironment()?.loginUiProperties?.noModal}
                    onClick={onSignIn}
                >
                    {submitLabel}
                </LoginButtonStyled>
            </FormStyled>
            {errorMessage && <FormErrorMessageStyled>{errorMessage}</FormErrorMessageStyled>}
            {renderActionPlaceholder()}
        </>
    )
}
export default LoginUsernamePassword
