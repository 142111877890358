import styled, { ThemeProps } from 'styled-components'

export const RowStyled = styled.div.attrs((props) => ({
    className: 'row g-0 mb-3',
}))``

export const ColStyled = styled.div.attrs((props) => ({
    className: 'col-xl-6 col-lg-12  px-2 text-center',
}))``

export const ButtonContentsStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-between w-100',
}))``

export const ButtonLabelStyled = styled.div.attrs((props) => ({}))``

export const PriceWrapperStyled = styled.div.attrs((props) => ({
    className: 'ms-1',
}))``

export const PriceStyled = styled.span.attrs((props) => ({
    className: 'me-1',
}))``

export const CurrencyStyled = styled.span.attrs((props) => ({}))``

interface ButtonProps extends ThemeProps<any> {
    side?: string
    active?: boolean
    className?: string
}

export const ButtonStyled = styled.button.attrs((props: ButtonProps) => ({
    role: 'button',
}))<ButtonProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    background-color: ${(props) => (props.side === 'SELL' ? props.theme?.colors?.bespoke[1] : props.theme?.colors?.primary[1])};
    opacity: ${(props) => (!props.active ? 0.7 : 1)};
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold;
    border: 2px solid ${(props) => (props.active ? props.theme?.colors?.light : props.theme?.colors?.dark[0])};
    padding: 0 10px;
    @media (max-width: 1400px) {
        font-size: 9px;
        padding: 0 4px;
    }
    @media (max-width: 1200px) {
        font-size: 10px;
        padding: 0 9px;
    }

    @media (max-width: 992px) {
        font-size: 9px;
        padding: 0 8px;
    }

    @media (max-width: 768px) {
        font-size: 8px;
        padding: 0 7px;
    }

    @media (max-width: 576px) {
        font-size: 7px;
        padding: 0 6px;
    }
`
