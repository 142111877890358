import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings } from '../../../services/ValidationService'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { ErrorMessageStyled } from '../../styled'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, InputStyled } from '../text/styled'

export const FileUploadField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<File>()
    const [formValue, setFormValue] = useState<File | undefined>(_guslFormState?.getFieldValue(properties) || properties.data || undefined)

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
    }

    useEffect(() => {
        setFormValue(_guslFormState?.getFieldValue(properties) || properties.data || undefined)
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        if (fieldValue) {
            return true
        }
        setErrorMessage('No file selected')
        // vvv not sure why formvalue is null
        return true // false;
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        return <></>
    }

    const onDataInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSubmitted(false)
        setErrorMessage(undefined)
        if (e.target.files) {
            // @ts-ignore
            console.log('->', e.target.files[0])
            setFormValue(e.target.files[0])
            properties.onChange(properties.fieldConfig.name, e.target.files[0])
        }
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)
        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <InputStyled
                                    type={'file'}
                                    id={properties?.fieldConfig?.name || 'def'}
                                    key={properties?.fieldConfig?.name || 'def'}
                                    onChange={(e) => onDataInputChange(e)}
                                    readOnly={disableField}
                                    disabled={disableField}
                                    required={required}
                                    submitted={submitted}
                                    noValue={!formValue}
                                />
                            </FieldContentWrapperStyled>
                            {submitted && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
