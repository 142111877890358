import styled, { ThemeProps } from 'styled-components'
import { inputClass } from '../../../pages/login/LoginUsernamePassword'
import { getTextAlign } from '../../../utils/CssUtils'
import { FieldPositionProperties } from '../../types'
import { TableStyledProps } from '../date-summary/styled'
import { defaultCellWidth } from '../gusl-table/guslTableSlice'
import { displayTable, flipX, flipY, RowStyledProperties, ScrollbarColouring } from '../gusl-table/styled/table/styled'
import { TdProperties } from '../maintain-table/styled'

export interface IndicatorsContainerProps extends ThemeProps<any> {
    isActive: boolean
}

export const LookupFieldStyled = styled.div.attrs((props) => ({
    className: 'form-control shadow mb-1 bg-transparent rounded-0 border-top-0 ' + 'border-start-0 border-end-0 text-light ',
}))`
    height: 70px !important;
    padding-right: 0 !important;
`

export const DownArrowStyled = styled.div.attrs((props) => ({}))`
    // display: -webkit-box;
    // display: -webkit-flex;
    // display: -ms-flexbox;
    // display: flex;
    padding: 8px;
    // -webkit-transition: color 150ms;
    // transition: color 150ms;
    // box-sizing: border-box;
`
export const DownArrowSvgStyled = styled.svg.attrs((props) => ({
    height: '20',
    width: '20',
    viewBox: '0 0 20 20',
}))``
// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
//   <path
//
//   d="M11.484 8.452c-0.436 0.446-1.043 0.481-1.576 0l-3.908-3.747-3.908 3.747c-0.533 0.481-1.141 0.446-1.574 0-0.436-0.445-0.408-1.197 0-1.615 0.406-0.418 4.695-4.502 4.695-4.502 0.217-0.223 0.502-0.335 0.787-0.335s0.57 0.112 0.789 0.335c0 0 4.287 4.084 4.695 4.502s0.436 1.17 0 1.615z"/>
//
// </svg>
export const DownArrowSvgPathStyled = styled.path.attrs((props) => ({
    d: 'M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z',
    role: 'button',
}))`
    // fill : whitesmoke;
    fill: ${(props) => props.theme.colors.light};
`
export const UpArrowSvgPathStyled = styled.path.attrs((props) => ({
    d: 'M15.484 8.452c-0.436 0.446-1.043 0.481-1.576 0l-3.908-3.747-3.908 3.747c-0.533 0.481-1.141 0.446-1.574 0-0.436-0.445-0.408-1.197 0-1.615 0.406-0.418 4.695-4.502 4.695-4.502 0.217-0.223 0.502-0.335 0.787-0.335s0.57 0.112 0.789 0.335c0 0 4.287 4.084 4.695 4.502s0.436 1.17 0 1.615z',
    role: 'button',
}))`
    // fill : whitesmoke;
    fill: ${(props) => props.theme.colors.accentColor};
`
export const SeparatorStyled = styled.span.attrs((props) => ({}))`
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    //background-color: hsl(0, 0%, 80%);
    background-color: ${(props) => props.theme.table.columnColor} !important;

    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
`

export const IndicatorsContainerStyled = styled.div.attrs((props: IndicatorsContainerProps) => ({
    role: 'button',
    className: 'btn-down-arrow',
}))<IndicatorsContainerProps>`
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: ${(props) => (props.isActive ? props.theme.table.panelBgColor : 'inherit')};

    padding-left: 5px;

    :hover {
        background-color: ${(props) => props.theme.table.panelBgColor};
    }
`

// MK 13/08/2023
export const IndicatorsContainerStyledTextFilter = styled.div.attrs((props: IndicatorsContainerProps) => ({
    role: 'button',
}))<IndicatorsContainerProps>`
    border-left: 1px solid ${(props) => props.theme.action.borderColor};
    background-color: ${(props) => (props.isActive ? props.theme.table.panelBgColor : 'inherit')};

    :hover {
        background-color: ${(props) => props.theme.table.panelBgColor};
    }
`
// END OF 13/08/2023

export const ValueContainerStyled = styled.div.attrs((props) => ({}))`
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // display: grid;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px 0px 0px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`

export const InputFieldContainerStyled = styled.div.attrs((props) => ({}))`
    width: 100%;
`

export const LookupTableViewStyled = styled.div.attrs((props) => ({}))``

export const ControlContainerStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    className: 'd-flex justify-content-between',
}))<FieldPositionProperties>`
    ${(props) => props.inLine && 'border: solid 1px #434343 !important;'}
    ${(props) => props.inLine && 'border-radius: 5px !important;'}
    ${(props) => props.inLine && 'line-height: 16px;'}
    ${(props) => props.inLine && 'padding-left: 5px;'}

    ${(props) => calcWidth(props, 'ControlContainerStyled')}
`

export const LookupControlContainerStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    className: 'd-flex justify-content-between',
}))<FieldPositionProperties>`
    // border: 1px solid #1f1f1f38 !important;

    ${(props) => props.inLine && 'border: solid 1px #434343 !important;'}
    ${(props) => props.inLine && 'border-radius: 5px !important;'}
    ${(props) => props.inLine && 'line-height: 16px;'}
    ${(props) => props.inLine && 'padding-left: 5px;'}
    ${(props) => props.inLine && 'width: 100%;'}
    ${(props) => !props.inLine && calcWidth(props, 'LookupControlContainerStyled')}
`
export const ControlContainerTextFilterStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-between ',
}))`
    border-bottom: 1px solid ${(props) => props.theme.action.borderColor};
    padding: 5px 0;
`

export const LookupScrollStyled = styled.div.attrs((props) => ({}))`
    max-height: 450px;
    overflow: auto;
`

// export const LookupTableStyled = styled.table.attrs(props => ({
//     className: 'table table-striped'
// }))`
//   width: 100%;
//   padding: 0.5rem 0.5rem;
//   background-color: var(--bs-table-bg);
//   border-bottom-width: 1px;
//   box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
// `

export const LookupHeaderRowStyled = styled.tr.attrs((props) => ({}))`
    color: ${(props) => props.theme.lookup.columnColor};
    font-size: ${(props) => props.theme.lookup.columnFontSize};
`

export const LookupRowStyled = styled.tr.attrs((props) => ({}))`
    color: ${(props) => props.theme.lookup.columnColor};
    font-size: ${(props) => props.theme.lookup.columnFontSize};

    :nth-of-type(odd) > * {
        color: ${(props) => props.theme.lookup.columnColor} !important;
        font-size: ${(props) => props.theme.lookup.columnFontSize};
    }

    :hover {
        color: ${(props) => props.theme.lookup.hoverColor};
        background-color: ${(props) => props.theme.lookup.hoverBgColor};
    }
`

export const LookupColumnHeaderStyled = styled.th.attrs((props) => ({
    className: 'px-3',
}))`
    color: ${(props) => props.theme.lookup.headerColor};
    font-size: ${(props) => props.theme.lookup.headerFontSize};
    font-weight: bold;
    text-transform: capitalize;
}`

// export const LookupColumnStyled = styled.td.attrs(props => ({
//     className: 'px-3'
// }))`
//   color: ${props => props.theme.lookup.columnColor};
//   font-size: ${props => props.theme.lookup.columnFontSize};
//
// `

interface LookupStyledProps extends ThemeProps<any> {
    noValue: boolean
    submitted: boolean
}

interface CurrentlySelectedProps extends ThemeProps<any> {}

interface LookupDataItemProps extends ThemeProps<any> {
    isMulti?: boolean
    onClick?: Function
    isSelected?: boolean
    isJustSelected?: boolean
    className?: string
    id?: string
}

export const LookupContainerStyled = styled.div.attrs((props: LookupStyledProps) => ({
    className:
        'form-control bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light ' +
        (props.submitted && props.noValue ? 'yellow' : ''),
}))<LookupStyledProps>`
    // commented out - seems big on Edit BO user
    //height: 70px !important;
    padding: 0px !important;
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};

    //@media (max-width: 440px) {
    //    width: 240px !important;
    //}

    .main-content {
        @media (min-width: 440px) {
            width: 300px !important;
        }

        @media (min-width: 900px) {
            width: 450px !important;
        }
    }
`
export const LookupContainerTextFilterStyled = styled.div.attrs((props: LookupStyledProps) => ({
    className: 'bg-transparent rounded-0',
}))<LookupStyledProps>`
    margin: 0 10px;
    padding: 0;
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`
export const NoneCurrentlySelected = styled.div.attrs((props) => ({}))`
    // height:52px;
`

export const CurrentlySelected = styled.div.attrs((props: CurrentlySelectedProps) => ({
    className: 'text-center',
}))<CurrentlySelectedProps>`
    // height:52px;
    color: ${(props) => props.theme.colors.accentColor};

    span {
        color: ${(props) => props.theme.colors.dark[1]};
        font-size: 13px;
    }
`
export const LookupDataItemWrapperStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'd-flex align-items-center',
}))<LookupDataItemProps>`
    :hover {
        background-color: ${(props) => (props.isSelected ? 'inherit' : props.theme.table.rowHoverBgColor)};
    }
`
export const LookupDataItemStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'd-flex justify-content-start align-items-center',
}))<LookupDataItemProps>`
    //cursor:${(props) =>
        props.isSelected
            ? 'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="30" style="font-size: 20px;" fill="green"><text y="15">✔</text></svg>\'), auto;'
            : 'pointer'};
    cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
    // MK 13/8/2023 items with longer text height 30px was not enough to display all text
    min-height: 30px;
    padding: 5px 10px;
    color: ${(props) =>
        props.isJustSelected
            ? props.theme.colors.accentColor
            : props.isSelected
            ? props.theme.colors.accentColorMuted
            : props.theme.table.columnColor};
    font-size: ${(props) => props.theme.table.columnFontSize};
    width: 100%;

    :hover {
        background-color: ${(props) => (props.isSelected ? 'inherit' : props.theme.table.rowHoverBgColor)};
    }
`
export const LookupItemStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    onClick: props.onClick,
}))<LookupDataItemProps>`
    padding: 0 10px;
    min-width: 50px;
`
export const NoMatchStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'text-center',
}))<LookupDataItemProps>`
    color: ${(props) => props.theme.colors.warning};
    padding: 0 10px;
    margin-top: 5px;
`
export const NoMatchCloseStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.colors.warning};
`

export const MatchesContainerStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    id: 'lookup_matches_cont',
}))<FieldPositionProperties>`
    text-align: center;
`

const log = (lbl: string, props: FieldPositionProperties, label?: string, result?: any) => {
    // console.log(`${props.name}  ${lbl} -----> ${label}   windowWidth: ${props.windowWidth} panelWidth: ${props.panelWidth} hasLabel: ${props.hasLabel} isResultTable: ${props.isResultTable} startPos: ${props.startPos} isMobile: ${props.isMobile} isDialog: ${props.isDialog}  result: ${result} `)
}

const leftMargin = 20

const calcWidth = (props: FieldPositionProperties, label?: string) => {
    let rightMargin = 40
    if (props.isDialog) {
        rightMargin += 45
    }
    if (props.isDialog && props.isResultTable) {
        rightMargin -= 15
    }
    if (props.windowWidth && props.startPos && props.windowWidth - props.startPos < 500) {
        if (props.windowWidth - props.startPos - rightMargin > 0) {
            log('calcWidth-1', props, label, props.windowWidth - (props.hasLabel ? props.startPos : 0) - rightMargin)
            return 'width:' + (props.windowWidth - (props.hasLabel ? props.startPos : 0) - rightMargin) + 'px !important;'
        }
    } else if (props.windowWidth - props.startPos > 500) {
        // if (props.isResultTable) {
        log('calcWidth-2', props, label, props.panelWidth - rightMargin)
        return 'width:' + (props.panelWidth - (props.windowWidth - props.startPos) - rightMargin) + 'px !important;'
        // } else {
        //     log('calcWidth', props, label, '500px');
        //     return 'width:' + 500 + 'px !important;'
        // }
    }
    log('calcWidth', props, label, false)
    return false
}

const calcLeft = (props: FieldPositionProperties, label?: string) => {
    if (props.windowWidth && props.startPos && props.windowWidth - props.startPos < 500) {
        if (props.isDialog && props.isResultTable) {
            log('calcLeft-1', props, label, leftMargin)
            return 'left:' + leftMargin + 'px;'
        }
        let lMargin = 10
        let finalLeft = props.startPos * -1 + lMargin
        if (finalLeft < 0) {
            finalLeft = 0
        }
        log('calcLeft-2', props, label, props.startPos * -1 + lMargin)
        return 'left:' + finalLeft + 'px;'
    }
    log('calcLeft-3', props, label, false)
    return false
}

export const MatchesContentStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    id: 'lookup_matches_content',
    className: 'lmc',
}))<FieldPositionProperties>`
    ${(props) => props.windowWidth && props.startPos && (props.windowWidth - props.startPos > 500 ? false : 'position: relative;')};

    ${(props) => calcWidth(props, 'MatchesContentStyled')}
    ${(props) => calcLeft(props, 'MatchesContentStyled')}


    max-height: 400px;
    display: block;
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: auto;
    ${ScrollbarColouring}
`

export const NoMatchesContentStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    id: 'lookup_no_matches_content',
}))<FieldPositionProperties>`
    ${(props) => props.windowWidth && props.startPos && (props.windowWidth - props.startPos > 500 ? false : 'position: relative;')};
    ${(props) => calcWidth(props)}
    ${(props) => calcLeft(props)}
`

export const ComboWrapperStyled = styled.div.attrs((props: FieldPositionProperties) => ({
    id: 'lookup_matches_cw',
}))<FieldPositionProperties>`
    border: 1px solid rgba(168, 148, 99, 0.4);

    background-color: #202931;
    border-radius: 5px;
    ${(props) => calcWidth(props, 'ComboWrapperStyled')}
    ${(props) => calcLeft(props, 'ComboWrapperStyled')}
        padding-right: 2px;
`

export const MatchesStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    id: props.id,
}))<LookupDataItemProps>`
    border: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    background-color: ${(props) => props.theme.table.panelBgColor};
    z-index: 2;
    position: absolute;
    margin-top: -8px;
    width: calc(100% - 35px);
    display: contents;

    ${ScrollbarColouring}
`

// MK 10/aug/2023 text input filter lookup
//relative, absolute, fixed, or sticky
export const MatchesStyledAsTextFilter = styled.div.attrs((props: LookupDataItemProps) => ({
    id: props.id,
}))<LookupDataItemProps>`
    border: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    background-color: ${(props) => props.theme.table.panelBgColor};
    overflow-y: auto;
    max-height: 150px !important;

    ${ScrollbarColouring}
`

export const SelectedFormValueStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'd-flex justify-content-between align-items-center',
}))<LookupDataItemProps>`
    // need to in line with option/types
    border: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    color: ${(props) => props.theme.table.titleColor} !important;
    background-color: ${(props) => props.theme.table.titleBgColor} !important;
    //border: 1px solid #dee2e6;
    //color: var(--muted) !important;
    border-radius: 3px;
    margin-top: 3px;

    padding: 5px 5px;
    // background-color: ${(props) => props.theme.table.panelBgColor};
    font-size: ${(props) => (props.isMulti ? '13px' : 'inherit')};
    width: 100%;
`

// MK 11/08/2023 input text filter
export const SelectedFormValueTextFilterWrapperStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'd-flex justify-content-between align-items-center',
}))<LookupDataItemProps>`
    // need to in line with option/types
    border: 1px solid ${(props) => props.theme.colors.accentColorMuted};
    color: ${(props) => props.theme.table.columnColor} !important;
    //border: 1px solid #dee2e6;
    //color: var(--muted) !important;
    border-radius: 3px;
    margin-top: 3px;
    padding: 5px 5px;
    // background-color: ${(props) => props.theme.table.panelBgColor};
    font-size: ${(props) => (props.isMulti ? '13px' : 'inherit')};
    //width: fit-content;
`

// MK 11/08/2023 input text filter
export const SelectedFormValueTextFilterStyled = styled.div.attrs((props: LookupDataItemProps) => ({}))<LookupDataItemProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const SelectedFormValueWrapperStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: props.className,
}))<LookupDataItemProps>``

export const SelectedFormValueWrapperContentStyled = styled.div.attrs((props: LookupDataItemProps) => ({}))<LookupDataItemProps>`
    padding: 0 5px;
`

export const SpacerStyled = styled.div.attrs((props: LookupDataItemProps) => ({}))<LookupDataItemProps>`
    margin: 0 auto;
    font-size: 13px;
`

export const ResetSearchStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    onClick: props.onClick,
}))<LookupDataItemProps>`
    margin-top: 3px;
    padding: 5px 5px;
    background-color: ${(props) => props.theme.table.panelBgColor};
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: 13px;
`

// MK 26/09/2023
export const ResetStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.colors.warning} !important;
`

export const ResetSearchWrapperStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'd-flex justify-content-end',
}))<LookupDataItemProps>``

interface LookupTableFieldProperties {
    hasLink?: boolean | undefined
}

export const LookupTableFieldStyled = styled.div.attrs((props: LookupTableFieldProperties) => ({}))<LookupTableFieldProperties>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};

    ${(props) => props.hasLink && ':hover {cursor:pointer;text-decoration: underline;}'}
`

export const LookupInputStyled = styled.input.attrs((props: LookupDataItemProps) => ({
    className: inputClass('border-0  ps-0 lookup'),
}))<LookupDataItemProps>`
    outline: none !important;
    box-shadow: none !important;
`

export const LookupInputTextFilterStyled = styled.input.attrs((props: LookupDataItemProps) => ({
    className: ' ps-0 lookup  bg-transparent border-0 ',
}))<LookupDataItemProps>`
    outline: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid transparent;

    :active {
        outline: none !important;
        box-shadow: none !important;
    }
`

export const SelectAllWrapperStyled = styled.div.attrs((props: LookupDataItemProps) => ({
    className: 'p-2 text-end',
    role: 'button',
}))<LookupDataItemProps>`
    span {
        color: ${(props) => props.theme.colors.dark[1]};
        font-size: 13px;
    }
`

export const CloseButtonWrapperStyled = styled.div.attrs((props) => ({}))`
    height: 30px;
`

export const CloseButtonIconStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-xmark me-1 ',
    role: 'button',
}))`
    display: block;
    vertical-align: middle; //position: relative;
`

export const CloseButtonStyled = styled.div.attrs((props) => ({}))`
    position: absolute;
    right: 0;
    top: -2px;
    margin: 5px 2px auto;
    text-align: center;
    color: ${(props) => props.theme.table.panelBgColor} !important;
    background-color: ${(props) => props.theme.table.columnColor} !important;

    padding-left: 4px;
    padding-top: 4px;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    vertical-align: middle;
`

export const LookupTableStyled = styled.table.attrs((props: TableStyledProps) => ({
    className: 'table table-striped',
}))<TableStyledProps>``

export const LookupTableHeadStyled = styled.thead.attrs((props: TableStyledProps) => ({}))<TableStyledProps>`
        position: sticky;
        top: -2px;
        z-index: 10;
        background-color: ${(props) => props.theme.table.panelBgColor};
    }
    `

export const LookupTableRowStyled = styled.tr.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>`
    :hover {
        background-color: ${(props) => props.theme.table.rowHoverBgColor};
    }

    ${displayTable};
    color: ${(props) => props.theme.table.columnColor};
    background-color: ${(props) => (props.active ? props.theme.table.rowHoverBgColor : 'inherit')};
    font-size: ${(props) => props.theme.table.columnFontSize};

    :nth-of-type(odd) > * {
        color: ${(props) => props.theme.table.columnColor} !important;
        background-color: ${(props) => props.theme.navigation.dropdownMenu.bgColor} !important;
        font-size: ${(props) => props.theme.table.columnFontSize};
    }

    @media all and (max-width: 768px) {
        line-height: 30px;
        span {
            white-space: break-spaces !important;
        }

        vertical-align: top;
    }
    @media only screen and (max-device-width: 148px) {
        line-height: 30px;
        span {
            white-space: break-spaces !important;
        }

        vertical-align: top;
    }
`

export const LookupHeaderColumnStyled = styled.th.attrs((props: TdProperties) => ({}))<TdProperties>`
    color: ${(props) => props.theme.table.columnColor};
    font-size: ${(props) => props.theme.table.columnFontSize};
    word-break: break-all;
    font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
    text-align: ${(props) => getTextAlign(props.textAlign)};
    vertical-align: top;
`

export const LookupColumnStyled = styled.td.attrs((props: TdProperties) => ({}))<TdProperties>`
    color: ${(props) => props.theme.table.columnColor};
    font-size: ${(props) => props.theme.table.columnFontSize};
    // min-width: ${(props) => (typeof props.cellWidth !== 'undefined' ? props.cellWidth : defaultCellWidth + 'px')};
    // max-width: ${(props) => (typeof props.cellWidth !== 'undefined' ? props.cellWidth : defaultCellWidth + 'px')};
    //width:100%;

    word-break: break-all;
    font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};

    text-align: ${(props) => getTextAlign(props.textAlign)};

    vertical-align: top;
    // border: ${(props) => props.theme.table.gridBorder};
`
export const LookupTableBodyStyled = styled.tbody.attrs((props: TableStyledProps) => ({
    className: props.className,
}))<TableStyledProps>`
    position: sticky;
    left: 0;
    top: 40px;
    // display: ${(props) => (props.isOverFlown ? 'inline-' : '')}block;
    // overflow-y: auto;
    // overflow-x: hidden;
    // min-height: auto;
    // max-height: ${(props) => (props.tBodyHeight ? props.tBodyHeight + 'px' : '500px')};
    // height: ${(props) => (props.tBodyHeight ? props.tBodyHeight + 'px' : '500px')};

    // ${flipX};
    width: 100%;

    ${ScrollbarColouring}
`
export const LookupTableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id,
}))<TableStyledProps>`
    display: block;
    max-height: 155px;
    overflow-y: auto;
    overflow-x: auto;

    ${ScrollbarColouring}
`

export const LookupValueStyled = styled.div.attrs((props) => ({}))`
    padding-left: 0.75em;
    color: ${(props) => props.theme.table.columnColor} !important ;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

interface TableTitleProperties extends ThemeProps<any> {}

export const LookupTableContainerStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>``
