import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks'
import { SessionContext } from '../../../../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../../../../providers/system/SystemContext'
import { cancelAbortController, getErrorMessage } from '../../../../../../../utils/Utils'
import { BuySellOrder, TickerDO } from '../../../../../buy-sell-form/types'
import ActionButton from '../../../../../maintain-table/bootstrap/ActionButton'
import { cancelOrder, createOrder, CreateOrderResponseWrapper, TradingState } from '../../../../tradingSlice'
import { ActionButtonPanelStyled, ActionButtonsStyled, ActionButtonStyled, DisclaimerStyled, ErrorStyled } from './styled'

export interface renderPlaceOrderButtonsDO {
    code: string
}

export default function RenderPlaceOrderButtons({ code }: renderPlaceOrderButtonsDO) {
    const [className] = useState('RenderPlaceOrderButtons-' + new Date().getTime())
    const sessionContext = useContext(SessionContext)
    const systemContext = useContext(SystemContext)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [abortController, setAbortController] = useState<AbortController | undefined>(undefined)

    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])
    const order: BuySellOrder | undefined = _tradingSlice?.order
    const ticker: TickerDO | undefined = _tradingSlice?.ticker

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    useEffect(() => {
        return () => {
            cancelAbortController(abortController)
        }
    }, [])

    const performOrderPlacement = () => {
        const abortController = new AbortController()
        setAbortController(abortController)

        dispatch(
            createOrder({
                code: code,
                sessionContext: sessionContext,
                abortController: abortController,
            })
        )
            .unwrap()
            .then((reply: CreateOrderResponseWrapper) => {
                navigate('/Order')
            })
            .catch((error: any) => {
                console.log('error ->', error)

                let errorMessage = getErrorMessage(error)
                setErrorMessage(errorMessage)
                console.error('ERR002 Error', errorMessage, error)
            })
    }

    // const placeOrderHandler = async () => {
    //     setPlaceOrderError(false)
    //
    //     if (menuItem?.action?.url) {
    //         sessionContext
    //             .post<any, any>(menuItem?.action?.url, {
    //                 side: order!.getSide(),
    //                 realtickMapper: ticker,
    //                 quantity: order?.getQuantity(),
    //                 orderType: order!.getOrderType(),
    //                 validity: order!.getValidity(),
    //                 limitPrice: order!.getLimitPrice(),
    //                 price: order!.getPrice(),
    //                 concd: order!.getConcd(),
    //                 dma: isDMA(order!),
    //             })
    //             .then(
    //                 () => {
    //                     reset(true, buySellState.orderAction)
    //                     setPlaceOrderError(false)
    //                     navigate('/Order')
    //                 },
    //                 (reason) => {
    //                     setPlaceOrderError(true)
    //                     log.error(className, 'ERR003', reason)
    //                 }
    //             )
    //     }
    // }

    const onCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        e?.stopPropagation()
        dispatch(cancelOrder({ code: code }))
    }

    const onPlaceOrder = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        e?.stopPropagation()
        performOrderPlacement()
        // dispatch(cancelOrder({code: code}))
    }

    return (
        <>
            <ActionButtonPanelStyled
                side={order?.getSide()}
                isMobile={true}
                canShowInfo={_tradingSlice?.canShowInfo || false}
                tickerId={ticker?.id || ''}
            >
                <ActionButtonsStyled>
                    <ActionButtonStyled>
                        <ActionButton
                            label={'Cancel'}
                            action={'warning'}
                            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onCancel(e)}
                            icon={'fa-solid fa-cancel'}
                            extraClass={'xs-small'}
                        />
                    </ActionButtonStyled>
                    {_tradingSlice?.canOrder && (
                        <ActionButtonStyled>
                            <ActionButton
                                label={_tradingSlice?.isDMA || false ? 'Place order' : 'Place care order'}
                                action={'save'}
                                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onPlaceOrder(e)}
                                icon={'fa-solid fa-check'}
                                extraClass={'xs-small'}
                            />
                        </ActionButtonStyled>
                    )}
                </ActionButtonsStyled>
                {_tradingSlice?.canOrder && <DisclaimerStyled>NB: Market price may vary from actual Execution price.</DisclaimerStyled>}
                {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>}
            </ActionButtonPanelStyled>
        </>
    )
}
