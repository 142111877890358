import React from 'react'
import { ChangeFieldStyled } from './styled'

export interface ChangePercDO {
    value: number | undefined
    multiplyBy100?: boolean
    inList?: boolean
}

export default function ChangePerc({ value, multiplyBy100 = false, inList = false }: ChangePercDO) {
    const change: string | undefined = (value || 0) > 0 ? 'up' : (value || 0) < 0 ? 'down' : (value || 0) === 0 ? 'even' : 'muted'
    const colorMap: { [change: string]: string } = {
        up: 'positive',
        down: 'negative',
        even: 'light',
        muted: 'muted',
    }

    const displayValue = value ? (multiplyBy100 ? value * 100 : value).toFixed(2) + '%' : inList ? 'NA' : '---'

    return (
        <ChangeFieldStyled color={colorMap[change]}>
            <i className={`fa-solid fa-caret-${change}`} />
            <span className={'ms-1'}>{displayValue}</span>
        </ChangeFieldStyled>
    )
}
