import styled from 'styled-components'

export const RowStyled = styled.div.attrs((props) => ({
    className: 'row g-0 mb-3',
}))``

export const Col6Styled = styled.div.attrs((props) => ({
    className: 'col-6 ',
}))``

export const SymbolStyled = styled.h3.attrs((props) => ({
    className: '',
}))`
    color: ${(props) => props.theme.colors.light};
    font-weight: bold;
    font-size: 16px;
`
