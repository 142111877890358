import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks'
import { TradingState, updateOrderType } from '../../../../tradingSlice'
import { ButtonCollectionStyled, ButtonGroupStyled, ButtonStyled } from './styled'

interface MarketLimitProperties {
    code: string
}

export const MarketLimitButtons = ({ code }: MarketLimitProperties): React.ReactElement<MarketLimitProperties> => {
    const dispatch = useAppDispatch()
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])

    const onOrderType = (e: React.MouseEvent<HTMLElement, MouseEvent>, orderType: 'MARKET' | 'LIMIT'): void => {
        e?.stopPropagation()
        dispatch(updateOrderType({ code: code, orderType: orderType }))
    }

    return (
        <ButtonCollectionStyled>
            <ButtonGroupStyled>
                <ButtonStyled
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, 'MARKET')}
                    active={_tradingSlice?.isMarket}
                >
                    Market
                </ButtonStyled>
                <ButtonStyled
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, 'LIMIT')}
                    active={!_tradingSlice?.isMarket}
                >
                    Limit
                </ButtonStyled>
            </ButtonGroupStyled>
        </ButtonCollectionStyled>
    )
}
