import { animated, useSpring } from '@react-spring/web'
import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Subscription } from 'rxjs'
import {
    BackIconStyled,
    HeaderStyled,
    SlidingPanelBodyStyled,
} from '../../components/bespoke/sports-betting/landing-page/sliding-panel/styled'
import ActionDialog from '../../components/common/action-dialog/ActionDialog'
import { ModalBodyStyled, ModalHeaderStyled } from '../../components/common/card/styled'
import { LegalPage } from '../../components/common/legal-page/LegalPage'
import GuslModal from '../../components/ui/modal/gusl-modal'
import { OffCanvasBodyStyled, OffCanvasContentStyled } from '../../components/ui/navigation/navbar/styled'
import { AppSeal } from '../../layouts/mercury-layout/app-seal/AppSeal'
import { Legals } from '../../layouts/mercury-layout/legals/Legals'
import {
    AppSealWrapperStyled,
    FooterTextStyled,
    ForgottenDetailsStyled,
    LegalsWrapperStyled,
    LoginOffCanvasStyled,
    LoginSlidingPanelHeaderStyled,
    LogoStyled,
    MainContainer,
    WarningMessageContainerStyled,
    WarningMessageTitleStyled,
} from '../../layouts/mobile-layout/styled'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { AppSealDTO, AuthType, LegalDTO, LegalsDTO } from '../../providers/environment/types'
import { CONTENT_START_POSITION } from '../../providers/theme/GuslThemeProvider'
import { RunOnceEffect, unSubscribe } from '../../utils/Utils'
import { Login } from './Login'
import { LoginPanelStyled, LoginWrapperStyled } from './styled'

interface LoginPageProperties {
    noModal?: boolean
}

export const LoginPage = (props: LoginPageProperties): React.ReactElement<LoginPageProperties> => {
    const environmentContext = React.useContext(EnvironmentContext)

    const [showForgottenDetail, setShowForgottenDetail] = useState<boolean>(false)
    const [cardOpened, setCardOpened] = useState<boolean>(false)
    const [cardTitle, setCardTitle] = useState<string>('')
    const [legal, setLegal] = useState<LegalDTO | undefined>(undefined)
    const isMobile = environmentContext.isMobileDevice()
    const [contentWidth, setContentWidth] = useState<number>(window.innerWidth)

    const [oldResize, setOldResize] = useState<number | undefined>(undefined)
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    const [notRegisteredEmail] = useState<string | undefined>(environmentService.getEnvironment()?.loginUiProperties?.notRegisteredEmail)

    const [isDisplayHelp, setIsDisplayHelp] = React.useState<boolean>(false)
    const contentProps = useSpring({
        to: {
            opacity: isDisplayHelp ? 1 : 0,
            marginTop: isDisplayHelp ? 0 : -10,
        },
    })
    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    const contentElement = useRef(null)
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION)

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [contentElement])

    useEffect(() => {
        const subscriptionResize = environmentContext.watchResize().subscribe((value: number) => {
            if (!oldResize || oldResize !== value) {
                setOldResize(value)
                setRefreshCounter(refreshCounter + 1)
                setContentWidth(value)
            }
        })
        return () => {
            unSubscribe(subscriptionResize)
        }
    }, [])

    const renderForgottenDetailsPanel = (): React.ReactElement => {
        return (
            <>
                {showForgottenDetail && environmentService.getEnvironment()?.forgottenDetails && (
                    <ActionDialog
                        action={environmentService.getEnvironment()?.forgottenDetails}
                        cannotClose={true}
                        closeDialog={() => {
                            setShowForgottenDetail(false)
                        }}
                    />
                )}
            </>
        )
    }

    const renderFooterText = (): React.ReactElement => {
        if (environmentService.getEnvironment()?.loginUiProperties?.loginFooterText) {
            return (
                <FooterTextStyled
                    dangerouslySetInnerHTML={{
                        __html: environmentService.getEnvironment()?.loginUiProperties?.loginFooterText || '',
                    }}
                />
            )
        }
        return <></>
    }

    const renderSignup = (): React.ReactElement => {
        if (
            !environmentService.getEnvironment()?.authType ||
            environmentService.getEnvironment()?.authType === AuthType.MSAL ||
            environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO
        ) {
            return <></>
        }
        return (
            <ForgottenDetailsStyled capitalise={false}>
                {!environmentService.getEnvironment()?.loginUiProperties?.hideNotRegistered && (
                    <h1 onClick={() => setIsDisplayHelp((showDisplay) => !showDisplay)}>Not registered? Join now</h1>
                )}
            </ForgottenDetailsStyled>
        )
    }

    const renderForgottenDetails = (): React.ReactElement => {
        if (
            !environmentService.getEnvironment()?.authType ||
            environmentService.getEnvironment()?.authType === AuthType.MSAL ||
            environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO
        ) {
            return <></>
        } else {
            return (
                <ForgottenDetailsStyled capitalise={environmentService.getEnvironment()?.loginUiProperties?.noModal}>
                    <h1
                        onClick={() =>
                            environmentService.getEnvironment()?.forgottenDetails
                                ? setShowForgottenDetail(true)
                                : setIsDisplayHelp((showDisplay) => !showDisplay)
                        }
                    >
                        Forgot your password?
                    </h1>
                    {!environmentService.getEnvironment()?.loginUiProperties?.hideNotRegistered && (
                        <h1 onClick={() => setIsDisplayHelp((showDisplay) => !showDisplay)}>Not registered? Join now</h1>
                    )}
                </ForgottenDetailsStyled>
            )
        }
    }

    const renderCardBody = (): React.ReactElement => {
        if (legal) {
            return <LegalPage code={legal.code} noTitle={true} />
        }
        return <p>Card Body</p>
    }

    const onOffCanvassClose = () => {
        setCardOpened(false)
    }

    const renderOffCanvas = (): React.ReactElement => {
        return (
            <div>
                <LoginSlidingPanelHeaderStyled width={contentWidth}>
                    <BackIconStyled>
                        <i className="fas fa-chevron-left" onClick={() => onOffCanvassClose()} />
                    </BackIconStyled>
                    <HeaderStyled width={contentWidth}>{cardTitle}</HeaderStyled>
                </LoginSlidingPanelHeaderStyled>
                <SlidingPanelBodyStyled
                    ref={contentElement}
                    startPos={startPos}
                    isMobile={isMobile}
                    footerHeight={footerHeight}
                    width={contentWidth}
                    noFooter={true}
                    id={'auth_sliding_panel_body'}
                >
                    <OffCanvasContentStyled>{cardOpened && renderCardBody()}</OffCanvasContentStyled>
                </SlidingPanelBodyStyled>
            </div>
        )
    }

    const renderCard = (): React.ReactElement => {
        return (
            <>
                {isMobile && (
                    <LoginOffCanvasStyled show={cardOpened} ismobile={isMobile ? 'true' : 'false'} placement={'end'}>
                        <OffCanvasBodyStyled id={'offcanvas_body'}>{renderOffCanvas()}</OffCanvasBodyStyled>
                    </LoginOffCanvasStyled>
                )}
                {!isMobile && cardOpened && (
                    <Modal show={cardOpened} centered={true} onHide={() => onOffCanvassClose()} size={'lg'} scrollable>
                        <ModalHeaderStyled closeButton>{cardTitle} </ModalHeaderStyled>

                        <ModalBodyStyled>{renderCardBody()}</ModalBodyStyled>
                    </Modal>
                )}
            </>
        )
    }
    const renderLegalsAndAppSeal = (): React.ReactElement => {
        const legals: LegalsDTO | undefined = environmentService.getEnvironment()?.legals
        const appSeal: AppSealDTO | undefined = environmentService.getEnvironment()?.appSeal

        const onLegalClick = (legal: LegalDTO) => {
            if (legal) {
                console.log('-- legal clicked', legal)
                setCardTitle(legal.label)
                setLegal(legal)
                setCardOpened(true)
            }
        }

        if (!legals && !appSeal) {
            return <></>
        }

        if (legals && appSeal) {
            legals.horizontalLayout = false
            legals.label = 'Policies'
            return (
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <LegalsWrapperStyled id={'legal_wrapper'}>
                                    <Legals {...legals} onLegalClick={onLegalClick} />
                                </LegalsWrapperStyled>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                                <AppSealWrapperStyled>
                                    <AppSeal {...appSeal} noText={true} />
                                </AppSealWrapperStyled>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <AppSealWrapperStyled>
                                    <AppSeal {...appSeal} noImage={true} />
                                </AppSealWrapperStyled>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }
        if (appSeal) {
            return (
                <AppSealWrapperStyled>
                    <AppSeal {...appSeal} />
                </AppSealWrapperStyled>
            )
        }
        if (legals) {
            legals.horizontalLayout = false
            legals.label = 'Policies'
            return (
                <LegalsWrapperStyled id={'legal_wrapper'}>
                    <Legals {...legals} onLegalClick={onLegalClick} />
                </LegalsWrapperStyled>
            )
        }
        return <></>
    }

    const renderLoginBody = (): React.ReactElement => {
        return (
            <>
                <MainContainer $extraClassName={'login'} id={'main_container'} coverUrl={environmentService.getEnvironment()?.coverUrl}>
                    <LogoStyled src={environmentService.getEnvironment()?.loginUiProperties?.loginLogoUrl} alt="Logo" />
                    <Login />
                    {/*{renderForgottenDetails()}*/}
                    {renderSignup()}
                    {renderFooterText()}
                    {isDisplayHelp && (
                        // @ts-ignore
                        <animated.div style={contentProps}>
                            <WarningMessageContainerStyled>
                                <WarningMessageTitleStyled>
                                    {environmentService.getEnvironment()?.loginUiProperties?.footerTitle}
                                </WarningMessageTitleStyled>
                                <p>
                                    {environmentService.getEnvironment()?.loginUiProperties?.footerText}
                                    <br />
                                    {notRegisteredEmail && <a href={'mailto:' + notRegisteredEmail}>{notRegisteredEmail}</a>}
                                    <br />
                                </p>
                            </WarningMessageContainerStyled>
                        </animated.div>
                    )}
                </MainContainer>
                {renderLegalsAndAppSeal()}
            </>
        )
    }

    const renderLoginWrapper = (): React.ReactElement => {
        return <LoginWrapperStyled>{renderLoginBody()}</LoginWrapperStyled>
    }

    if (environmentService.getEnvironment() && props.noModal) {
        return <LoginPanelStyled>{renderLoginWrapper()}</LoginPanelStyled>
    } else {
        return (
            <>
                {environmentService.getEnvironment() && (
                    <GuslModal dismissible={false} extraClassName="login">
                        {renderLoginBody()}
                    </GuslModal>
                )}
                {renderForgottenDetailsPanel()}
                {renderCard()}
            </>
        )
    }
}
