import React, { useContext, useEffect, useId, useRef, useState } from 'react'
import { Subscription } from 'rxjs'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getSettings, validate } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { ErrorMessageStyled } from '../../styled'
import { FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { RowsPerPageContainerStyled, RowsPerPageTableResponsiveStyled } from '../gusl-table/styled/table/styled'
import { LookupColumnStyled, LookupTableBodyStyled, LookupTableRowStyled, LookupTableStyled } from '../lookup/styled'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { RowsPerContentStyled } from '../maintain-table/styled'
import { Option } from '../option/OptionField'
import { OptionComboStyled, OptionTableContainerStyled } from '../option/styled'
import { RadioButtonContainerStyled, RadioButtonIconStyled, RadioButtonLabelStyled } from '../risk-gain/styled'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, InlineInputStyled, InputStyled } from '../text/styled'
import { OddsStyled, OddsTypeLookupStyled, OddsTypeWrapper, OddsValueWrapper, OddsWrapper } from './styled'
import { OddsDTO } from './types'

const ODDS_TYPES = ['D', 'US']

export const OddsField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const uniqueId = useId() // properties?.uniqueId ||

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const blastContext = useContext(BlastContext)
    const sessionContext = React.useContext(SessionContext)

    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<OddsDTO | undefined>(_guslFormState?.getFieldValue(properties))
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const valueRef = useRef(properties?.data)
    const [oddsTypeOpen, setOddsTypeOpen] = useState<boolean>(false)

    const onOddsTypeChange = (option: Option) => {
        setOddsType(option)
        setFormValue({ type: option.value, value: formValue?.value || 0 })
        properties.onChange(properties.fieldConfig.name, {
            type: option.value,
            value: formValue?.value || 0,
        })
        setOddsTypeOpen(false)
    }

    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    useEffect(() => {
        // console.log(`subscribe fld: ${properties.fieldConfig.name} bdc: ${properties.fieldConfig.blastDeltaCommand} kv: ${properties.fieldConfig.keyValue}  kf:${properties.fieldConfig.keyField} `)
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            // newValue could be just money or object with field
            if (newValue) {
                // console.log('-- new value', newValue)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    const extraFieldProperties = extractExtraFieldProperties(properties)

    const [displayOddsType] = useState<boolean>(extraFieldProperties?.showOddsType || false)

    const [oddsTypes] = useState<Option[]>(() => {
        const options: Option[] = []
        ODDS_TYPES.forEach((oddsType) => {
            options.push({ value: oddsType + '', label: oddsType + ': ' })
        })
        return options
    })

    const [oddsType, setOddsType] = useState<Option>(() => {
        if (properties?.data?.type) {
            let found: Option | undefined

            oddsTypes.filter((option) => option.value === properties?.data?.type).forEach((option) => (found = option))

            if (found) {
                return found
            } else {
                return {
                    value: ODDS_TYPES[0] + '',
                    label: ODDS_TYPES[0] + ': ',
                }
            }
        } else {
            return {
                value: ODDS_TYPES[0] + '',
                label: ODDS_TYPES[0] + ': ',
            }
        }
    })

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data)
        // dispatch(initForm({code: uniqueId, formValue: properties?.data}))
    }

    useEffect(() => {
        setFormValue(properties?.data)
        // dispatch(initForm({code: uniqueId, formValue: properties?.data}))
    }, [properties])

    // useEffect(() => {
    //     dispatch(initForm({code: uniqueId, formValue: properties?.data}))
    // }, [uniqueId]);

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        const message = validate(
            properties.menuItem?.code,
            properties.fieldConfig.name,
            properties.fieldConfig.validators,
            'checkbox',
            fieldValue
        )
        if (message) {
            setErrorMessage(message)
            return false
        }
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss)

        // const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss,
        //     properties.isTableView ? 'textAlign:right;wordWrap:break-word;' : 'textAlign:left;')

        if (!style['textAlign']) {
            if (properties.isTableView) {
                style['textAlign'] = 'right'
                style['wordWrap'] = 'break-word'
                style['paddingRight'] = '10px'
            } else {
                style['textAlign'] = 'left'
            }
        }

        let displayOddsValue: string = '' + (formValue?.value || '')
        if (extraFieldProperties.withSign) {
            if ((formValue?.value || 0) > 0) {
                displayOddsValue = '+' + displayOddsValue
            }
        }
        let displayOddsType = extraFieldProperties?.showOddsType ? (formValue?.type || '') + ' ' : ''

        return (
            <OddsStyled
                colorise={extraFieldProperties.colorise || false}
                style={style}
                odds={formValue}
            >{`${displayOddsType}${displayOddsValue}`}</OddsStyled>
        )
    }

    // const renderFormView = (): React.ReactElement => {
    //     /* eslint-disable @typescript-eslint/no-unused-vars */
    //     const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
    //     return (
    //         <>
    //             {!hideField && <>
    //                 <FloatingFormStyled>
    //                     <div className={inputClass() + ((submitted && !formValue) ? 'yellow' : '')}>
    //                         {formMode === FormMode.VIEW && renderTableView()}
    //                     </div>
    //                     <FieldLabel properties={properties}/>
    //                     {submitted && errorMessage &&
    //                         <small className="yellow">{errorMessage}</small>}
    //                 </FloatingFormStyled>
    //             </>}
    //         </>
    //     )
    // }

    const renderOddsType = (option: Option, idx: number) => {
        return (
            <LookupTableRowStyled
                role={'button'}
                key={'id_' + idx}
                id={'id_' + idx}
                active={false}
                lookupField={true}
                onClick={() => onOddsTypeChange(option)}
            >
                <LookupColumnStyled textAlign={'right'} key={'hdr_row_' + idx}>
                    {option.label}
                </LookupColumnStyled>
            </LookupTableRowStyled>
        )
    }

    const renderOddsTypes = () => {
        return <>{oddsTypes && oddsTypes.map((option, idx) => renderOddsType(option, idx))}</>
    }
    const renderOddsTypeLookup = (): React.ReactElement => {
        return (
            <OddsTypeLookupStyled>
                <OptionTableContainerStyled>
                    <RowsPerContentStyled footerHeight={footerHeight}>
                        <RowsPerPageTableResponsiveStyled>
                            <LookupTableStyled>
                                <LookupTableBodyStyled id={'lk_rowsperpage'} isOverFlown={false}>
                                    {renderOddsTypes()}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </RowsPerPageTableResponsiveStyled>
                    </RowsPerContentStyled>
                </OptionTableContainerStyled>
            </OddsTypeLookupStyled>
        )
    }

    const renderOddsTypeAsComboBox = (): React.ReactElement => {
        return (
            <>
                <RowsPerPageContainerStyled isMobile={false} onClick={() => setOddsTypeOpen(!oddsTypeOpen)}>
                    {/*{!isMobile && <div className={''}>{translateService.getKey('table.pagination.rowsPerPage')}</div>}*/}
                    <OptionComboStyled>
                        <i className={'fa-solid fa-caret-down me-1'}></i> {oddsType.label}
                    </OptionComboStyled>
                </RowsPerPageContainerStyled>
                {oddsTypeOpen && renderOddsTypeLookup()}
            </>
        )
    }
    const onRadioClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, type: string) => {
        onOddsTypeChange({ value: type + '', label: type + ': ' })
    }
    const renderRadioButton = (label: string, type: string): React.ReactElement => {
        return (
            <RadioButtonContainerStyled>
                <RadioButtonIconStyled
                    active={oddsType.value === type}
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onRadioClick(e, type)}
                />
                <RadioButtonLabelStyled onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onRadioClick(e, type)}>
                    {label}
                </RadioButtonLabelStyled>
            </RadioButtonContainerStyled>
        )
    }

    const renderOddsAsRadioButton = (): React.ReactElement => {
        return (
            <RadioButtonContainerStyled>
                {renderRadioButton('US', 'US')}
                {renderRadioButton('Decimal', 'D')}
            </RadioButtonContainerStyled>
        )
    }

    const isNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
            event.preventDefault()
        }
    }

    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {
            try {
                const number = parseFloat(value)
                if (!isNaN(number)) {
                    setFormValue({ type: oddsType.value, value: number })
                    properties.onChange(properties.fieldConfig.name, {
                        type: oddsType.value,
                        value: number,
                    })
                }
            } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
            }
        }
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (!hideField && properties.inline) {
            return (
                <OddsWrapper>
                    <OddsTypeWrapper>
                        {/*{renderOddsTypeAsComboBox()}*/}
                        {renderOddsAsRadioButton()}
                    </OddsTypeWrapper>
                    <OddsValueWrapper>
                        <InlineInputStyled
                            type={'number'}
                            id={properties?.fieldConfig?.name || 'def'}
                            ref={valueRef}
                            defaultValue={formValue?.value}
                            onChange={(e) => onDataInputChange(e.target.value)}
                            readOnly={disableField}
                            disabled={disableField}
                            required={required}
                            submitted={submitted}
                            noValue={!formValue}
                            placeholder={properties?.fieldConfig?.placeholder || ''}
                            onKeyDown={(event) => isNumber(event)}
                        />
                    </OddsValueWrapper>
                </OddsWrapper>
            )
        }

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled formMode={formMode}>
                                    <OddsTypeWrapper>
                                        {/*{renderOddsTypeAsComboBox()}*/}
                                        {renderOddsAsRadioButton()}
                                    </OddsTypeWrapper>
                                    <InputStyled
                                        type={'number'}
                                        id={properties?.fieldConfig?.name || 'def'}
                                        ref={valueRef}
                                        defaultValue={formValue?.value}
                                        onChange={(e) => onDataInputChange(e.target.value)}
                                        readOnly={disableField}
                                        disabled={disableField}
                                        required={required}
                                        submitted={submitted}
                                        noValue={!formValue}
                                    />
                                </FieldContentStyled>
                                {submitted && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}

// const getNumberOfDecimalPlaces = (odds: OddsDTO): number => {
//     if (isDefined(odds) && isDefined(odds.type)) {
//         if (odds.type === 'US') {
//             return 0;
//         } else if (odds.type === 'D') {
//             return 2;
//         } else if (odds.type === 'P') {
//             return 5;
//         } else if (odds.type === 'HK') {
//             return 5;
//         } else if (odds.type === 'M') {
//             return 5;
//         } else if (odds.type === 'I') {
//             return 0;
//         } else {
//             return 0
//         }
//     } else {
//         return 0;
//     }
//
// }

// const areEqual = (a: OddsDTO, b: OddsDTO): boolean => {
//     if ((a && !b) || (!a && b) || (!a.type && b.type) || (a.type && !b.type)) {
//         return false;
//     }
//     return a.type === b.type && a.value === b.value;
// }
//
//
// const whatDecimalSeparator = (): string => {
//     const n = 1.1;
//     // @ts-ignore
//     return /^1(.+)1$/.exec(n.toLocaleString())[1];
// }

// const countDecimals = (odds: OddsDTO): number => {
//     if (notDefined(odds.value)) {
//         return 0;
//     }
//     if ((odds.value % 1) !== 0) {
//         const splits = odds.value.toString().split(whatDecimalSeparator());
//         if (splits && splits.length === 2) {
//             return splits[1].length;
//         } else {
//             return 0;
//         }
//     }
//     return 0;
// }
