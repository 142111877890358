import React, { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { SystemContext } from '../../../providers/system/SystemContext'
import { getTableControls, splitPath } from '../../../utils/Utils'
import {
    ActionConfigDTO,
    ConditionalRowDetailsDTO,
    FieldConfigDTO,
    IMenuDTO,
    OrderByActionDO,
    OrderByDTO,
    PanelConfigDTO,
    SummaryDO,
    TableRowDTO,
    WidgetPanelProperties,
} from '../../types'
import ColumnsSettings from './columns-settings/ColumnsSettings'
import Filters from './filters/Filters'
import TopFilters from './filters/top-filters/TopFilters'
import { GuslTableState } from './guslTableSlice'
import { ListView } from './ListView'
import TablePagination from './pagination/TablePagination'
import ScrollManager from './ScrollManager'
import { GuslScrollState } from './scrollSlice'
import SplitListView from './split-list-view/SplitListView'
import { GuslTableStyled, TabbedPageMenuBarStyled } from './styled'
import TableControls from './table-controls/TableControls'
import TableHeader from './table-header/TableHeader'

// interface TableState {
//     state: GuslTableState,
//     queryParamsState: GuslTableQueryParamState
// }
//
export type GuslTableProperties = {
    code: string
    fields?: FieldConfigDTO[]
    tableData?: TableRowDTO[] | undefined
    selectUrl?: string | undefined
    label?: string | undefined
    subLabel?: string | undefined
    tableActions?: ActionConfigDTO[] | undefined
    rowActions?: ActionConfigDTO[] | undefined
    groupActions?: ActionConfigDTO[] | undefined

    orderByActions?: OrderByActionDO[] | undefined
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    hideHeaderPanel?: boolean | undefined
    hideHeaderColumns?: boolean | undefined
    filterPanelOpened?: boolean | undefined
    headerSummary?: SummaryDO | undefined
    footerSummary?: SummaryDO | undefined
    conditionalRowDetails?: ConditionalRowDetailsDTO[] | undefined
    blastDeltaCommand?: string
    highlightDelta?: boolean
    disableTableControls?: boolean
    widgetPanelProperties?: WidgetPanelProperties

    /**
     * If true cannot resize columns
     */
    disableColumnResize?: boolean

    /**
     * If present, row is clickable with 'code' as value of firstTab
     */
    firstTab?: string

    /**
     * params e.g. :id passed in location.pathname
     */
    pathParams?: any

    /**
     * listView refreshRate
     * number in milliseconds or false for no refreshing
     * */

    refreshRate?: number | boolean

    isInSplit?: boolean | undefined
    createConfig?: IMenuDTO | undefined

    /**
     * table height
     * needed for floating/sticky header to work
     *
     * calculated value
     * */
    height?: number

    /**
     * Table uses a blast collection
     */
    collectionName?: string | undefined

    headerUrl?: string

    /**
     * defines initial sort order
     */
    initialOrderBy?: OrderByDTO

    isTabChild?: boolean

    /**
     * header panels
     */
    headerPanels?: PanelConfigDTO[]

    fixedHeight?: number

    /**
     * is the table cascading
     */
    isCascadingTable?: boolean

    /**
     * for cascading table, the associated parent data
     */
    parentData?: any
}

export const GuslTable = ({
    code,
    label,
    fields,
    selectUrl,
    tableData,
    tableActions,
    rowActions,
    groupActions,
    isSummaryHeader,
    isSummaryFooter,
    hideHeaderPanel,
    hideHeaderColumns,
    filterPanelOpened,
    headerSummary,
    footerSummary,
    conditionalRowDetails,
    firstTab,
    pathParams,
    refreshRate,
    createConfig,
    isInSplit,
    collectionName,
    headerUrl,
    blastDeltaCommand,
    highlightDelta,
    disableTableControls,
    disableColumnResize,
    subLabel,
    initialOrderBy,
    isTabChild,
    orderByActions,
    widgetPanelProperties,
    headerPanels,
    fixedHeight,
    isCascadingTable,
    parentData,
}: GuslTableProperties): React.ReactElement => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('GuslTable-' + new Date().getTime())
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    // MK 29/08/2023
    const scrollState: GuslScrollState = useAppSelector((state) => state.scrollSlice[code])
    const scrollLeftPosition: number = scrollState?.scrollLeftPosition
    // MK 11/08/2023
    const showTopTableFilters = state?.showTopFilters

    const [entityCode, id, tabCode] = splitPath(window.location.pathname)

    // MK 18/09/2023
    const [hasAdvancedFilters, setHasAdvancedFilters] = useState<boolean>(false)
    useEffect(() => {
        const adv = (fields || []).filter((fld) => fld.advancedFilter)
        setHasAdvancedFilters(adv.length > 0)
    }, [code])

    const sidePanelOpen = state?.showFilters || state?.showColumnsSettings
    const splitScreen: boolean = useAppSelector((state) => state.uiSlice.splitScreen)
    const divClassName = () => {
        return (
            ' col-md-' +
            (sidePanelOpen ? '9' : '12') +
            ' col-lg-' +
            (sidePanelOpen ? '9' : '12') +
            ' col-xl-' +
            (sidePanelOpen ? '10' : '12')
        )
    }
    const tBodyHeight = window.innerHeight - 200
    // MK 12/08/2023
    const environmentContext = useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice()
    const [[showPagination, showColumnSettings, showFilter, showRefresh, showResize, showSearch], setTablecontrols] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])

    const [haveTableControls, setHaveTableControls] = useState<boolean>(false)
    useEffect(() => {
        if (state) {
            setTablecontrols(getTableControls(environmentContext.getCurrentMediaType(widgetPanelProperties), state?.tableControl))
            setHaveTableControls(
                !state?.disableTableControls ||
                    showPagination ||
                    showFilter ||
                    showSearch ||
                    showColumnSettings ||
                    (state?.orderByActions || []).length > 0
            )
        }
    }, [state])
    // MK 02-10-2024
    const systemContext = useContext(SystemContext)
    const [menuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(code))

    const renderTable = (): React.ReactElement => {
        return !splitScreen ? (
            <>
                {state && !isMobile && (
                    <>
                        {/*{state?.hasInfinityScroll && <InfiniteScrollManager code={code}/>}*/}
                        <ScrollManager code={code} isIndividual={true} />
                        <ScrollManager code={code} isIndividual={false} />
                    </>
                )}
                <div key={'div_1_' + code} className={'row g-0'} id={'gt_1_' + code}>
                    <div
                        key={'div_2_' + code}
                        className={'col order-last order-md-first ' + divClassName()}
                        id={'gt_' + code + (isSummaryHeader ? '_hdr' : '') + (isSummaryFooter ? '_ftr' : '')}
                    >
                        {!state?.hideHeaderPanel && !isInSplit && (
                            <TableHeader
                                key={'table_header_' + code}
                                code={code}
                                state={state}
                                isSummaryHeader={isSummaryHeader}
                                isSummaryFooter={isSummaryFooter}
                                headerUrl={headerUrl}
                                blastDeltaCommand={blastDeltaCommand}
                                widgetPanelProperties={widgetPanelProperties}
                                headerPanels={headerPanels}
                            />
                        )}
                        {haveTableControls && state && !state.isCascadingTable && (
                            <TableControls
                                key={code + '-tc'}
                                code={code}
                                state={state}
                                isLoading={false}
                                isHeader={isSummaryHeader || false}
                                isFooter={isSummaryFooter || false}
                                haveFilters={state?.haveFilters || false}
                                pagination={
                                    <TablePagination
                                        key={code + '-table-pagination'}
                                        code={code}
                                        widgetPanelProperties={widgetPanelProperties}
                                    />
                                }
                                orderByActions={state?.orderByActions}
                                hasAdvancedFilters={hasAdvancedFilters}
                                widgetPanelProperties={widgetPanelProperties}
                            />
                        )}
                        {isMobile && <TablePagination code={code} widgetPanelProperties={widgetPanelProperties} />}
                        {hasAdvancedFilters && showTopTableFilters && !isMobile && (
                            <TopFilters
                                key={'top_filters_' + code}
                                code={code}
                                data={{ id: id }}
                                widgetPanelProperties={widgetPanelProperties}
                            />
                        )}
                        <ListView
                            code={code}
                            key={code}
                            label={label}
                            fields={fields}
                            selectUrl={selectUrl}
                            tableData={tableData}
                            tableActions={tableActions}
                            rowActions={rowActions}
                            groupActions={groupActions}
                            isSummaryHeader={isSummaryHeader}
                            isSummaryFooter={isSummaryFooter}
                            hideHeaderPanel={hideHeaderPanel}
                            hideHeaderColumns={hideHeaderColumns}
                            filterPanelOpened={filterPanelOpened}
                            headerSummary={headerSummary}
                            footerSummary={footerSummary}
                            conditionalRowDetails={conditionalRowDetails}
                            firstTab={firstTab}
                            pathParams={pathParams}
                            refreshRate={refreshRate}
                            createConfig={createConfig}
                            height={tBodyHeight}
                            headerUrl={headerUrl}
                            blastDeltaCommand={blastDeltaCommand}
                            highlightDelta={highlightDelta}
                            disableTableControls={disableTableControls}
                            disableColumnResize={disableColumnResize}
                            subLabel={subLabel}
                            initialOrderBy={initialOrderBy}
                            isTabChild={isTabChild}
                            orderByActions={orderByActions}
                            widgetPanelProperties={widgetPanelProperties}
                            headerPanels={headerPanels}
                            fixedHeight={fixedHeight}
                            isCascadingTable={isCascadingTable}
                            parentData={parentData}
                        />
                    </div>
                    {sidePanelOpen && !splitScreen && (
                        <TabbedPageMenuBarStyled>
                            {state?.showFilters && <Filters code={code} data={{ id: id }} />}
                            {state?.showColumnsSettings && <ColumnsSettings code={code} />}
                        </TabbedPageMenuBarStyled>
                    )}
                </div>
            </>
        ) : (
            <SplitListView code={code} label={label} fields={fields} selectUrl={selectUrl} />
        )
    }

    return (
        <GuslTableStyled id={'gusl-table-' + code} isSummaryHeader={isSummaryHeader} isSummaryFooter={isSummaryFooter}>
            {renderTable()}
            {/*{!systemReady ? <LoadingSpinner/> : renderTable()}*/}
        </GuslTableStyled>
    )
}
