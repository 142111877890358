import { Dropdown, NavLink } from 'react-bootstrap'
import { DropdownProps } from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import styled, { ThemeProps } from 'styled-components'
import {
    CONTENT_START_POSITION,
    IOS_TOP_STATUS_BAR_HEIGHT,
    MAIN_TOOLBAR_HEIGHT,
    MARGIN_TOP_HDR_TOOLBAR_ITEMS,
    MOBILE_WIDTH,
    RELEASE_DETAILS_FOOTER_HEIGHT,
} from '../../../../providers/theme/GuslThemeProvider'
import { ScrollbarColouring } from '../../../common/gusl-table/styled/table/styled'
import Icon from '../../../common/maintain-table/bootstrap/Icon'
import IconWrapper from '../../IconWrapper'

interface OffcanvasTitleProps extends ThemeProps<any> {
    onClick: Function
}

interface NavLinkProps extends ThemeProps<any> {
    to: string
}

export const NavbarStyled = styled(Navbar).attrs((props) => ({
    variant: 'dark',
    expand: 'lg',
}))`
    background-color: ${(props) => props.theme.navigation.topNavbarBgColor};
    color: ${(props) => props.theme.navigation.topNavbarColor};
    max-height: ${(props) => CONTENT_START_POSITION}px;
`
export const MobileNavbarStyled = styled(Navbar).attrs((props) => ({
    // className: 'row g-0',
    variant: 'dark',
    expand: 'lg',
}))`
    background-color: ${(props) => props.theme.navigation.topNavbarBgColor};
    color: ${(props) => props.theme.navigation.topNavbarColor};
    max-height: ${(props) => MAIN_TOOLBAR_HEIGHT}px;
    display: block;
`

export const GradientLineStyled = styled.div`
    span:before {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 3px;
        border-radius: 2px;
        background: linear-gradient(
            100deg,
            ${(props) => props.theme.navigation.gradient[0]} 25%,
            ${(props) => props.theme.navigation.gradient[1]} 50%
        );
    }
`

interface DropdownStyledProps extends ThemeProps<any>, DropdownProps {
    $isEntered: boolean
    $isMobile?: boolean
}

export const DropdownStyled = styled(Dropdown).attrs((props: DropdownStyledProps) => ({
    autoClose: 'outside',
    className: props.$isMobile ? '' : 'd-flex align-items-center justify-content-center',
    id: 'mg_with_children',
}))<DropdownStyledProps>`
    ${(props) => {
        return props.$isEntered
            ? `
 > button {
    font-weight: bold;
    border: none;
    background-color:  props.theme.navigation.bgColor};
    color:  props.theme.colors.dark[1]};
}
`
            : ``
    }}

    display: block;

    text-decoration: none;
    /*transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;*/
    border: none;
    transition: none !important;
    padding-right: 10px;

    line-height: ${(props) => (props.$isMobile ? '40px' : '30px')};
    color: ${(props) => props.theme.table.menuButtonColor};

    > button {
        color: ${(props) => props.theme.navigation.buttonColor};
        background-color: ${(props) => props.theme.navigation.buttonBgColor};
        border: none;
    }

    > button:hover {
        background-color: ${(props) => props.theme.navigation.hoverButtonBgColor};
        font-weight: bold;
        border: none;
    }
`

interface IconStyledProps extends ThemeProps<any> {
    $isItem: boolean
}

export const MenuImageStyled = styled.img.attrs((props: IconStyledProps) => ({}))<IconStyledProps>`
    width: ${(props) => (props.$isItem ? '24px' : '30px')} !important;
    vertical-align: middle;
`

export const IconStyled = styled(Icon).attrs((props: IconStyledProps) => ({
    className: '',
}))<IconStyledProps>`
    font-size: ${(props) => (props.$isItem ? '12px' : '16px')};
    margin-left: 5px;

    :hover {
        color: ${(props) => props.theme.navigation.hoverButtonColor};
    }

    span {
        font-size: ${(props) => (props.$isItem ? '12px' : '16px')} !important;
    }
`
export const OffcanvasTitleStyled = styled.div.attrs((props: OffcanvasTitleProps) => ({
    onClick: props.onClick,
}))<OffcanvasTitleProps>`
    margin-top: 15px;
`

export const OffCanvasHeaderStyled = styled(Offcanvas.Header).attrs((props) => ({}))``

export const OffCanvasTitleStyled = styled(Offcanvas.Title).attrs((props) => ({}))``

export const OffCanvasBodyStyled = styled(Offcanvas.Body).attrs((props) => ({}))`
    overflow-y: auto;
    padding-left: 0px;
    padding: 0px;

    ${ScrollbarColouring}
`

export const OffCanvasContentStyled = styled.div.attrs((props) => ({
    id: 'offcanvas_content',
}))`
    // if you want padding put it lower down in stack - doing it here shags breadcrumbs
    // padding: 5px;
`

export const DropdownMenuStyled = styled(Dropdown.Menu).attrs((props) => ({}))`
    background-color: ${(props) => props.theme.navigation.dropdownMenu.bgColor};
    border: 2px solid ${(props) => props.theme.navigation.borderColor} !important;
    margin-top: -5px !important;
    width: 220px;

    > button {
        color: var(--muted);
        background-color: var(--widget-blue);
        border: none;
    }

    > button:hover {
        background-color: ${(props) => props.theme.navigation.dropdownMenu.hoverButtonBgColor};
        color: ${(props) => props.theme.navigation.dropdownMenu.hoverButtonColor};
    !important;
        font-weight: bold;
        border: none;
    }

    > .dropdown-item:hover {
        font-weight: bold;
        border-left: 5px solid ${(props) => props.theme.navigation.dropdownMenu.leftBarColor} !important;
    }


`

export const DropdownToggledStyled = styled(Dropdown.Toggle).attrs((props) => ({
    id: 'dropdown-autoclose-outside',
}))`
    ::after {
        display: none !important;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
`

interface DropdownItemStyledProps extends ThemeProps<any> {
    idx: number
}

export const DropdownItemStyled = styled(Dropdown.Item).attrs((props: DropdownItemStyledProps) => ({
    href: '#',
    key: 'c_' + props.idx,
}))`
    color: ${(props) => props.theme.navigation.dropdownMenu.color};

    :hover {
        font-weight: bold;
        border-left: 5px solid ${(props) => props.theme.navigation.dropdownMenu.leftBarColor} !important;
        padding-left: 11px !important;
    }
`
export const LogoLinkStyled = styled(NavLink).attrs((props: NavLinkProps) => ({
    className: 'navbar navbar-brand p-0 m-0 ms-4',
    to: props.to,
}))<NavLinkProps>``
export const LogoImageStyled = styled.img.attrs((props) => ({
    className: 'img-fluid logo-image',
}))`
    // I want actual image size to dictate
    max-width: 150px;
    max-height: 29px;
`
export const MobileLogoImageStyled = styled.img.attrs((props) => ({
    className: 'bg-transparent border-0 mobile-logo-image', // 'img-fluid'
}))`
    //margin-top: -9px !important;
    //width: 54px;
    //height: 54px;
`
export const MobileLogoImageStyledLarge = styled.img.attrs((props) => ({
    className: 'bg-transparent border-0 ', // 'img-fluid'
}))`
    //margin-top:-9px !important;
    //width: 200px;
    //height: 54px;
    max-width: 200px;
`
export const ToolbarMenuStyled = styled(Navbar.Collapse).attrs((props) => ({
    id: 'navbar-dark-example',
    className: 'd-flex justify-content-end ',
}))`
    padding-right: 20px;
`

export const HorizontalMenuStyled = styled(Navbar.Collapse).attrs((props) => ({
    className: 'd-flex justify-content-center',
}))``

export const TickerMenuStyled = styled(Navbar.Collapse).attrs((props) => ({
    className: 'd-flex justify-content-center',
}))``

interface IconWrapperProperties extends ThemeProps<any> {
    $isSideNavOpen: boolean
    extraClass?: string
    angleDirection?: string
    isRight?: boolean
}

export const UserIconStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-user',
    role: 'button',
}))`
    color: ${(props) => props.theme.colors.accentColor} !important;
    padding: 0 10px 0 0;
`

export const HamburgerWrapperStyled = styled.span.attrs((props) => ({
    className: '',
}))`
    color: ${(props) => props.theme.navigation.hamburgerColor} !important;
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    margin-left: 10px;
    padding-right: 5px;
`
export const HamburgerStyled = styled.i.attrs((props: IconWrapperProperties) => ({
    className: !props.$isSideNavOpen ? 'fa-solid fa-bars' : 'fa-solid fa-angles-' + (props.angleDirection ? props.angleDirection : 'left'),
    role: 'button',
}))<IconWrapperProperties>`
    font-size: 30px;
    margin-top: 5px;
`

interface MobileMainHeaderProperties extends ThemeProps<any> {
    isMobile?: boolean
}

/*
good for alvar bad for nova - move into Card
  padding-left: 10px;
  padding-right: 10px;

 */

interface MobileMainHeaderEmbedded {
    ultraNarrowDevice: boolean
}

export const MobileMainHeaderEmbeddedStyled = styled.div.attrs((props: MobileMainHeaderEmbedded) => ({
    className: '',
}))<MobileMainHeaderEmbedded>`
    text-align: center;
    margin: auto;

    ${(props) => props.ultraNarrowDevice && 'font-size:10px !important;'}
    td {
        ${(props) => props.ultraNarrowDevice && 'font-size:10px !important;'}
    }

    div {
        ${(props) => props.ultraNarrowDevice && 'font-size:10px !important;'}
    }
`
export const ThreeDotsStyled = styled(IconWrapper).attrs((props: IconWrapperProperties) => ({
    icon: !props.$isSideNavOpen
        ? 'fa-solid fa-ellipsis-vertical'
        : 'fa-solid fa-angles-' + (props.angleDirection ? props.angleDirection : 'left'),
    extraClass: 'fs-4',
}))<IconWrapperProperties>`
    span {
        color: ${(props) => props.theme.navigation.hamburgerColor} !important;
    }

    icon {
        color: wheat !important;
    }
`

export const MobileMainHeaderStyled = styled.div``

export const MobileHeaderInfoStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.modal.innerBackground};
    color: ${(props) => props.theme.table.columnColor};
    width: 400px;
    border-radius: 7px;
    margin: 0px auto;
    text-align: center;
    padding: 10px 10px 10px 10px;
    border: ${(props) => '1px solid ' + props.theme.modal.outerBackground};
`

export const MobileHeaderItemStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.panel.titleColor};
    overflow: hidden !important;
    max-width: ${(props) => MOBILE_WIDTH + 40}px;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    height: 100%;
`
/*
  margin: 0px auto;
  position: relative;
  top:-10px;

 */

export const ActiveUsersPanelStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
    margin: 0px auto;
    display: inline-flex;
    position: relative;
    top: ${(props) => MARGIN_TOP_HDR_TOOLBAR_ITEMS}px;
`
export const ContentPanelStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    width: 100%;
    text-align: center;
    position: relative;
    top: -8px;
    display: contents;
`
export const SelectorsStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
    margin: 0px auto;
    display: inline-flex;
    padding-left: 50px;
`

export const MobileLogoStyled = styled.div.attrs((props: LeftHandMenuTitleProperties) => ({
    className: '',
}))<LeftHandMenuTitleProperties>`
    ${(props) => (!props.hideHamburger || !props.noMobileLogo) && 'margin-right: 10px;'};
    ${(props) => (!props.hideHamburger || !props.noMobileLogo) && 'max-width: 50px'};
`

export const NavBarContainerStyledStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center w-100',
}))``

interface MobileMainProperties extends ThemeProps<any> {
    isIOS: boolean
}

export const MobileMainStyled = styled.div.attrs((props: MobileMainProperties) => ({
    id: 'mobile_main_toolbar',
}))<MobileMainProperties>`
    height: ${(props) => CONTENT_START_POSITION}px;
`

interface LeftHandMenuContentsProperties {
    isMobile: boolean
    startPos: number
    userInfoHeight: number
    footerHeight: number
}

/*
+ props.userInfoHeight
 */
export const LeftHandMenuContentsStyled = styled.div.attrs((props: LeftHandMenuContentsProperties) => ({}))<LeftHandMenuContentsProperties>`
    height: ${(props) => {
        if (props.isMobile) {
            return (
                'calc(var(--internal-height)  - env(safe-area-inset-bottom) - ' +
                (props.startPos + RELEASE_DETAILS_FOOTER_HEIGHT + props.footerHeight) +
                'px)'
            )
        }
        return 'inherit'
    }};
    overflow: auto;
`

interface LeftHandMenuWrapperProperties {
    isMobile: boolean
}

export const LeftHandMenuWrapperStyled = styled.div.attrs((props: LeftHandMenuWrapperProperties) => ({}))<LeftHandMenuWrapperProperties>`
    overflow: hidden;
`

export const LeftHandMenuContainerStyled = styled.div.attrs((props) => ({
    className: ' ',
}))``

interface LeftHandMenuTitleProperties {
    hideHamburger: boolean

    noMobileLogo: boolean
}

export const LeftHandMenuTitleStyled = styled.div.attrs((props: LeftHandMenuTitleProperties) => ({
    className: 'd-flex align-items-center justify-content-start me-1 ms-1 py-0',
}))<LeftHandMenuTitleProperties>`
    ${(props) => {
        return props.hideHamburger && props.noMobileLogo && 'display:none;'
    }};
    ${(props) => props.hideHamburger && props.noMobileLogo && 'display:none;'};
`

interface LogoWrapperProperties extends ThemeProps<any> {
    isIOS: boolean
}

export const LogoWrapperStyledStyled = styled.div.attrs((props: LogoWrapperProperties) => ({
    className: '',
}))<LogoWrapperProperties>`
    height: ${(props) => CONTENT_START_POSITION}px;
    padding-top: 12px;
`
export const BlankHamburgerStyled = styled.div.attrs((props: LeftHandMenuTitleProperties) => ({
    className: '',
}))<LeftHandMenuTitleProperties>`
    display: inline-block;
    ${(props) => (!props.hideHamburger || !props.noMobileLogo) && 'width: 15px;'};
`

interface MainToolbarWrapperProperties {
    isIOS: boolean
}

export const MainToolbarWrapperStyled = styled.div.attrs((props: MainToolbarWrapperProperties) => ({
    id: 'main_toolbar',
    className: 'main-toolbar', //  sticky-top
}))<MainToolbarWrapperProperties>`
    position: fixed;
    ${(props) => props.isIOS && 'top: ' + IOS_TOP_STATUS_BAR_HEIGHT + 'px;'}
    ${(props) => !props.isIOS && 'top: 0;'}
    width: 100vw;
    z-index: 1;
`

/*
col-3 d-flex align-items-center
 */

export const TopBarStyled = styled.div.attrs((props) => ({
    // className: 'col-3 d-flex'
}))`
    // MK 02/10/2023
    // display: inline-block;
    display: flex;
    align-items: start;
    //END OF MK 02/10/2023
`

interface HeaderInfoProperties {
    width?: number
    isIphone?: boolean
}

export const HeaderInfoStyled = styled.div.attrs((props: HeaderInfoProperties) => ({}))<HeaderInfoProperties>`
    ${(props) => props.width && 'width: ' + props.width + 'px;'}
    display: inline-block;

    margin-top: 6px;

    &.ios {
        position: relative;
        top: -12px;
    }
`

export const MobileToolbarMenuStyled = styled.div.attrs((props) => ({
    id: 'mobile_toolbar',
}))`
    display: inline-block;
    float: right;
`

interface UserNameProperties {
    ultraNarrowDevice: boolean
}

export const UserNameStyled = styled.div.attrs((props: UserNameProperties) => ({
    id: 'mobile_username',
}))<UserNameProperties>`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: right;
    position: absolute;
    top: ${(props) => IOS_TOP_STATUS_BAR_HEIGHT + 38 + 'px;'}
    text-align: center;
    // GBW 02/09 was 45 - Nova
    width: 50px;
    font-size: 12px;
    right: 5px;
    margin: 0px auto;
    color: ${(props) => props.theme.navigation.topNavbarColor};
    ${(props) => props.ultraNarrowDevice && 'font-size:10px !important;'}

`

interface LogoProperties {
    sidebarNavigation: boolean
}

export const LogoWrapperStyled = styled.div.attrs((props: LogoProperties) => ({
    id: 'logo_wrapper',
}))<LogoProperties>`
    ${(props) => !props.sidebarNavigation && 'padding-left: 10px;'}
`
