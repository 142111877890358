import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ElementWithModal from '../../element-with/element-with-modal/ElementWithModal'
import Icon from '../../maintain-table/bootstrap/Icon'
import { BuySellState } from '../buySellSlice'
import { BoxStyled, ButtonGroupStyled, CurrencyTickerStyled } from '../styled_v2'
import { OrderAction, TickerDO } from '../types'
import HeaderItem from './HeaderItem'
import { EquitiesTrie } from './search-table/equities/EquitiesTrie'
import SearchTableContent from './search-table/SearchTableContent'
import { HeaderItemWrapperStyled, HeaderTickerLookupWrapperStyled } from './styled'
import { TickerLookup } from './ticker-lookup/TickerLookup'
import { NewTickerDO } from './types'

export interface BSHeaderDO {
    lookUpClosed: boolean
    setLookUpClosed: Function
    setTickers: Function
    _buySellSlice: BuySellState
    tickers: NewTickerDO[]
    ticker: NewTickerDO
    originalTickers: NewTickerDO[]
    performPreOrderQuery: Function
    setWLQueryRefresher: Function
    currentlySelectedFavorites: NewTickerDO[]
}

export default function BSHeader({
    lookUpClosed,
    setLookUpClosed,
    setTickers,
    _buySellSlice,
    tickers,
    ticker,
    originalTickers,
    performPreOrderQuery,
    setWLQueryRefresher,
    currentlySelectedFavorites,
}: BSHeaderDO) {
    const [className] = useState('BSHeader-' + new Date().getTime())

    const trie = new EquitiesTrie()
    originalTickers.forEach((ticker) => trie.insert(ticker))

    const renderTickerLookup = (): React.ReactElement => {
        const onTickerSelected = (ticker: TickerDO) => {
            setTickers(ticker)
            performPreOrderQuery(ticker, _buySellSlice.orderAction || OrderAction.NEW)
            setLookUpClosed(false)
        }
        return (
            <HeaderTickerLookupWrapperStyled>
                <TickerLookup code={'BuySellTickerLookup'} onTickerSelected={onTickerSelected} ticker={_buySellSlice.ticker} />
            </HeaderTickerLookupWrapperStyled>
        )
    }

    return (
        <div className="row g-0">
            {renderTickerLookup()}
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'Last price'}
                    value={ticker?.lastPrice as number}
                    valueType={'lastPrice'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h change'}
                    value={ticker?.change as number}
                    valueType={'change'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h high'}
                    value={ticker?.dailyHigh as number}
                    valueType={'dailyHigh'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h low'}
                    value={ticker?.dailyLow as number}
                    valueType={'dailyLow'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'Volume'}
                    value={ticker?.volume as number}
                    valueType={'volume'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
        </div>
    )
}
