import styled, { ThemeProps } from 'styled-components'

export const WidgetMainContainerStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    min-height: 730px;
    margin: 10px 10px;
    border-radius: 10px;
    padding: 10px;
`

interface BoxStyledProps extends ThemeProps<any> {
    level?: string
    className?: string
}

export const BalanceStyled = styled.h3.attrs((props) => ({
    id: 'balance',
    className: '',
}))`
    color: ${(props) => props.theme.colors.dark[1]};

    font-size: 12px;
`

export const CurrencyTickerStyled = styled.h3.attrs((props) => ({
    className: '',
}))`
    color: ${(props) => props.theme.colors.light};
    font-weight: bold;
    font-size: 16px;
`

/*
    background-color: ${(props) => props.theme.colors.dark[0]};
    background: -moz-linear-gradient(
        left,
        ${(props) => props.theme.colors.dark[0]} 0%,
        ${(props) => props.theme.colors.background[1]} 100%
    );
background: -webkit-linear-gradient(
    left,
    ${(props) => props.theme.colors.dark[0]} 0%,
    ${(props) => props.theme.colors.background[1]} 100%
);
background: linear-gradient(to right, ${(props) => props.theme.colors.dark[0]} 0%, ${(props) => props.theme.colors.background[1]} 100%);

 */
export const BoxStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className,
}))<BoxStyledProps>`
    padding: 2px 5px;
    //margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid
        ${(props) =>
            props.level === 'WARNING' ? props.theme.colors.warning : props.level === 'INFO' ? props.theme.colors.info : 'inherit'};
`

export const ErrorStyled = styled.p.attrs((props) => ({
    className: 'small invalid',
}))`
    font-size: 11px;
`
