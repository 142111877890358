import { OrderAction } from '../buy-sell-form/types'

/**
 * part of 'limit' entry in order screen
 */
export const getLimitStep = () => {
    let stepMap: { [index: number]: number } = {}
    const numOfDecimals: number = 2
    const maxNumberOfDecimals = 10

    Array.from({ length: maxNumberOfDecimals }).forEach((_, idx) => {
        stepMap[idx] = 1 / Math.pow(10, idx)
    })
    return stepMap[numOfDecimals]
}

export const getOrderAction = (actionParam: string | undefined): OrderAction => {
    let orderAction: OrderAction = OrderAction.NEW
    try {
        if (actionParam) {
            if (actionParam.toUpperCase() === 'NEW') {
                orderAction = OrderAction.NEW
            } else if (actionParam.toUpperCase() === 'CLOSE') {
                orderAction = OrderAction.CLOSE
            } else if (actionParam.toUpperCase() === 'POSITIONNEWORDER') {
                orderAction = OrderAction.NEW
            } else if (actionParam.toUpperCase() === 'TRADE') {
                // desktop watchlist to trade
                // orderAction = OrderAction.TRADE
                orderAction = OrderAction.NEW
            }
        }
    } catch (error) {
        console.error('Failed to convert', actionParam)
    }
    return orderAction
}
