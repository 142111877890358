import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks'
import { TradingState, updateSide } from '../../../../tradingSlice'
import {
    ButtonContentsStyled,
    ButtonLabelStyled,
    ButtonStyled,
    ColStyled,
    CurrencyStyled,
    PriceStyled,
    PriceWrapperStyled,
    RowStyled,
} from './styled'

interface BuySellProperties {
    code: string
}

export const BuySellButtons = ({ code }: BuySellProperties): React.ReactElement<BuySellProperties> => {
    const dispatch = useAppDispatch()
    const _tradingSlice: TradingState = useAppSelector((state) => state.tradingSlice[code])

    const onSideClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, side: 'BUY' | 'SELL'): void => {
        e?.stopPropagation()
        dispatch(updateSide({ code: code, side: side }))
    }

    const renderButton = (side: 'BUY' | 'SELL', price: number | undefined): React.ReactElement => {
        return (
            <ButtonStyled
                side={side}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onSideClick(e, side)}
                active={_tradingSlice?.order?.getSide() === side}
            >
                <ButtonContentsStyled>
                    <ButtonLabelStyled>{side}</ButtonLabelStyled>
                    <PriceWrapperStyled>
                        <PriceStyled>{price || ''}</PriceStyled>
                        <CurrencyStyled>{_tradingSlice?.currency}</CurrencyStyled>
                    </PriceWrapperStyled>
                </ButtonContentsStyled>
            </ButtonStyled>
        )
    }

    const renderNewOrder = (): React.ReactElement => {
        return (
            <RowStyled key={'f_' + _tradingSlice?.refreshCounter} id={'f_' + _tradingSlice.refreshCounter}>
                <ColStyled>{renderButton('SELL', _tradingSlice?.order?.getSellPrice())}</ColStyled>
                <ColStyled>{renderButton('BUY', _tradingSlice?.order?.getBuyPrice())}</ColStyled>
            </RowStyled>
        )
    }

    const renderOnlyWithSell = (): React.ReactElement => {
        return (
            <RowStyled>
                <ColStyled>{renderButton('SELL', _tradingSlice?.order?.getSellPrice())}</ColStyled>
            </RowStyled>
        )
    }

    const renderOnlyWithBuy = (): React.ReactElement => {
        return (
            <RowStyled>
                <ColStyled>{renderButton('BUY', _tradingSlice?.order?.getBuyPrice())}</ColStyled>
            </RowStyled>
        )
    }

    if (_tradingSlice?.isNewOrder) {
        return renderNewOrder()
    }
    if (_tradingSlice?.order?.getSide() === 'BUY' || (_tradingSlice?.isTradeOrder && _tradingSlice?.order?.getSide() === 'SELL')) {
        return renderOnlyWithSell()
    } else {
        return renderOnlyWithBuy()
    }
}
